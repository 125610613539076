import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './SudoUserSelector.css';
import { useNavigate } from 'react-router-dom';
function SudoUserSelector() {
  const [usernames, setUsernames] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAssuming, setIsAssuming] = useState(false);
  const [filteredUsernames, setFilteredUsernames] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserorgcode, setSelectedorgcode] = useState(null);
  const [selectedUserrole, setSelectedrole] = useState(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
    const fetchUsernames = async () => {
        const token = JSON.parse(localStorage.getItem("data")).token;

      setLoading(true);
      try {
        const url = `/api/accounts`;

  const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
        setUsernames(response.data);
        setFilteredUsernames(response.data);
      } catch (error) {
        toast.error("Failed to fetch usernames.");
      } finally {
        setLoading(false);
      }
    };

  

    const handleFilter = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSelectedUsername(searchTerm);
    
        console.log("Search Term:", searchTerm);
        if (searchTerm) {
            const filtered = usernames.filter((username) =>
              username.username && username.username.toLowerCase().includes(searchTerm)          );
            setFilteredUsernames(filtered);
            console.log("Filtered Usernames:", filtered);
        } else {
            setFilteredUsernames(usernames); // Show all usernames if no search term
            console.log("No search term, showing all usernames:", usernames);
        }
    };
    
    const handleAssume = async () => {
    if (!selectedUsername) {
      toast.warning("Please select a username.");
      return;
    }
    const token = JSON.parse(localStorage.getItem("data")).token;

    setIsAssuming(true);
    try {
      const { data } = await axios.post(
        `/api/accounts/select-user`,
            { id: selectedUserId  },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const orgid =selectedUserorgcode;
          const role=selectedUserrole;
          const newToken = data.token;
      
          // Log the response token
          console.log("Response Token:", newToken);
    
          // Save the new token to localStorage
          localStorage.setItem("data", JSON.stringify({
            token: newToken,
            orgcode: orgid,
            role: role 
          }));    
          const storedData = JSON.parse(localStorage.getItem("data"));
          console.log("Stored Token in LocalStorage:", storedData.token);
          console.log("Stored Orgcode in LocalStorage:", storedData.orgcode);
          console.log("Stored Role in LocalStorage:", storedData.role); // Log role
          
      toast.success(`Assumed as ${selectedUsername}`);
      navigate("/users/admindashboardwithanalytics");

    } catch (error) {
      toast.error("Failed to assume user.");
    } finally {
      setIsAssuming(false);
    }
  };
  const handleFocus = () => {
    fetchUsernames();
    setDropdownOpen(true); // Open the dropdown when the input is focused
  };
  const handleCloseDropdown = () => {
    setDropdownOpen(false); // Close the dropdown when the Cancel button is clicked
  };
  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title">
              Assume (SUDO)
            </div>
<br/>
            <div className="form-group">
              <label htmlFor="usernameDropdown">Select Username</label>
              <br/> 
              {loading && <div className="loading"></div>} 
             

              <input
                id="usernameDropdown"
                className="form-control"
                  type="text"
                placeholder="Search User Name"
                onFocus={handleFocus}    
               onChange={handleFilter}
                value={selectedUsername}
               style={{width:"400px"}}
                />
                  {dropdownOpen && (
                <div className="dropdown-list mt-2">
                  {filteredUsernames.length > 0 ? (
                    filteredUsernames.map((username) => (
                      <div
                        key={username.id}
                        className="dropdown-item"
                        onClick={() => {
                          setSelectedUsername(username.username); // Set selected username
                          setSelectedUserId(username.id); // Set selected user ID
                          setSelectedorgcode(username.orgid);
                          setSelectedrole(username.role)
                          setDropdownOpen(false); // Close dropdown after selection
                        }}
                      >
                        {username.username}
                      </div>
                    ))
                  ) : (
                    <div className="dropdown-item">No usernames found</div>
                  )}
                   <div
                    className="dropdown-item cancel-btn"
                    onClick={handleCloseDropdown}
                  >
                    Cancel
                  </div>
                </div>
              )}
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={handleAssume}
              disabled={isAssuming || !selectedUsername}
            >
              {isAssuming ? "Assuming..." : "Assume"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SudoUserSelector;
