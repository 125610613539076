import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../images/report.png";

function Transactionoutgoingreport() {
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [vehicleFilter, setVehicleFilter] = useState("");
  const [refillProducts, setRefillProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(""); 
  const [isDataAvailable, setIsDataAvailable] = useState(false); 

  const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;
  // Fetch vehicles from API
  const fetchVehicles = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;

    try {
      const response = await axios.get(`/api/vehicle/orgid=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const vehiclesData = response.data.map((vehicle) => ({
        id: vehicle.id,
        truckinfo: vehicle.truckinfo,
        licenceNo: vehicle.licenceNo,
      }));

      setVehicles(vehiclesData);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch vehicles");
    }
  };

  // Fetch products from API
  const fetchRefillProducts = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    
    try {
      const response = await axios.get(`/api/product/productstatus=true/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setRefillProducts(response.data); 
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch products");
    }
  };

  // Fetch data from API
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    
    setLoading(true);
    setIsDataAvailable(false); 

    try {
      const response = await axios.get(
        `/api/outgoingtransactions/orgcode=${orgcode}/vehicle=${vehicleFilter}/productcode=${selectedProduct}/${month.getMonth() + 1}/${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setReportData(response.data.productSummary || {});
      setIsDataAvailable(true); 
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  };

  const generatePDF = () => {
    if (!isDataAvailable) {
      toast.error("No data available to generate PDF.");
      return;
    }
  
    try {
      const doc = new jsPDF();
  
      // Add logo to the left side
      const imgData = img; // Replace `img` with your logo data
      const logoWidth = 50;
      const logoHeight = 20;
      const logoX = 10; // Left margin
      const logoY = 10; // Top margin
      doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);
  
      // Add address to the right side
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const pageWidth = doc.internal.pageSize.width;
      const addressX = pageWidth - 10; // Right margin
      const addressY = logoY + 5; // Vertically aligned with logo
      doc.text("Vijay Gas Agencies", addressX, addressY, { align: "right" });
  
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressDetails = "1010/27 Mysore Road, Gundlupete - 571111";
      const addressDetailsY = addressY + 10; // Below the title
      doc.text(addressDetails, addressX, addressDetailsY, { align: "right" });
  
      // Add report title below the logo
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      const titleX = logoX; // Align with logo
      const titleY = logoY + logoHeight + 10; // Space below the logo
      doc.text("Outgoing Transaction Report", titleX, titleY);
  
      // Add month details below the report title
      doc.setFontSize(12);
      const monthText = `Month: ${
        month ? `${month.getMonth() + 1}/${month.getFullYear()}` : "N/A"
      }`;
      const detailsY = titleY + 10; // Space below the report title
      doc.text(monthText, titleX, detailsY);
  
      // Prepare table data
      const tableData = [];
      Object.keys(reportData).forEach((date) => {
        reportData[date].forEach((item) => {
          tableData.push([
            item.date,
            item.productname,
            item.quantity,
            item.noofsealintact ,
            item.noofcorrectweight ,
            item.noofbungleak ,
            item.noofbodyleak ,
            item.noofappearance ,
          ]);
        });
      });
  
      // Generate the table in the PDF
      doc.autoTable({
        startY: detailsY + 10, // Start the table after the month details
        head: [
          [
            "Date",
            "Product Name",
            "Quantity",
            "No of Seal Intact",
            "No of Correct Weight",
            "No of Bung Leak",
            "No of Body Leak",
            "No of Appearance",
          ],
        ],
        body: tableData,
        theme: "striped",
        styles: { overflow: "linebreak" },
      });
  
      // Save the generated PDF
      doc.save("TransactionOutgoingReport.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };
  
  const handleMonthChange = (date) => {
    setMonth(date);
    if (date) {
      setYear(date.getFullYear());
    }
  };

  const handleVehicleChange = (event) => {
    setVehicleFilter(event.target.value);
  };

  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  useEffect(() => {
    fetchVehicles(); 
    fetchRefillProducts(); 
  }, []);

  useEffect(() => {
    if (month && year && vehicleFilter) {
      getData();
    }
  }, [month, year, vehicleFilter, selectedProduct]);  

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br /><br/>
      <div className="d-flex justify-content-center align-items-center">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
              </div>
            </div>
            <br />
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                  <div className="d-flex justify-content-between align-items-center" style={{ fontFamily: "Arial, sans-serif" }}>
                    <div>
                      <label htmlFor="month">Month:</label>
                      <DatePicker
                        selected={month}
                        onChange={handleMonthChange}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        className="form-control"
                      />
                    </div>

                    <div className="filter-dropdown" style={{ marginRight: "20px" }}>
                      <label>Vehicle:</label>
                      <select value={vehicleFilter} onChange={handleVehicleChange} className="form-control">
                        <option value="">Select Vehicle</option>
                        {vehicles.map((vehicle) => (
                          <option key={vehicle.id} value={vehicle.truckinfo}>
                            {vehicle.truckinfo || vehicle.licenceNo}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="d-flex align-items-center">
                      <label className="me-2">FILTER BY PRODUCT:</label>
                      <select
                        value={selectedProduct}
                        onChange={handleProductChange}
                        className="form-select"
                        style={{ width: "200px" }}
                      >
                        <option value="">All</option>
                        {refillProducts.map((p) => (
                          <option key={p.id} value={p.productcode}>
                            {p.productname}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <br />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={getData}
                    disabled={loading}
                  >
                    Generate Report
                  </button>
                  <button
                    type="button"
                    className="btn btn-success ms-2"
                    onClick={generatePDF}
                    disabled={!isDataAvailable}
                  >
                    Download PDF
                  </button>
                </div>
              </div>
              {loading && <div className="loading">Loading...</div>}

              <div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  isDataAvailable && (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Product Name</th>
                          <th>Quantity</th>
                          <th>No of Seal Intact</th>
                          <th>No of Correct Weight</th>
                          <th>No of Bung Leak</th>
                          <th>No of Body Leak</th>
                          <th>No of Appearance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(reportData).map((date) =>
                          reportData[date].map((item, index) => (
                            <tr key={index}>
                              <td>{item.date}</td>
                              <td>{item.productname}</td>
                              <td>{item.quantity}</td>
                              <td>{item.noofsealintact || 0}</td>
                              <td>{item.noofcorrectweight || 0}</td>
                              <td>{item.noofbungleak || 0}</td>
                              <td>{item.noofbodyleak || 0}</td>
                              <td>{item.noofappearance || 0}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Transactionoutgoingreport;
