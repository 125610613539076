import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";
import "./Spinner.css";
import Search from "./search";

function Exempteddelivery() {
  const [Exempteddelivery, setExempteddelivery] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchbydate, setSearchbydate] = useState(true);
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const navigate = useNavigate();

  const [searchDate, setSearchDate]= useState(currentDate);

  const url = `/api/ExemptedDetails/orgcode=${
    JSON.parse(localStorage.getItem("data")).orgcode
  }/date=${currentDate}`;
  console.log("API URL:", url);


  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setExempteddelivery(res.data);
        setLoader(false);
        console.log(res.data)
      })
      .catch((error) => {
        setLoader(false);
        handleError(error);
      });
  };

  const searchName = async (text) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);
    setSearchDate(text);
    setLoader(true);
      axios
      .get( `/api/ExemptedDetails/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${text}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      })

    .then((res) => {
        setExempteddelivery(res.data);
        setLoader(false);
      })
      .catch((error)=>{
        setLoader(false);
        handleError(error);
      })
      
    }

    function handleError(error) {
      if (error.response && error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else {
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error('An error occurred.');
        }
      }
    }


  useEffect(() => {
    getData();
  }, []);

  function exemptededit(e, id) {
    navigate(`/users/exemptededit/${id}`);
  }
  function rsubmit(e, id, astatus) {
    e.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .post(
        "/api/ExemptedDetails/update",
        {
          id: id,
          status: astatus,
        },

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        setLoading(false);
        searchName(searchDate);
      })

      .catch((error) => {
        setLoader(false);
       handleError(error);
      });
  }
  return (
    <div className="attendance-page">
    <div className="dashboard-container">
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "750px", top: "75px" }}
        ></div>
      )}
      <div className="d-flex align-items-right ">
        <div className="col-12  align-items-center mx-xl-auto  ps-xl-30 mt-10">
       
      <br/>
            <div className="content-container">

      <div className="col-12  mx-xl-auto ps-xl-30"> 
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="d-flex">
              <div className="card-title" > Exempted Delivery Details</div>
              <div style={{ position: "relative", left: "180px" }}>
        <Search searchName={searchName} />{" "}
      </div>
           

          
            <Link to="/users/AddExempteddelivery">
                  <button className="btn btn-primary float-end" style={{left:"380px"}}>ADD</button>
                </Link>
                </div>
                <br />
                <br />
               
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  <th>Customer Name</th>

                  <th>Customer Number</th>
                  <th>Category Name</th>
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Quantity</th> 
                  {/* <th>Unit Price</th>
                  <th>Tariff</th> */}
                  <th>Total</th>
                  <th>Verify</th>
                </tr>
              </thead>
              
              <tbody>
                {searchbydate && Exempteddelivery.length === 0 ? (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchbydate &&  Exempteddelivery.map((exempted) => {

                  return (
                    <tr key={exempted.id}>
                    <td>{exempted.customername}</td>
                      <td>{exempted.customerid}</td>
                      <td>{exempted.categoryname }</td>
                      <td>{exempted.productname}</td>
                      <td>{exempted.productcode}</td>
                      <td>{exempted.deliveredqty}</td>
                      <td>{exempted.total}</td>
            

                      {/* <td>
                        <button
                          onClick={(e) => exemptededit(e, exempted.id)}
                          style={{
                            border: "none",
                            background: "white",
                            size: "150px",
                          }}
                        >
                          <BiEditAlt />
                        </button>
                      </td> */}

                      {exempted.status == false ? (
                        <td id="icon" value={exempted.status}>
                          &nbsp; Verify&nbsp;
                          <BsCheckCircle
                            className="icon"
                            id="approval.approvalstatus"
                            style={{ backgroundColor: "green" }}
                            value="true"
                            onClick={(e) =>
                              rsubmit(
                                e,
                                exempted.id,

                                true
                              )
                            }
                          />
                          &ensp;
                          <BsXCircle
                            className="icon"
                            value="Notverified"
                            style={{ backgroundColor: "red" }}
                            id="exempted.status"
                            onClick={(e) =>
                              rsubmit(
                                e,
                                exempted.id,

                                false,
                                exempted.orgcode
                              )
                            }
                          />
                        </td>
                      ) : (
                        <td id="icon" value={exempted.status}>
                          {" "}
                          <div className="d-flex">
                            {" "}
                            <AiOutlineCheck style={{ color: "green" }} />
                          </div>
                        </td>
                      )}
                    </tr>

                 );
                    }))}
                   {!searchbydate && Exempteddelivery.length === 0 ? (
 <tr>
 <td colSpan="10" className="text-center">
   No Data Available
 </td>
</tr>
) : (
!searchbydate &&  Exempteddelivery.map((exempted) => {
  return (
    <tr key={exempted.id}>
      <td>{exempted.customername}</td>
      <td>{exempted.customerid}</td>
      <td>{exempted.categoryname }</td>
      <td>{exempted.productname}</td>
      <td>{exempted.productcode}</td>
      <td>{exempted.deliveredqty}</td>
      <td>{exempted.total}</td>


      {/* <td>
        <button
          onClick={(e) => exemptededit(e, exempted.id)}
          style={{
            border: "none",
            background: "white",
            size: "150px",
          }}
        >
          <BiEditAlt />
        </button>
      </td> */}

      {exempted.status == false ? (
        <td id="icon" value={exempted.status}>
          &nbsp; Verify&nbsp;
          <BsCheckCircle
            className="icon"
            id="approval.approvalstatus"
            style={{ backgroundColor: "green" }}
            value="true"
            onClick={(e) =>
              rsubmit(
                e,
                exempted.id,

                true
              )
            }
          />
          &ensp;
          <BsXCircle
            className="icon"
            value="Notverified"
            style={{ backgroundColor: "red" }}
            id="exempted.status"
            onClick={(e) =>
              rsubmit(
                e,
                exempted.id,

                false,
                exempted.orgcode
              )
            }
          />
        </td>
      ) : (
        <td id="icon" value={exempted.status}>
          {" "}
          <div className="d-flex">
            {" "}
            <AiOutlineCheck style={{ color: "green" }} />
          </div>
        </td>
      )}
    </tr>

 );
    }))}
              </tbody>
            </table>
          </div>
        </div>{" "}
      </div>
      </div>
      </div>
      </div>
    </div>
  );
}

export default Exempteddelivery;
