import { useState, useEffect } from 'react';
import AdminDashboard from './admindashboard';
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton, Tooltip, Dialog, Pagination, DialogContent, TextField, Modal, InputAdornment, Button, MenuItem, Select, Grid } from "@mui/material";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { CoPresentOutlined } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BiEditAlt } from "react-icons/bi";



const CreditNoteEdit = () => {
  const [loader, setLoader] = useState(false);
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  const [productList, setProductList] = useState([]);
  const navigate = useNavigate();
  const { creditNoteId } = useParams();
  const [orgAddress, setOrgAddress] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  console.log(selectedProducts)
  const [currentDate, setCurrentDate] = useState('');
  const [creditdata, setCreditdata] = useState([]);
  const [tariffCharge, setTariffCharge] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showTaxDetails, setShowTaxDetails] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [openNewContactModal, setOpenNewContactModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCustomers = customers.slice(indexOfFirstItem, indexOfLastItem);


  console.log(selectedCustomer);

  useEffect(() => {
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
    setCurrentDate(formattedDate);

    getData();
    fetchOrgAddress();
    // fetchExemptedCustomers();
    fetchCreditNoteDetails();
  }, []);

  const fetchCreditNoteDetails = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      const response = await axios.get(`/api/creditbill/id=${creditNoteId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const creditNote = response.data;
      setCreditdata(creditNote);
      setTariffCharge(creditNote.tariff);
      setSelectedProducts(creditNote.creditdetails || []); // Extract quotes data and set it to productData state


      const Consumername = creditNote.consumername;
      const formattedConsumername = Consumername.replace(/\//g, '!');

      const encodedConsumername = encodeURIComponent(formattedConsumername);
      const encodedOrgcode = encodeURIComponent(orgcode);

      const customerResponse = await axios.get(`/api/exemptedcustomer/orgcode=${encodedOrgcode}/customername=${encodedConsumername}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const customerData = customerResponse.data[0];
      setSelectedCustomer(customerData);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  useEffect(() => {
    console.log('Products:', productList);
    console.log('Selected Products:', selectedProducts);
  }, [productList, selectedProducts]);

  const fetchExemptedCustomers = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/exemptedcustomer/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCustomers(response.data);
      setTotalItems(response.data.length);
      console.log(response.data.length);


    } catch (error) {
      handleError(error);
    }
  };

  const searchQuotations = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const params = {
        orgcode: orgcode,
        customername: searchTerm ? encodeURIComponent(searchTerm) : undefined,
      };

      const response = await axios.get(`/api/exemptedcustomer/search`, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });

      const data = Array.isArray(response.data) ? response.data : response.data.data || [];
      setCustomers(data);
    } catch (error) {
      console.error("Error occurred during product fetch:", error);
    } finally {
      setLoader(false);
    }
  };


  const getData = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/product/getProductAndPriceDetails/orgcode=${orgcode}/productstatus=${true}/productCategoryTpeId=${1}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProductList(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const fetchOrgAddress = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/organizationprofiles/Orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { addressLine1, addressLine2, name, state, city, pincode, gstin, pan, logo, imageMeta, contact } = response.data;
      setOrgAddress({
        name,
        addressLine1,
        addressLine2,
        city,
        state,
        pincode,
        gstin,
        pan,
        logo,
        imageMeta,
        contact
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  const handleAddProductRow = () => {
    setSelectedProducts([
      ...selectedProducts,
      {
        productname: '',
        quantity: 1,
        price: 0,
        gst: 0,
        amount: 0,
      },
    ]);
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...selectedProducts];

    if (field === 'productname') {
      const product = productList.find((prod) => prod.productname === value);

      if (product) {
        const basePrice = product.pprice.price;
        const gstRate = product.gst || 0;
        const gstAmount = (basePrice * gstRate) / 100;

        updatedProducts[index] = {
          ...updatedProducts[index],
          productname: value,
          price: basePrice,
          gst: gstRate,
          amount: (basePrice + gstAmount).toFixed(2),
        };
      }
    } else if (field === 'quantity') {
      const quantity = parseInt(value, 10) || 0;
      const basePrice = updatedProducts[index].price || 0;
      const gstRate = updatedProducts[index].gst?.totalTax || 0;
      const gstAmount = (basePrice * gstRate) / 100;

      updatedProducts[index] = {
        ...updatedProducts[index],
        quantity,
        amount: ((basePrice + gstAmount) * quantity).toFixed(2),
      };
    } else {
      updatedProducts[index][field] = value;
    }

    setSelectedProducts(updatedProducts);
  };





  const calculateTotalQuantity = () => {
    return selectedProducts.reduce((total, item) => {
      return total + (parseInt(item.quantity, 10) || 0);
    }, 0);
  };

  const calculateTotalAmount = () => {
    const totalProductAmount = selectedProducts.reduce((total, item) => {
      return total + (parseFloat(item.amount) || 0);
    }, 0);

    const totalTariffAmount = parseFloat(tariffCharge || 0) * calculateTotalQuantity();

    return {
      Totalamount: (totalProductAmount + totalTariffAmount).toFixed(2),
      Totaltariff: totalTariffAmount.toFixed(2),
    };
  };

  const resultValue = calculateTotalAmount();

  const calculateSubTotal = () => {
    return selectedProducts.reduce((sum, item) => {
      const quantity = parseFloat(item.quantity) || 0;
      const price = parseFloat(item.price) || 0;
      return sum + (quantity * price);
    }, 0).toFixed(2);
  };

  const TotalTaxAmount = (selectedProducts) => {
    return selectedProducts.reduce((sum, product) => {
      const tax = parseFloat(product.gst);
      const Beforeamount = parseFloat(product.price)
      const totaltax = (tax * Beforeamount) / 100;

      return sum + totaltax;
    }, 0).toFixed(2);
  };

  const toggleTaxDetails = () => {
    setShowTaxDetails(!showTaxDetails);
  };

  const handleSaveCreditNote = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const payload = {
      id: creditdata.id,
      orgcode,
      consumername: selectedCustomer.customername,
      creditdetails: selectedProducts.map((product) => ({
        creditbillid: creditdata.id,
        id: product.id || null,
        productname: product.productname,
        quantity: product.quantity,
        price: product.price,
        gst: product.gst,
        amount: product.amount,
      })),
      totalamount: resultValue.Totalamount,
      tariff: tariffCharge,
      date: currentDate,
    };
    try {
      await axios.post(`/api/creditbill/update`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("performa bill updated successfully!");
      navigate('/users/CreditnoteList');
    } catch (error) {
      handleError(error);
    }
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = selectedProducts.filter((_, i) => i !== index);
    setSelectedProducts(updatedProducts);
  };

  const numberToWords = (num) => {
    const a = [
      '', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE',
      'TEN', 'ELEVEN', 'TEWLEV', 'THIRTEEN', 'FOURTEEN', 'FIFTEEN', 'SIXTEEN', 'SEVENTEEN', 'EIGHTEEN', 'NINETEEN',
    ];
    const b = ['', '', 'TWENTY', 'THIRTY', 'FOURTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY'];

    const inWords = (n) => {
      if (n < 20) return a[n];
      if (n < 100) return `${b[Math.floor(n / 10)]} ${a[n % 10]}`.trim();
      if (n < 1000) return `${a[Math.floor(n / 100)]} HUNDRED ${inWords(n % 100)}`.trim();
      if (n < 100000) return `${inWords(Math.floor(n / 1000))} THOUSAND ${inWords(n % 1000)}`.trim();
      if (n < 10000000) return `${inWords(Math.floor(n / 100000))} LAKH ${inWords(n % 100000)}`.trim();
      return `${inWords(Math.floor(n / 10000000))} CRORE ${inWords(n % 10000000)}`.trim();
    };

    return inWords(num) || 'ZERO';
  };

  const handleCloseContactModal = () => setOpenContactModal(false);
  const handleOpenNewContactModal = () => setOpenNewContactModal(true);
  const handleCloseNewContactModal = () => setOpenNewContactModal(false);

  const handleOpenContactModal = () => {
    setOpenContactModal(true);
    fetchExemptedCustomers();
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSelectContact = (customer) => {
    setSelectedCustomer(customer);
    handleCloseContactModal();
  };

  const handleBackClick = () => {
    setSearchTerm(""); // Clear the search term
    setCustomers([]); // Clear the customer list
    fetchExemptedCustomers(); // Optionally re-fetch data
  };

  const handleClose = () => {
    navigate(-1);
  };



  return (
    <div>
      <AdminDashboard className="no-print" />
      <br /><br /><br /><br />
      <div className="col-12 mx-xl-auto ps-xl-10">
        <Tooltip title="Back" arrow className="no-print">
          <IconButton onClick={() => navigate(-1)} style={{ paddingLeft: '10px', marginLeft: '20px' }}>
            <AiOutlineArrowLeft style={{ fontSize: '30px' }} />
          </IconButton>
        </Tooltip>
        <div style={{
          width: '70%',
          backgroundColor: '#fff',
          margin: '0 auto',
          padding: '20px',
          border: '1px solid #000',
          fontFamily: 'Arial, sans-serif',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        }}>
          {/* Header */}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #000', paddingBottom: '10px' }}>
            <div>
              {/* Logo Image */}
              {orgAddress?.logo && (
                <img
                  className="img-fluid mb-1"
                  id="image"
                  src={`${orgAddress.imageMeta},${orgAddress.logo}`}
                  alt="Company Logo"
                  style={{ width: '80px', height: '80px', marginRight: '15px' }}
                />
              )}

              <h2 style={{ margin: 0, fontSize: '1.5rem' }}>{orgAddress?.name || ''}</h2>
              <br />
              <p style={{ margin: '0', padding: '2px 0' }}>{orgAddress?.addressLine1 || ''}</p>
              <p style={{ margin: '0', padding: '2px 0' }}>{orgAddress?.addressLine2 || ''}</p>
              <p style={{ margin: '0', padding: '2px 0' }}>
                {orgAddress ? `${orgAddress.city}, ${orgAddress.state} - ${orgAddress.pincode}` : ''}
              </p>
              <p style={{ margin: '0', padding: '2px 0' }}>GSTIN: {orgAddress?.gstin || ''}</p>
            </div>
            <div style={{ textAlign: 'right' }}>
              <p>Date: <input type="text" value={currentDate} placeholder="DD/MM/YYYY" style={{ border: 'none', borderBottom: '1px dashed #000', outline: 'none', width: '120px' }} /></p>
              <h3 style={{ margin: 0 }}>Performa Bill</h3>
            </div>
          </div>

          {/* To Section */}
          <div style={{ padding: '20px' }}>
            <h5>To
              <IconButton style={{ color: "blue" }} onClick={handleOpenContactModal}>
                <BiEditAlt />
              </IconButton>
            </h5>
            {selectedCustomer ? (
              <div>
                <p style={{ marginBottom: '5px' }}> {selectedCustomer.customername}</p>
              <p style={{ marginBottom: '5px' }}> {selectedCustomer.address1},{selectedCustomer.city}</p>
              <p style={{ marginBottom: '5px' }}> {selectedCustomer.district}-{selectedCustomer.pincode}</p>
              <p style={{ marginBottom: '5px' }}> {selectedCustomer.customerNumber}</p>

              </div>
            ) : (
              <p>Loading customer details...</p>
            )}
          </div>

          <Dialog open={openContactModal} onClose={handleCloseContactModal} maxWidth="md" fullWidth>
            <DialogContent>

              <Tooltip title="Back" arrow className="no-print">
                <IconButton
                  onClick={handleBackClick} // Call the back handler
                  style={{ paddingLeft: "10px", marginLeft: "20px" }}
                >
                  <AiOutlineArrowLeft style={{ fontSize: "30px" }} />
                </IconButton>
              </Tooltip>

              <Grid item sx={{ marginRight: '8px' }}>
                <TextField
                  variant="outlined"
                  placeholder={`Search by `}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    ),
                    sx: { height: '30px', fontSize: '15px' },
                  }}
                  sx={{ width: '150px', height: '30px' }}
                />

                <Button
                  variant="contained"
                  onClick={searchQuotations}
                  sx={{ height: '30px', width: '60px', backgroundColor: 'blue', color: 'white' }}
                >
                  search
                </Button>
              </Grid>

              <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                    <th>ID</th>
                      <th>Username</th>
                      <th>Customer No</th>
                      <th>Address1</th>
                      <th>City</th>
                      <th>District</th>
                      <th>Pincode</th>

                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(currentCustomers) && currentCustomers.length > 0 ? (
                      currentCustomers.map(customer => (

                        <tr
                          key={customer.id} onClick={() => handleSelectContact(customer)}
                          style={{ cursor: "pointer" }}>
                          <td>{customer.id}</td>
                          <td>{customer.customername}</td>
                          <td>{customer.customerNumber}</td>
                          <td>{customer.address1}</td>
                          <td>{customer.city}</td>
                          <td>{customer.district}</td>
                          <td>{customer.pincode}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No data available</td>
                      </tr>
                    )}


                  </tbody>
                </table>



              </div>

              <Pagination
                count={Math.ceil(customers.length / itemsPerPage)}
                page={currentPage}

                onChange={handlePageChange}
                sx={{
                  marginTop: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  '& .MuiPaginationItem-root': {
                    color: 'blue',
                  },
                  '& .Mui-selected': {
                    backgroundColor: 'blue',
                    color: 'white',
                  },
                }}
              />
            </DialogContent>
          </Dialog>

          {/* Credit Note Details */}
          <div style={{ margin: '20px 0', padding: '10px', border: '1px solid #000' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ width: "20%", textAlign: 'left' }}>Description</th>
                  <th style={{ width: "15%", textAlign: 'center' }}>Quantity</th>
                  <th style={{ width: "10%", textAlign: 'center' }}>Base Amount</th>
                  <th style={{ width: "20%", textAlign: 'center' }}>Tax</th>
                  <th style={{ width: "15%", textAlign: 'center' }}>Amount</th>
                  <th style={{ width: "10%", textAlign: 'center' }}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {selectedProducts.map((product, index) => (
                  <tr key={index}>
                    <td style={{ padding: '10px 5px', borderBottom: '1px dashed #000' }}>
                      <select
                        value={product.productname}
                        onChange={(e) => handleProductChange(index, 'productname', e.target.value)}
                        style={{ width: '100%', border: 'none', outline: 'none' }}

                      >
                        <option value="">Select Product</option>
                        {productList.map((prod) => (
                          <option key={prod.id} value={prod.productname}>
                            {prod.productname}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td style={{ padding: ' 5px', textAlign: 'center', borderBottom: '1px dashed #000' }}>
                      <input
                        type="number"
                        value={product.quantity}
                        onChange={(e) => handleProductChange(index, 'quantity', e.target.value)}
                        style={{ width: '60px', border: 'none', outline: 'none', textAlign: 'right' }}
                      />
                    </td>
                    <td style={{ padding: ' 5px', textAlign: 'center', borderBottom: '1px dashed #000' }}>
                      {product.price || 0}</td>

                    <td style={{ padding: ' 5px', textAlign: 'center', borderBottom: '1px dashed #000' }}>
                      <div>
                        tax:{product.gst}%  , ₹ {(product.gst * product.amount / 100).toFixed(2)}
                      </div>

                      <div>
                        CGST:{product.gst / 2}% ,₹ {((product.gst * product.amount / 100) / 2).toFixed(2)}
                      </div>
                      <div>
                        SGST:{product.gst / 2} % ,₹ {((product.gst * product.amount / 100) / 2).toFixed(2)}
                      </div>
                    </td>

                    <td style={{ padding: ' 5px', textAlign: 'center', borderBottom: '1px dashed #000' }}>
                      {product.amount || 0}
                    </td>
                    <td style={{ padding: ' 5px', textAlign: 'center', borderBottom: '1px dashed #000' }}>
                      <button onClick={() => handleRemoveProduct(index)}>
                        <span style={{ color: 'red', fontSize: '18px' }}>X</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <button onClick={handleAddProductRow} style={{ marginTop: '10px' }}>Add Another Product</button>

            <div style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <p style={{ margin: 0, marginRight: '10px' }}>Tariff Charge</p>
                <input
                  id="tariff"
                  type="number"
                  value={creditdata.tariff}
                  // onChange={(e) => setTariffCharge(e.target.value)}
                  style={{ width: '80px', padding: '5px', border: '1px solid #ccc' }}
                  readOnly
                />

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ margin: 0, marginRight: '10px' }}>Total Quantity:</p>
                <span>{calculateTotalQuantity()}</span>
              </div>
            </div>

            <div style={{ marginTop: '10px' }}>
              <p style={{ margin: '0', padding: '2px 0', display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}>
                Sub Total: {calculateSubTotal()}
              </p>
              <p style={{ margin: '0', padding: '2px 0', display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}>
                <label> Tax</label>

                <Tooltip title="Show tax breakdown">
                  <IconButton onClick={toggleTaxDetails} >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>:
                {/* </p> */}
                {/* <p style={{ margin: '0', padding: '2px 0', display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}> */}
                <label>₹ {TotalTaxAmount(selectedProducts)}</label>

              </p>

              {showTaxDetails && (
                <p
                  style={{
                    margin: '0',
                    padding: '2px 0',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontWeight: 'bold',
                    borderRadius: "0.5px", // Reduced border radius
                  }}
                >
                  <h6 >Tax Breakdown:</h6>
                  <p style={{ margin: '0 8px', padding: '2px 0', display: 'flex', justifyContent: 'flex-end' }}>
                    <div>CGST: ₹ {selectedProducts.reduce((sum, product) => sum + ((product.gst * product.price / 100) / 2), 0).toFixed(2)}</div>
                  </p>
                  <p style={{ margin: '0 8px', display: 'flex', justifyContent: 'flex-end' }}>
                    <div>SGST: ₹ {selectedProducts.reduce((sum, product) => sum + ((product.gst * product.price / 100) / 2), 0).toFixed(2)}</div>
                  </p>
                </p>
              )}

              <p style={{ margin: '0', padding: '2px 0', display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}>
                Total Tariff: {resultValue.Totaltariff}
              </p>
              <p style={{ margin: '0', padding: '2px 0', display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}>
                Total Amount: {resultValue.Totalamount}
              </p>
            </div>


            {/* Footer */}
            <div style={{ marginTop: '20px' }}>
              <p style={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', marginTop: '10px' }}>
                (Amount in words: {numberToWords(resultValue.Totalamount)} rupees only)
              </p>
              <p style={{ fontStyle: 'italic', textAlign: 'center', margin: '20px 0' }}>Note: This performa bill is applicable only for the date mentioned above.</p>
              <p style={{ textAlign: 'center', marginTop: '30px' }}>For {orgAddress?.name || 'Your Company Name'}</p>
              <p style={{ textAlign: 'center' }}>Authorized Signatory</p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', paddingRight: '90px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveCreditNote}
          style={{ width: '7%' }}
        >
          Save
        </Button>

        
        <button
          className="btn btn-secondary"
          onClick={handleClose}
          style={{ marginLeft: '10px', height: '35px' }}                                  >
          Close
        </button>

      </div>
      {/* <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>

        <button
          className="btn btn-secondary"
          onClick={handleClose}
          style={{ marginLeft: '10px', height: '35px' }}                                  >
          Close
        </button>


      </div> */}

    </div>
  );
};

export default CreditNoteEdit;
