import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";

const AdministratorSettings = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate();

  // Initialize the current date in YYYY-MM-DD format
  const date = new Date();
  const currentDate = date.toISOString().split("T")[0];

  // Define the initial state for newProduct
  const initialProductState = {
    adminPassword: "",
    orgcode: JSON.parse(localStorage.getItem("data"))?.orgcode || "",
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);

  const url = "/api/accounts/set-admin-password";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    
    try {
      await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(false);
      toast.success("Admin Password Created successfully");
      setNewProduct(initialProductState);
      onRequestClose();
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response?.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      const errorMessage = error.response?.data?.message || "An error occurred.";
      toast.error(errorMessage);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setNewProduct((prevState) => ({
      ...prevState,
      [id]: id === "netWeight" ? (value ? parseFloat(value) : "") : value,
    }));
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Admin Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <label htmlFor="adminPassword">Admin Password</label>
                  <input
                    className="form-control"
                    id="adminPassword"
                    type="password"
                    placeholder="Enter Admin Password"
                    value={newProduct.adminPassword}
                    onChange={handleChange}
                    required
                  />
                </div>

              

                <div className="d-flex justify-content-end">
                  <Button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? "Saving..." : "SAVE"}
                  </Button>
                  &nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Modal.Body>
    </Modal>
  );
};

export default AdministratorSettings;