import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import "./Spinner.css";
import { useNavigate } from "react-router";
import { MdDeleteForever } from "react-icons/md";
import ProductSelectionModal from "./productsPopup";
import { AddCircle } from "@mui/icons-material";

const UpdateCampaigns = ({ isOpen, onRequestClose, campaignDetails }) => {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [linkToSales, setLinkToSales] = useState(false);
  const [campaign, setCampaign] = useState({
    campaignName: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    points: "",
    startDate: "",
    endDate: "",
    description: "",
    linkproduct: false,
    id: campaignDetails ? campaignDetails.id : null,
    campaignProductMap: [
      {
        productId: "",
        fromDate: "",
        toDate: "",
      },
    ],
  });

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [linkedProducts, setLinkedProducts] = useState([]);
  const [showProductModal, setShowProductModal] = useState(false);

  const fetchProducts = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const productResponse = await axios.get(
        `/api/product/productstatus=true/orgcode=${JSON.parse(
          localStorage.getItem("data")
        ).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProducts(productResponse.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching products:", error);
      toast.error("Failed to fetch products.");
    }
  };

  const fetchLinkedProducts = async (campaignId) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(
        `/api/campaignProductsMapping/campaignId=${campaignId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLinkedProducts(response.data);
    } catch (error) {
      console.error("Error fetching linked products:", error);
      toast.error("Failed to fetch linked products.");
    }
  };

  useEffect(() => {
    if (campaignDetails) {
      setCampaign({
        campaignName: campaignDetails.campaignName,
        points: campaignDetails.points,
        startDate: campaignDetails.startDate,
        endDate: campaignDetails.endDate,
        description: campaignDetails.description,
        linkproduct: campaignDetails.linkproduct,
        id: campaignDetails.id,
      });
      if (campaignDetails.id) {
        fetchLinkedProducts(campaignDetails.id);
      }
    }
  }, [campaignDetails]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      const endpoint = "/api/campaign/update";
      const response = await axios.post(
        endpoint,
        { ...campaign, campaignProductMap: selectedProducts },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLoading(false);
      toast.success(`Campaign updated successfully`);
      onRequestClose();
      setCampaign({
        campaignName: "",
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        points: "",
        startDate: "",
        endDate: "",
        description: "",
        linkproduct: false,
        id: null,
      });
      setSelectedProducts([]);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  };

  const handleProductSelection = (productId) => {
    const productExists = selectedProducts.some(
      (product) => product.productId === productId
    );

    if (productExists) {
      setSelectedProducts((prevSelected) =>
        prevSelected.filter((product) => product.productId !== productId)
      );
    } else {
      setSelectedProducts((prevSelected) => [
        ...prevSelected,
        { productId, fromDate: "", toDate: "" },
      ]);
    }
  };

  const handleDateChange = (productId, field, value) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.map((product) =>
        product.productId === productId
          ? { ...product, [field]: value }
          : product
      )
    );
  };

  const handleDone = () => {
    setShowProductModal(false);
    if (selectedProducts.length === 0) {
      setLinkToSales(false);
    }
  };

  const handleClose = () => {
    setShowProductModal(false);
   // Process selectedProducts only after modal closes
   setSelectedProducts((prevSelected) => {
    if (prevSelected.length === 0) {
      setLinkToSales(false);
    }
    return prevSelected; // Return the unchanged state
  });
  };

  const handleAddProductClick = () => {
    setShowProductModal(true);
  };

  const handleRemoveProduct = (productId) => {
    setSelectedProducts((prevSelected) => {
      const updatedSelectedProducts = prevSelected.filter(
        (product) => product.productId !== productId
      );

      if (updatedSelectedProducts.length === 0) {
        setLinkToSales(false);
      }

      return updatedSelectedProducts;
    });
  };
  return (
    <>
      <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
        {loading && <div className="loading"></div>}
        <Modal.Header closeButton>
          <Modal.Title>Update Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-xl-10 mx-xl-auto">
            <div className="card card-raised mb-3">
              <div className="card-body p-4">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="campaignname">
                        Campaign Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        id="campaignName"
                        type="text"
                        placeholder="campaign Name"
                        value={campaign.campaignName}
                        onChange={(e) => setCampaign({ ...campaign, campaignName: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="startDate">
                        Start Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        id="startDate"
                        type="date"
                        value={campaign.startDate}
                        onChange={(e) => setCampaign({ ...campaign, startDate: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="endDate">End Date</label>
                    <input
                      className="form-control"
                      id="endDate"
                      type="date"
                      value={campaign.endDate}
                      onChange={(e) => setCampaign({ ...campaign, endDate: e.target.value })}
                    />
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="points">
                        Points<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        id="points"
                        type="text"
                        value={campaign.points}
                        onChange={(e) => setCampaign({ ...campaign, points: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        value={campaign.description}
                        onChange={(e) => setCampaign({ ...campaign, description: e.target.value })}
                        rows="2"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <label>Link to Sales</label>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          checked={campaign.linkproduct===true}
                        
                          onChange={() => {
                            setLinkToSales(true);
                            setCampaign((prevCampaign) => ({
                                ...prevCampaign,
                                linkproduct: true, // Set linkproduct to false
                              }));
                            setShowProductModal(true);
                          }}
                       
                        />
                        <Form.Check
  inline
  type="radio"
  label="No"
  checked={campaign.linkproduct === false}
  onChange={() => {
    // Only proceed if linkedProducts is empty
    if (linkedProducts.length === 0) {
      setLinkToSales(false);
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        linkproduct: false, // Set linkproduct to false
      }));
      setShowProductModal(false);
      setSelectedProducts([]); // Clear selected products
    }
  }}
/>

                      </div>
                    </div>
                    </div>
                
                      <div> 
  {campaign.linkproduct===true && (
    <button
  style={{
    border: "none",
    background: "white",
    color: "black",
    fontSize: "16px",
  }}
  className="ms-2"
  type="button" // Change this line
  onClick={handleAddProductClick}
>
  <AddCircle />
</button>

  )}
  </div> 
                    {/* Display linked products */}
                    {campaign.id && linkedProducts.length > 0 && (
                      <div>
                        <h6>Linked Products</h6>
                       
                        <ul>
                          {linkedProducts.map((product) => (
                            <li key={product.id}>
                              Product: {product.product?.productname} 
                              {product.fromDate && `, From Date: ${product.fromDate}`}
                              {product.toDate && `, To Date: ${product.toDate}`}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {/* Display selected products */}
                
{selectedProducts.length > 0 && (
  <div>
     <h6>Selected Products</h6>
    <ul>
      {selectedProducts.map((product) => (
        <li key={product.productId}>
          Product: {product.productname}
          {product.fromDate && `, From Date: ${product.fromDate}`}
          {product.toDate && `, To Date: ${product.toDate}`}
          <button
            type="button"
            className="ms-2"
            onClick={() => handleRemoveProduct(product.productId)}
            style={{
              border: "none",
              background: "transparent",
              color: "red",
            }}
          >
            <MdDeleteForever />
          </button>
        </li>
      ))}
    </ul>
  </div>
)}

                    

                 
                 
                  <Button
                    variant="primary"
                    type="submit"
                    className="me-3"
                  >
                    Update
                  </Button>

                  <Button variant="secondary" onClick={onRequestClose}>
                            Close
                          </Button>
                  
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showProductModal && (
  <ProductSelectionModal
    isOpen={showProductModal}
    selectedProducts={selectedProducts}
    onProductsSelected={(updatedProducts) => {
      setSelectedProducts(updatedProducts); // Ensure this updates correctly
    }}
    onRequestClose={handleClose}
  />
)}
         </>
  );
};

export default UpdateCampaigns;
