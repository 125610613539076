import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../api/baseurl";
import Select from "react-select"; // For the searchable dropdown

const ProductSelectionModal = ({ isOpen, selectedProducts, onProductsSelected, onRequestClose }) => {
    const [localSelectedProducts, setLocalSelectedProducts] = useState([...selectedProducts]);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]); // To store categories from the API
    const [selectedCategory, setSelectedCategory] = useState("all"); // Selected category object
    const [loading, setLoading] = useState(false);
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

    const fetchProductsAndCategories = async () => {
        const token = JSON.parse(localStorage.getItem("data")).token;
        setLoading(true);

        try {
            // Fetch products
            const productResponse = await axios.get(
                `/api/product/productstatus=true/orgcode=${orgcode}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setProducts(productResponse.data);

            // Fetch categories
            const categoryResponse = await axios.get(
                `/api/productcategories/orgcode=${orgcode}/disable=false`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setCategories(categoryResponse.data);
            setLoading(false);

            // Set the first category as the default selection
            if (categoryResponse.data.length > 0) {
                setSelectedCategory({ value: categoryResponse.data[0].id, label: categoryResponse.data[0].categoryname });
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
            toast.error("Failed to fetch products or categories.");
        }
    };

    useEffect(() => {
        fetchProductsAndCategories();
    }, []);

    const handleProductSelection = (productId, productname) => {
        const exists = localSelectedProducts.some((product) => product.productId === productId);
        if (exists) {
            setLocalSelectedProducts((prev) => prev.filter((product) => product.productId !== productId));
        } else {
            setLocalSelectedProducts((prev) => [
                ...prev,
                { productId, productname, fromDate: "", toDate: "" }
            ]);
        }
    };

    const handleDateChange = (productId, field, value) => {
        setLocalSelectedProducts((prev) =>
            prev.map((product) =>
                product.productId === productId ? { ...product, [field]: value } : product
            )
        );
    };

    const handleCategorySelect = (selectedOption) => {
        // Update selectedCategory when a category is chosen
        setSelectedCategory(selectedOption);
    };

    const handleDone = () => {
        // Check if all selected products have a 'fromDate'
        const invalidProducts = localSelectedProducts.filter((product) => !product.fromDate);
        
        if (invalidProducts.length > 0) {
            toast.error("Please select From Date for selected products.");
            return; // Stop further execution
        }

        onProductsSelected(localSelectedProducts); // Pass updated products to parent
        onRequestClose(); // Close the modal
    };

    // Filter products by selected category
    const filteredProducts = selectedCategory?.value === "all" 
        ? products // Show all products if "All Categories" is selected
        : products.filter((product) => product.productCategoryId === selectedCategory.value); // Filter by selected category

    // Format categories for Select dropdown, including "All Categories" option
    const categoryOptions = [
        { value: "all", label: "All Categories" },
        ...categories.map((category) => ({
            value: category.id,
            label: category.categoryname,
        })),
    ];

    return (
        <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
            {loading && <div className="loading"></div>}
            <Modal.Header closeButton>
                <Modal.Title>Select Products</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {/* Searchable Dropdown for Categories */}
                    <h5>Categories</h5>
                    <Select
                        options={categoryOptions}
                        value={selectedCategory ? { value: selectedCategory.value, label: selectedCategory.label } : null}
                        onChange={handleCategorySelect}
                        placeholder="Select a Category"
                    />

                  {/* Display filtered products */}
                  <ul>
                      {filteredProducts.map((product) => (
                          <li key={product.id} style={{ display: 'flex', alignItems: 'center' }}>
                              <input
                                  type="checkbox"
                                  checked={localSelectedProducts.some((p) => p.productId === product.id)}
                                  onChange={() => handleProductSelection(product.id, product.productname)}
                              />
                              <span style={{ marginLeft: '10px' }}>{product.productname}</span>

                              {localSelectedProducts.some((p) => p.productId === product.id) && (
                                  <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                                      <label style={{ marginRight: '5px' }}>From Date <span style={{ color: "red" }}>*</span></label>
                                      <input
                                          type="date"
                                          value={localSelectedProducts.find((p) => p.productId === product.id)?.fromDate || ""}
                                          onChange={(e) => handleDateChange(product.id, "fromDate", e.target.value)}
                                          style={{ marginRight: '10px' }}
                                          required
                                      />
                                      <label style={{ marginRight: '5px' }}>To Date</label>
                                      <input
                                          type="date"
                                          value={localSelectedProducts.find((p) => p.productId === product.id)?.toDate || ""}
                                          onChange={(e) => handleDateChange(product.id, "toDate", e.target.value)}
                                      />
                                  </div>
                              )}
                          </li>
                      ))}
                  </ul>

                  <Button onClick={handleDone}>Save</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ProductSelectionModal;
