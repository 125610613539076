import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import ProducteditModalPopup from "./productedit";
import Modalpopup from "./Modalpopup";
import VendorViewModal from "./Vendorviewmodal";
import AddVendor from "./Addvendor";
import StockistViewModal from "./Stoclist";
import ProductViewModal from "./Productviewmodal";
import VendoreditModalPopup from "./Vendoreditmodal";
import { MdDeleteForever } from "react-icons/md";
import Pagination from 'react-bootstrap/Pagination'; 
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';  
import { ImPlus } from "react-icons/im";
import ViewStockistmapping from "./Viewstockist";
import ViewLinkmapping from "./Viewlink";

function VendorManagementcomponent() {
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [isVendorEditModalOpen, setIsVendorEditModalOpen] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isVendorViewModalOpen, setIsVendorViewModalOpen] = useState(false);
  const [isUpdateVendorModalOpen, setIsUpdateVendorModalOpen] = useState(false);
  const [isProductViewModalOpen, setIsProductViewModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);

  const navigate = useNavigate();

  
  const getData = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const vendorResponse = await axios.get(`/api/vendorprofile/getAllByOrgcode/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVendor(vendorResponse.data.content);
      setCurrentPage(vendorResponse.data.number);
      setTotalPages(vendorResponse.data.totalPages);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  
  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [isCreateModalOpen]);

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
    setVendor([]);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    setVendor([]);
  };

  const handleVendorEdit = (vendorId) => {
    setSelectedVendorId(vendorId);
    setIsVendorEditModalOpen(true);
  };

  const closeVendorEditModal = () => {
    setIsVendorEditModalOpen(false);
    setSelectedVendorId(null);
  };

  const handleVendorView = (vendorId) => {
    setSelectedVendorId(vendorId);
    setIsVendorViewModalOpen(true);
  };

  const closeVendorViewModal = () => {
    setIsVendorViewModalOpen(false);
    setSelectedVendorId(null);
  };
  const handleUpdateVendor = (vendorId) => {
    setSelectedVendorId(vendorId);
    setIsUpdateVendorModalOpen(true);
  };

  const closeUpdateVendorModal = () => {
    setIsUpdateVendorModalOpen(false);
    setSelectedVendorId(null);
  };

  const handleProductView = async (vendorId) => {
    setSelectedVendorId(vendorId);
    setIsProductViewModalOpen(true);

   
  };

  const closeProductViewModal = () => {
    setIsProductViewModalOpen(false);
  };

  const handleEdit = async (vendorId) => {
    setSelectedVendorId(vendorId);
 
      setIsEditModalOpen(true);
   
  };
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    getData();
 
  };
  const onClickhandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/vendorprofile",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    setLoading(false); 
    getData();
  } catch (error) {
    setLoading(false); 
  }
};
const handleVendorNameClick = (vendorId) => {
  // Navigate to the vendor view page
  console.log(vendorId);
  navigate(`/users/vendorview/${vendorId}`);
};

const filteredVendors = vendor.filter((vendor) =>
    vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleFirst = () => getData(0);
  const handlePrevious = () => getData(currentPage - 1);
  const handleNext = () => getData(currentPage + 1);
  const handleLast = () => getData(totalPages - 1);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);  
    let endPage = Math.min(totalPages - 1, currentPage + 2); 

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => getData(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };
  const handleLink = ( vendorId ,vendorname) => {
    setSelectedVendor({  vendorId ,vendorname});
    setIsLinkModalOpen(true);
  };
 
  const closeLink = () => {
    setSelectedVendor(null);
    setIsLinkModalOpen(false);
    getData();
  };


  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Vendors
              <input
  type="text"
  className="form-control"
  style={{
    width: '200px',  
    display: 'inline-block', 
    paddingLeft: '10px', 
  }}  placeholder="Search name"
  value={searchTerm}
  onChange={(e) => setSearchTerm(e.target.value)}
/>
              <button className="btn btn-primary" onClick={handleCreateModalOpen}  style={{ marginRight: '10px' }}>
                ADD
              </button>
             
            </div>

            <br />
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Address</th>
                    <th>PAN</th>
                    <th>GST</th>

                    {/* <th>Contact Person</th>
                    <th>Stockist</th>
                    <th>Product</th> */}
                                        {/* <th>Link</th> */}

                    <th>Action</th>


                  </tr>
                </thead>
                <tbody>
                  {filteredVendors.length === 0 ? (
                    <tr>
                      <td colSpan="15" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    filteredVendors.map((vendor) => {
                      return (
                        <tr key={vendor.id}>
 <td>
  <span
    style={{
      color: 'black',
      textDecoration: 'none',
      cursor: 'pointer',
    }}
    onMouseEnter={(e) => (e.currentTarget.style.color = 'blue')}
    onMouseLeave={(e) => (e.currentTarget.style.color = 'black')}
    onClick={() => handleVendorNameClick(vendor.id)}
  >
    {vendor.name}
  </span>
</td>
                                    <td>{vendor.email}</td>
                          <td>{vendor.phone}</td>
                          <td>{vendor.address}</td>
                          <td>{vendor.pan}</td>
                          <td>{vendor.gst}</td>
                          {/* <td>
                          <Button
                                      variant="contained"

                                    onClick={() => handleLink(vendor.id ,vendor.name)} // Passing product ID
                                >
                                    <ImPlus />

                                </Button>
                                </td> */}
                          {/* <td>
                          <button
  onClick={() => handleVendorView(vendor.id)}
  className="btn btn-primary btn-sm"  
  style={{ marginLeft: "10px" }}
>
  VIEW
</button>
                          </td>
                          <td>
                          <button
                            onClick={() => handleUpdateVendor(vendor.id)}
                            className="btn btn-primary btn-sm"  
                            style={{ marginLeft: "10px" }}
                          >
                            VIEW
                          </button>
                        </td>
                        <td>
          <button
            onClick={() => handleProductView(vendor.id)}
            className="btn btn-primary btn-sm"
            style={{ marginLeft: "10px" }}
          >
            View
          </button>
        </td> */}
        <td> 
                     <button
                      onClick={() => handleEdit(vendor.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "25px",
                            fontSize: "24px",
                            bottom:"20px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              vendor.id,
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                       
                      
                   </td>

           
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
 {/* Pagination controls */}
 <Pagination style={{ justifyContent: 'center', flexGrow: 1 }}>
              <Pagination.First onClick={handleFirst} disabled={currentPage === 0} />
              <Pagination.Prev onClick={handlePrevious} disabled={currentPage === 0} />
              {renderPageNumbers()}
              <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages - 1} />
              <Pagination.Last onClick={handleLast} disabled={currentPage === totalPages - 1} />
            </Pagination>
            <div>
              {isVendorEditModalOpen && (
                <ProducteditModalPopup
                  isOpen={isVendorEditModalOpen}
                  onRequestClose={closeVendorEditModal}
                  productId={selectedVendorId}
                />
              )}

              {isCreateModalOpen && (
                <AddVendor
                  isOpen={isCreateModalOpen}
                  onRequestClose={handleCreateModalClose}
                />
              )}

                {isVendorViewModalOpen && (
                <VendorViewModal
                  isOpen={isVendorViewModalOpen}
                  onRequestClose={closeVendorViewModal}
                  vendorId={selectedVendorId} 
                />
              )}
               {isUpdateVendorModalOpen && (
                <StockistViewModal
                  isOpen={isUpdateVendorModalOpen}
                  onRequestClose={closeUpdateVendorModal}
                  vendorId={selectedVendorId}
                />
              )}
              {isProductViewModalOpen && (
    <ProductViewModal
      isOpen={isProductViewModalOpen}
      onRequestClose={closeProductViewModal}
      vendorId={selectedVendorId}
      />
  )}

{isEditModalOpen && (
              <VendoreditModalPopup
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
                vendorId={selectedVendorId}
                />
            )}
             {isLinkModalOpen && selectedVendor && (
        <ViewLinkmapping
          isOpen={isLinkModalOpen}
          onRequestClose={closeLink}
          params={{ vendorname: selectedVendor.vendorname , vendorid: selectedVendor.vendorId,}}


        />
      )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorManagementcomponent;
