import { useState, useEffect } from 'react';
import AdminDashboard from './admindashboard';
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IconButton, Tooltip, Dialog, Pagination, DialogContent, TextField, Modal, InputAdornment, Button, MenuItem, Select, Grid } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BiEditAlt } from "react-icons/bi";


const CreditNote = () => {
  const [loader, setLoader] = useState(false);
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  const [product, setProduct] = useState([]);
  const navigate = useNavigate();
  const [orgAddress, setOrgAddress] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  console.log(selectedProducts);
  const [currentDate, setCurrentDate] = useState('');
  const [customers, setCustomers] = useState([]);
  const [tariffCharge, setTariffCharge] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showTaxDetails, setShowTaxDetails] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [openNewContactModal, setOpenNewContactModal] = useState(false);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCustomers = customers.slice(indexOfFirstItem, indexOfLastItem);




  useEffect(() => {
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
    setCurrentDate(formattedDate);

    getData();
    fetchOrgAddress();
    fetchExemptedCustomers();
  }, []);

  const getData = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/product/getProductAndPriceDetails/orgcode=${orgcode}/productstatus=${true}/productCategoryTpeId=${1}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const fetchOrgAddress = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/organizationprofiles/Orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { addressLine1, addressLine2, name, state, city, pincode, gstin, pan, logo, imageMeta, contact } = response.data;
      setOrgAddress({
        name,
        addressLine1,
        addressLine2,
        city,
        state,
        pincode,
        gstin,
        pan,
        logo,
        imageMeta,
        contact
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };
  useEffect(() => {
    console.log('Fetched Customers:', customers);
  }, [customers]);



  const searchQuotations = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const params = {
        orgcode: orgcode,
        customername: searchTerm ? encodeURIComponent(searchTerm) : undefined,
      };

      const response = await axios.get(`/api/exemptedcustomer/search`, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });

      const data = Array.isArray(response.data) ? response.data : response.data.data || [];
      setCustomers(data);
    } catch (error) {
      console.error("Error occurred during product fetch:", error);
    } finally {
      setLoader(false);
    }
  };


  const fetchExemptedCustomers = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/exemptedcustomer/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCustomers(response.data);
      setTotalItems(response.data.length);
      console.log(response.data.length);


    } catch (error) {
      handleError(error);
    }
  };
  const handleSelectContact = (customer) => {
    setSelectedCustomer(customer);
    handleCloseContactModal();
  };



  const handleAddProduct = () => {
    setSelectedProducts([...selectedProducts, { product: '', quantity: 1, amount: '', gst: '', calculatedAmount: '' }]);
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...selectedProducts];

    if (field === "product") {
      const selectedProductData = product.find((prod) => prod.productname === value);
      updatedProducts[index][field] = value;

      if (selectedProductData) {
        const basePrice = selectedProductData.pprice.price;
        const gstRate = selectedProductData.gst;
        const gstAmount = (basePrice * gstRate) / 100;
        console.log(gstAmount);

        updatedProducts[index].amount = basePrice;
        updatedProducts[index].calculatedAmount = basePrice * updatedProducts[index].quantity + gstAmount * updatedProducts[index].quantity;
        updatedProducts[index].gst = {
          totalTax: gstRate.toFixed(2),
          GstAmount: gstAmount.toFixed(2),
          cgstRate: (gstRate / 2).toFixed(2),
          sgstRate: (gstRate / 2).toFixed(2),
          CgstAmount: ((basePrice * gstRate) / 200).toFixed(2),
          SgstAmount: ((basePrice * gstRate) / 200).toFixed(2),
        };
      }
    } else if (field === "quantity") {
      const qun = updatedProducts[index][field] = value;
      console.log(updatedProducts[index][field] = value);
      const basePrice = updatedProducts[index].amount || 0;
      const gstRate = updatedProducts[index].gst?.totalTax || 0;
      console.log(gstRate);
      const BaseAmount = basePrice * qun;
      const gstAmount = (BaseAmount * gstRate) / 100;

      console.log(BaseAmount + gstAmount);

      updatedProducts[index].calculatedAmount = BaseAmount + gstAmount;
      updatedProducts[index].gst = {
        totalTax: gstRate,
        GstAmount: gstAmount.toFixed(2),
        cgstRate: (gstRate / 2).toFixed(2),
        sgstRate: (gstRate / 2).toFixed(2),
        CgstAmount: ((BaseAmount * gstRate) / 200).toFixed(2),
        SgstAmount: ((BaseAmount * gstRate) / 200).toFixed(2),
      };
    } else {
      updatedProducts[index][field] = value;
    }
    setSelectedProducts(updatedProducts);
  };


  const calculateTotalQuantity = () => {
    return selectedProducts.reduce((total, item) => total + (parseInt(item.quantity) || 0), 0);
  };

  const calculateTotalAmount = () => {
    const totalProductAmount = selectedProducts.reduce((total, item) => total + (item.calculatedAmount || 0), 0);
    const totalTariffAmount = tariffCharge * calculateTotalQuantity();
    return {
      Totalamount: (totalProductAmount + totalTariffAmount).toFixed(2),
      Totaltariff: totalTariffAmount.toFixed(2)
    }
  };
  const resultValue = calculateTotalAmount();

  const calculateSubTotal = () => {
    return selectedProducts.reduce((sum, item) => {
      const quantity = parseFloat(item.quantity) || 0;
      const price = parseFloat(item.amount) || 0;
      return sum + (quantity * price);
    }, 0).toFixed(2);
  };

  const calculateTotalTaxAmount = () => {
    return selectedProducts.reduce((sum, item) => {
      const cgst = parseFloat(item.gst.CgstAmount) || 0;
      const sgst = parseFloat(item.gst.SgstAmount) || 0;
      const totalamount = cgst + sgst;

      return sum + totalamount;
    }, 0).toFixed(2);
  };

  const toggleTaxDetails = () => {
    setShowTaxDetails(!showTaxDetails);
  };



  const handleRemoveProduct = (index) => {
    const updatedProducts = selectedProducts.filter((_, i) => i !== index);
    setSelectedProducts(updatedProducts);
  };

  const handleSaveCreditNote = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const payload = {
      orgcode,
      consumername: selectedCustomer.customername,
      creditdetails: selectedProducts.map((products) => ({
        productname: products.product,
        quantity: products.quantity,
        price: products.amount,
        gst: products.gst.totalTax,
        amount: products.calculatedAmount,
      })),
      totalamount: resultValue.Totalamount,
      tariff: tariffCharge,
      date: currentDate,
    };

    try {
      const response = await axios.post('/api/creditbill/create', payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Credit Note created successfully!");
      navigate('/users/CreditnoteList');
    } catch (error) {
      handleError(error);
    }
  };

  const numberToWords = (num) => {
    const a = [
      '', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE',
      'TEN', 'ELEVEN', 'TEWLEV', 'THIRTEEN', 'FOURTEEN', 'FIFTEEN', 'SIXTEEN', 'SEVENTEEN', 'EIGHTEEN', 'NINETEEN',
    ];
    const b = ['', '', 'TWENTY', 'THIRTY', 'FOURTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY'];

    const inWords = (n) => {
      if (n < 20) return a[n];
      if (n < 100) return `${b[Math.floor(n / 10)]} ${a[n % 10]}`.trim();
      if (n < 1000) return `${a[Math.floor(n / 100)]} HUNDRED ${inWords(n % 100)}`.trim();
      if (n < 100000) return `${inWords(Math.floor(n / 1000))} THOUSAND ${inWords(n % 1000)}`.trim();
      if (n < 10000000) return `${inWords(Math.floor(n / 100000))} LAKH ${inWords(n % 100000)}`.trim();
      return `${inWords(Math.floor(n / 10000000))} CRORE ${inWords(n % 10000000)}`.trim();
    };

    return inWords(num) || 'ZERO';
  };

  const handleOpenContactModal = () => {
    setOpenContactModal(true);
    fetchExemptedCustomers();
  };
  const handleCloseContactModal = () => setOpenContactModal(false);
  const handleOpenNewContactModal = () => setOpenNewContactModal(true);
  const handleCloseNewContactModal = () => setOpenNewContactModal(false);





  const handleBackClick = () => {
    setSearchTerm(""); // Clear the search term
    setCustomers([]); // Clear the customer list
    fetchExemptedCustomers(); // Optionally re-fetch data
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };


  const handleClose = () => {
    navigate(-1);
  };


  return (
    <div>
      <AdminDashboard className="no-print" />
      <br /><br /><br /><br />
      <div className="col-12 mx-xl-auto ps-xl-10">
        <Tooltip title="Back" arrow className="no-print">
          <IconButton onClick={() => navigate(-1)} style={{ paddingLeft: '10px', marginLeft: '20px' }}>
            <AiOutlineArrowLeft style={{ fontSize: '30px' }} />
          </IconButton>
        </Tooltip>
        <div style={{
          width: '70%',
          backgroundColor: '#fff',
          margin: '0 auto',
          padding: '20px',
          border: '1px solid #000',
          fontFamily: 'Arial, sans-serif',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        }}>
          {/* Header */}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #000', paddingBottom: '10px' }}>
            <div>
              {/* Logo Image */}
              {orgAddress?.logo && (
                <img
                  className="img-fluid mb-1"
                  id="image"
                  src={`${orgAddress.imageMeta},${orgAddress.logo}`}
                  alt="Company Logo"
                  style={{ width: '80px', height: '80px', marginRight: '15px' }}
                />
              )}

              <h2 style={{ margin: 0, fontSize: '1.5rem' }}>{orgAddress?.name || ''}</h2>
              <br />
              <p style={{ margin: '0', padding: '2px 0' }}>{orgAddress?.addressLine1 || ''}</p>
              <p style={{ margin: '0', padding: '2px 0' }}>{orgAddress?.addressLine2 || ''}</p>
              <p style={{ margin: '0', padding: '2px 0' }}>{orgAddress ? `${orgAddress.city}, ${orgAddress.state} - ${orgAddress.pincode}` : ''}</p>
              <p style={{ margin: '0', padding: '2px 0' }}>GSTIN: {orgAddress?.gstin || ''}</p>
            </div>
            <div style={{ textAlign: 'right' }}>
              <p>Date: <input type="text" value={currentDate} placeholder="DD/MM/YYYY" style={{ border: 'none', borderBottom: '1px dashed #000', outline: 'none', width: '120px' }} /></p>
              <h3 style={{ margin: 0 }}>Performa Bill</h3>
            </div>
          </div>

          {selectedCustomer ? (
            <div >
              <h5> To  <IconButton style={{ color: "blue" }} onClick={handleOpenContactModal}>
                <BiEditAlt />
              </IconButton>  </h5>

              <p style={{ marginBottom: '5px' }}> {selectedCustomer.customername}</p>
              <p style={{ marginBottom: '5px' }}> {selectedCustomer.address1},{selectedCustomer.city}</p>
              <p style={{ marginBottom: '5px' }}> {selectedCustomer.district}-{selectedCustomer.pincode}</p>
              <p style={{ marginBottom: '5px' }}> {selectedCustomer.customerNumber}</p>


            </div>
          ) : (
            <div>
              <h5>To</h5>
              <button className="btn btn-primary mb-3" onClick={handleOpenContactModal}>
                + Add a Contact...
              </button>
            </div>
          )}

          <Dialog open={openContactModal} onClose={handleCloseContactModal} maxWidth="md" fullWidth>
            <DialogContent>

              <Tooltip title="Back" arrow className="no-print">
                <IconButton
                  onClick={handleBackClick} // Call the back handler
                  style={{ paddingLeft: "10px", marginLeft: "20px" }}
                >
                  <AiOutlineArrowLeft style={{ fontSize: "30px" }} />
                </IconButton>
              </Tooltip>

              <Grid item sx={{ marginRight: '8px' }}>
                <TextField
                  variant="outlined"
                  placeholder={`Search by `}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    ),
                    sx: { height: '30px', fontSize: '15px' },
                  }}
                  sx={{ width: '150px', height: '30px' }}
                />

                <Button
                  variant="contained"
                  onClick={searchQuotations}
                  sx={{ height: '30px', width: '60px', backgroundColor: 'blue', color: 'white' }}
                >
                  search
                </Button>
              </Grid>

              <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Username</th>
                      <th>Customer No</th>
                      <th>Address1</th>
                      <th>City</th>
                      <th>District</th>
                      <th>Pincode</th>
                      

                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(currentCustomers) && currentCustomers.length > 0 ? (
                      currentCustomers.map(customer => (

                        <tr
                          key={customer.id} onClick={() => handleSelectContact(customer)}
                          style={{ cursor: "pointer" }}>
                          <td>{customer.id}</td>
                          <td>{customer.customername}</td>
                          <td>{customer.customerNumber}</td>
                          <td>{customer.address1}</td>
                          <td>{customer.city}</td>
                          <td>{customer.district}</td>
                          <td>{customer.pincode}</td>

                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No data available</td>
                      </tr>
                    )}


                  </tbody>
                </table>



              </div>

              <Pagination
                count={Math.ceil(customers.length / itemsPerPage)}
                page={currentPage}

                onChange={handlePageChange}
                sx={{
                  marginTop: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  '& .MuiPaginationItem-root': {
                    color: 'blue',
                  },
                  '& .Mui-selected': {
                    backgroundColor: 'blue',
                    color: 'white',
                  },
                }}
              />
            </DialogContent>
          </Dialog>

          {/* Credit Note Details */}
          <div style={{ margin: '20px 0', padding: '10px', border: '1px solid #000' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ width: "20%", textAlign: 'left' }}>Description</th>
                  <th style={{ width: "15%", textAlign: 'center' }}>Quantity</th>
                  <th style={{ width: "10%", textAlign: 'center' }}>Base Amount</th>
                  <th style={{ width: "20%", textAlign: 'center' }}>Tax</th>
                  <th style={{ width: "15%", textAlign: 'center' }}>Amount</th>
                  <th style={{ width: "10%", textAlign: 'center' }}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {selectedProducts.map((item, index) => (
                  <tr key={index}>
                    <td style={{ padding: '5px', borderBottom: '1px dashed #000' }}>
                      <select
                        value={item.product}
                        onChange={(e) => handleProductChange(index, 'product', e.target.value)}
                        style={{ width: '100%', border: 'none', outline: 'none' }}
                      >
                        <option value="">Select Product/Service</option>
                        {product.map((prod) => (
                          <option key={prod.id} value={prod.productname}>{prod.productname}</option>
                        ))}
                      </select>
                    </td>
                    <td style={{ padding: ' 5px', textAlign: 'center', borderBottom: '1px dashed #000' }}>
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) => handleProductChange(index, 'quantity', e.target.value)}
                        placeholder="Qty"
                        style={{ width: '30px', border: 'none', outline: 'none', textAlign: 'right' }}
                      />
                    </td>
                    <td style={{ padding: '5px', textAlign: 'center', borderBottom: '1px dashed #000' }}>
                      <input
                        value={item.amount}
                        placeholder="Amount"
                        style={{ width: '70px', border: 'none', outline: 'none', textAlign: 'right' }}
                      />
                    </td>
                    <td style={{ padding: '5px', textAlign: 'center', borderBottom: '1px dashed #000' }}>
                      <div>
                        Tax: {item.gst.totalTax || 0}% ({item.gst.GstAmount})
                      </div>
                      <div>
                        CGST: {item.gst.cgstRate}% ({item.gst.CgstAmount})
                      </div>
                      <div>
                        SGST: {item.gst.sgstRate}% ({item.gst.SgstAmount})
                      </div>
                    </td>
                    <td style={{ padding: '5px', textAlign: 'center', borderBottom: '1px dashed #000' }}>
                      {item.calculatedAmount || 0}
                    </td>
                    <td style={{ padding: '5px', textAlign: 'center', borderBottom: '1px dashed #000' }}>
                      <Tooltip title="Remove" arrow>
                        <IconButton onClick={() => handleRemoveProduct(index)}>
                          <span style={{ color: 'red', fontSize: '18px' }}>X</span>
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>


            <button onClick={handleAddProduct} style={{ marginTop: '10px' }}>Add Product</button>

            <div style={{ marginTop: '10px' }}>
              {/* Tariff Charge */}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <p style={{ margin: 0, marginRight: '10px' }}>Tariff Charge</p>
                <input
                  id="tariff"
                  value={tariffCharge}
                  onChange={(e) => setTariffCharge(e.target.value)}
                  style={{ width: '80px', padding: '5px', border: '1px solid #ccc' }}
                  readOnly
                />
              </div>

              {/* Total Quantity */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ margin: 0, marginRight: '10px' }}>Total Quantity:</p>
                <span>{calculateTotalQuantity()}</span>
              </div>
            </div>

            <div style={{ marginTop: '10px' }}>
              <p style={{ margin: '0', padding: '2px 0', display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}>
                Sub Total: {calculateSubTotal()}
              </p>
              <p style={{ margin: '0', padding: '2px 0', display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}>
                <label> Tax</label>

                <Tooltip title="Show tax breakdown">
                  <IconButton onClick={toggleTaxDetails} >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>:
                {/* </p> */}
                {/* <p style={{ margin: '0', padding: '2px 0', display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}> */}
                <label>₹ {calculateTotalTaxAmount()}</label>

              </p>

              {showTaxDetails && (
                <p
                  style={{
                    margin: '0',
                    padding: '2px 0',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontWeight: 'bold',
                    borderRadius: "0.5px", // Reduced border radius
                  }}
                >
                  <h6 >Tax Breakdown:</h6>
                  <p style={{ margin: '0 8px', padding: '2px 0', display: 'flex', justifyContent: 'flex-end' }}>
                    CGST: ₹ {selectedProducts.reduce((sum, item) => sum + parseFloat(item.gst.CgstAmount || 0), 0).toFixed(2)}
                  </p>
                  <p style={{ margin: '0 8px', display: 'flex', justifyContent: 'flex-end' }}>
                    SGST: ₹ {selectedProducts.reduce((sum, item) => sum + parseFloat(item.gst.SgstAmount || 0), 0).toFixed(2)}
                  </p>
                </p>
              )}

              <p style={{ margin: '0', padding: '2px 0', display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}>
                Total Tariff: {resultValue.Totaltariff}
              </p>
              <p style={{ margin: '0', padding: '2px 0', display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}>
                Total Amount: {resultValue.Totalamount}
              </p>
            </div>


            {/* Footer */}
            <div style={{ marginTop: '20px' }}>
              <p style={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', marginTop: '10px' }}>
                (Amount in words: {numberToWords(resultValue.Totalamount)} rupees only)
              </p>
              <p style={{ fontStyle: 'italic', textAlign: 'center', margin: '20px 0' }}>Note: This performa bill is applicable only for the date mentioned above.</p>
              <p style={{ textAlign: 'center', marginTop: '30px', padding: '20px 0' }}>For {orgAddress?.name || 'Your Company Name'}</p>
              <p style={{ textAlign: 'center' }}>Authorized Signatory</p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', paddingRight: '90px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveCreditNote}
          style={{ width: '7%' }}
        >
          Save
        </Button>

        <button
          className="btn btn-secondary"
          onClick={handleClose}
          style={{ marginLeft: '10px', height: '35px' }}                                  >
          Close
        </button>

      </div>


    </div>
  );
};

export default CreditNote;
