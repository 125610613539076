import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../api/baseurl";
import { useNavigate } from "react-router";

const DisableLinkedProducts = ({ isOpen, mapping, onRequestClose }) => {
    const [loading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(""); 
    const token = JSON.parse(localStorage.getItem("data")).token;

    const handleSave = async () => {
        if (!endDate) {
            toast.error("Please select an end date.");
            return;
        }
        setLoading(true);
        try {
            const response = await axios.post("/api/campaignProductsMapping/update", {
              id:mapping?.id,
               toDate:endDate,
            },
            {
                headers: { Authorization: `Bearer ${token}` },
              }
        );

            if (response.status === 200) {
                toast.success("Updated successfully!");
                onRequestClose();
            } else {
                throw new Error("Unexpected response status");
            }
        } catch (error) {
           handleError(error);
        } finally {
            setLoading(false);
        }
    };

     const navigate = useNavigate();
    function handleError(error) {
        if (error.response) {
          if (error.response.status === 401) {
            navigate(`/signin`);
            toast.error("Your session has expired. Please login.");
          } else if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          }  else if (error.response.data && error.response.data.code) {
            toast.error(error.response.data.code);
          }
           else {
            toast.error("An error occurred.");
          }
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred.");
        }
      }
    

    return (
        <Modal show={isOpen} onHide={onRequestClose} centered>
            {loading && <div className="loading"></div>}
            <Modal.Header closeButton>
                <Modal.Title>Disable Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Please select a end date for disabling the product.
                </p>
                <Form>
                    <Form.Group controlId="formEndDate">
                        <Form.Label>To Date <span style={{ color: "red" }}>*</span></Form.Label>
                        <Form.Control
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            required
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="me-3" onClick={onRequestClose} >
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DisableLinkedProducts;
