import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card, CardContent, Grid, Typography, Button,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, Table, TableHead, TableBody, TableRow, TableCell
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Balance } from "@mui/icons-material";

function CustomerOrder() {
  const [loader, setLoader] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false); // To control the "View" modal
  const [orders, setOrders] = useState([]);
  const [refillProducts, setRefillProducts] = useState([]);
  const [newOrder, setNewOrder] = useState({
    customername: "",
      product: "",
      otherProduct: "",
      phonenumber: "",
      description: "",
      requestedproduct: "",
      estimatedprice:"",
      advancepaid: "",
      balance:"",
  });
  const [viewOrder, setViewOrder] = useState(null); // To hold the selected order details

  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  const navigate = useNavigate();

  const fetchOrders = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data"))?.token;
      const response = await axios.get("/api/CustomerOrder", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrders(response.data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch orders");
    }
  };

  const fetchRefillProducts = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data"))?.token;
      const response = await axios.get(`/api/product/productstatus=true/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRefillProducts(response.data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch products");
    }
  };

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    setNewOrder({
      customername: "",
      product: "",
      otherProduct: "",
      phonenumber: "",
      description: "",
      requestedproduct: "",
      estimatedprice:"",
      advancepaid: "",
      balance:"",
    });
  };

  const handleAddOrder = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data"))?.token;

      const requestPayload = {
        ...newOrder,
        product: newOrder.product === "Other" ? newOrder.otherProduct : newOrder.product,
      };

      await axios.post("/api/CustomerOrder/create", requestPayload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success("Order created successfully!");
      handleCreateModalClose();
      fetchOrders();
    } catch (error) {
      console.error(error);
      toast.error("Failed to create order");
    } finally {
      setLoader(false);
    }
  };

  const handleViewOrder = (order) => {
    setViewOrder(order);
    setIsViewModalOpen(true);
  };

  const handleViewModalClose = () => {
    setIsViewModalOpen(false);
    setViewOrder(null);
  };

  useEffect(() => {
    fetchOrders();
    fetchRefillProducts();
  }, []);

  return (
    <div>
      <AdminDashboard />
      <br /><br /><br/><br/><br/>

      {loader && <div className="loading"></div>}

      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <Card>
            <CardContent>
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="h5">Customer Orders</Typography>
                <Button
                  variant="contained"
                  onClick={handleCreateModalOpen}
                  sx={{
                    backgroundColor: 'blue',
                    color: 'white',
                    '&:hover': { backgroundColor: 'darkblue' },
                  }}
                >
                  Add Order
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>

      <div style={{ display: "flex", height: "100vh", marginTop: "20px" }}> {/* Reduced marginTop */}
        <div
          style={{
            marginLeft: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <Card style={{ width: "80%" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ marginBottom: "10px" }}> {/* Reduced margin */}
                Customer Orders List
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Customer Name</strong></TableCell>
                    <TableCell><strong>Product</strong></TableCell>
                    <TableCell><strong>PhoneNumber</strong></TableCell>
                    <TableCell><strong>Description</strong></TableCell>
                    <TableCell><strong>Requested Product</strong></TableCell>
                    <TableCell><strong>Estimated Price</strong></TableCell>
                    <TableCell><strong>Advance Paid</strong></TableCell>
                    <TableCell><strong>Balance</strong></TableCell>

                    <TableCell><strong>Action</strong></TableCell> {/* Added Action Column */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>{order.customername}</TableCell>
                      <TableCell>{order.product}</TableCell>
                      <TableCell>{order.phonenumber}</TableCell>
                      <TableCell>{order.description}</TableCell>
                      <TableCell>{order.requestedproduct}</TableCell>
                      <TableCell>{order.estimatedprice}</TableCell>
                      <TableCell>{order.advancepaid}</TableCell>
                      <TableCell>{order.balance}</TableCell>
                     
                      <TableCell>
  <Button
    variant="outlined"
    onClick={() => handleViewOrder(order)}
  >
    <VisibilityIcon />
  </Button>
</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* View Order Modal */}
      <Dialog open={isViewModalOpen} onClose={handleViewModalClose}>
        <DialogTitle>View Order Details</DialogTitle>
        <DialogContent>
          {viewOrder && (
            <>
              <p><strong>Customer Name:</strong> {viewOrder.customername}</p>
              <p><strong>Product:</strong> {viewOrder.product}</p>
              <p><strong>Phone Number:</strong> {viewOrder.phonenumber}</p>
              <p><strong>Requested Product:</strong> {viewOrder.requestedproduct}</p>
              <p><strong>Description:</strong> {viewOrder.description}</p>
              <p><strong>Estimated Paid:</strong> {viewOrder.advancepaid}</p>
              <p><strong>Advance Paid:</strong> {viewOrder.advancepaid}</p>
              <p><strong>Balance:</strong> {viewOrder.balance}</p>

            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewModalClose} color="secondary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Modal for creating an order */}
      <Dialog open={isCreateModalOpen} onClose={handleCreateModalClose}>
        <DialogTitle>Add New Order</DialogTitle>
        <DialogContent>
          <TextField
            label="Customer Name"
            fullWidth
            margin="normal"
            value={newOrder.customername}
            onChange={(e) => setNewOrder({ ...newOrder, customername: e.target.value })}
          />
          <TextField
            label="Product"
            select
            fullWidth
            margin="normal"
            value={newOrder.product}
            onChange={(e) => setNewOrder({ ...newOrder, product: e.target.value })}
          >
            {refillProducts.map((p) => (
              <MenuItem key={p.id} value={p.productcode}>
                {p.productname}
              </MenuItem>
            ))}
            <MenuItem value="Other">Other</MenuItem>
          </TextField>
          {newOrder.product === "Other" && (
            <TextField
              label="Specify Product"
              fullWidth
              margin="normal"
              value={newOrder.otherProduct}
              onChange={(e) => setNewOrder({ ...newOrder, otherProduct: e.target.value })}
            />
          )}
          <TextField
            label="Phone Number"
            fullWidth
            margin="normal"
            value={newOrder.phonenumber}
            onChange={(e) => setNewOrder({ ...newOrder, phonenumber: e.target.value })}
          />
          
          <TextField
            label="Description"
            multiline
            rows={3}
            fullWidth
            margin="normal"
            value={newOrder.description}
            onChange={(e) => setNewOrder({ ...newOrder, description: e.target.value })}
          />
          <TextField
            label="Requested Product"
            fullWidth
            margin="normal"
            value={newOrder.requestedproduct}
            onChange={(e) => setNewOrder({ ...newOrder, requestedproduct: e.target.value })}
          />
          <TextField
            label="Estimated price"
            fullWidth
            margin="normal"
            value={newOrder.estimatedprice}
            onChange={(e) => setNewOrder({ ...newOrder, estimatedprice: e.target.value })}
          />
          <TextField
            label="Advanced Paid"
            fullWidth
            margin="normal"
            value={newOrder.advancepaid}
            onChange={(e) => setNewOrder({ ...newOrder, advancepaid: e.target.value })}
          />
          
          <div style={{ marginTop: "20px" }}>
            <Button
              variant="outlined"
              sx={{ margin: "10px 10px 0 0" }}
              onClick={() => navigate('/users/Purchases')}
            >
              Purchase Order
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate('/users/AddInvoice')}
            >
              Invoice
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateModalClose} color="secondary">Cancel</Button>
          <Button onClick={handleAddOrder} color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CustomerOrder;
