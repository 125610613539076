import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "../components/admindashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../images/report.png";
import './loadin.css';

function LoadinReport() {
  const [orgCode, setOrgCode] = useState("");
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState("");
  const [productTypeid, setProductTypeid] = useState("");
  const [reportData, setReportData] = useState(null);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [columnTotals, setColumnTotals] = useState({});
  const [invoiceData, setInvoiceData] = useState(null);

  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/productcategories/orgcode=${orgcode}/disable=false`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProductData(response.data);
      } catch (error) {
        console.error("Error fetching product types:", error.response || error);
        toast.error("Failed to load product types.");
      }
    };

    fetchProductTypes();
  }, []);

  const fetchReportData = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
  
      // Fetch products based on selected product type
      const productResponse = await axios.get(
        `/api/product/orgcode=${orgCode}/productstatus=true/productTypeId=${productTypeid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProducts(productResponse.data);
  
      // Fetch load-in report data
      const response = await axios.get(`/api/loadintotal/orgcode=${orgCode}/producttypeid=${productTypeid}/month=${month.getMonth() + 1}/year=${year}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Fetch invoice data
      const invoiceResponse = await axios.get(
        `/api/invoicetotal/orgcode=${orgCode}/month=${month.getMonth() + 1}/year=${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setInvoiceData(invoiceResponse.data);
  
      // Group data by date, truck info, and invoice ID
      const groupedData = response.data.daily_totals.reduce((acc, current) => {
        const existing = acc.find(item => item.date === current.date && item.truckinfo === current.truckinfo && item.invoiceid === current.invoiceid);
        if (existing) {
          existing.products.push({
            productname: current.productname,
            quantity: current.quantity,
          });
          // Add financial data to the existing entry
          existing.taxableValue += current.taxableValue;
          existing.taxes += current.taxes;
          existing.deliveryCharges += current.deliveryCharges;
        } else {
          acc.push({
            date: current.date,
            truckinfo: current.truckinfo,
            invoiceid: current.invoiceid, // Add invoiceid to the grouped data
            products: [{
              productname: current.productname,
              quantity: current.quantity,
            }],
            taxableValue: current.taxableValue,
            taxes: current.taxes,
            deliveryCharges: current.deliveryCharges,
          });
        }
        return acc;
      }, []);
  
      // Calculate column totals for each product
      const totals = {};
      groupedData.forEach(row => {
        row.products.forEach(prod => {
          if (!totals[prod.productname]) {
            totals[prod.productname] = 0;
          }
          totals[prod.productname] += prod.quantity;
        });
      });
  
      setReportData(groupedData);
      setColumnTotals(totals);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching report data:", error);
      toast.error("Failed to load report data.");
      setLoading(false);
    }
  };
  
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "year") setYear(value);
    if (id === "productType") setProductTypeid(value);
  };

  const handleMonthChange = (date) => {
    setMonth(date);
    if (date) {
      setYear(date.getFullYear());
    }
  };

  const generatePDF = () => {
    if (!reportData) {
      toast.error("No data available to generate PDF.");
      return;
    }
  
    try {
      const doc = new jsPDF("landscape"); // Generate PDF in landscape orientation
  
      // Add logo to the left side
      const imgData = img;
      const logoWidth = 50;
      const logoHeight = 20;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);
  
      // Add company name and address to the right side
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const textX = doc.internal.pageSize.width - 10;
      let textY = logoY + 10;
      doc.text("Vijay Gas Agencies", textX, textY, { align: "right" });
      textY += 8;
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      textY += 4;
      doc.text(addressText, textX, textY, { align: "right" });
  
      // Add report name below the logo
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      const reportName = "Monthly Load-In Report";
      const reportNameY = logoY + logoHeight + 10;
      doc.text(reportName, logoX, reportNameY);
  
      // Add month details below the report name
      doc.setFontSize(12);
      const monthText = `Month: ${
        month ? `${month.getMonth() + 1}/${month.getFullYear()}` : "N/A"
      }`;
      const detailsY = reportNameY + 8; // Spacing below the report name
      doc.text(monthText, logoX, detailsY);
  
      // Generate the table with the report data
      const tableColumn = [
        "Date",
        "Invoice ID",
        "Truck Info",
        "Taxable Value",
        "Taxes",
        "Delivery Charges",
        ...products.map((p) => p.productname),
        "Total", // Add a Total column
      ];
      const tableRows = [];
  
      const productTotals = products.map(() => 0); // Array to store totals for each product
  
      reportData.forEach((row) => {
        const productQuantities = products.map((p, index) => {
          const prod = row.products.find((pr) => pr.productname === p.productname);
          const quantity = prod ? prod.quantity : 0;
          productTotals[index] += quantity; // Accumulate the total for this product
          return quantity;
        });
  
        const rowData = [
          row.date,
          row.invoiceid,
          row.truckinfo,
          row.taxableValue || 0, // Add taxable value
          row.taxes || 0, // Add taxes
          row.deliveryCharges || 0, // Add delivery charges
          ...productQuantities,
          productQuantities.reduce((sum, q) => sum + q, 0), // Calculate row total
        ];
        tableRows.push(rowData);
      });
  
      // Add column totals row
      const columnTotals = {
        taxableValue: reportData.reduce((sum, row) => sum + (row.taxableValue || 0), 0),
        taxes: reportData.reduce((sum, row) => sum + (row.taxes || 0), 0),
        deliveryCharges: reportData.reduce((sum, row) => sum + (row.deliveryCharges || 0), 0),
      };
  
      const totalRow = [
        "Total",
        "",
        "",
        columnTotals.taxableValue,
        columnTotals.taxes,
        columnTotals.deliveryCharges,
        ...productTotals,
        productTotals.reduce((sum, q) => sum + q, 0), // Total of all products
      ];
      tableRows.push(totalRow);
  
      // Adjust margin to accommodate details and table
      const tableMarginTop = detailsY + 16;
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        margin: { top: tableMarginTop },
        styles: { fontSize: 8 },
        columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 30 } },
      });
  
      // Save the generated PDF
      doc.save("monthly_loadin_report.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Failed to generate PDF.");
    }
  };
  

  return (
    <div className="container d-flex flex-column align-items-center">
      <AdminDashboard />
      <br /><br /><br />
      <div className="card card-raised mt-4" style={{ width: "100%", maxWidth: "800px" }}>
        <div className="card-body">
          <br />
          <div className="col-xl-10 mx-xl-auto">
            <div className="card card-raised mb-3">
              <div className="card-body p-4">
              <form onSubmit={(e) => e.preventDefault()} className="text-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <img className="top-bar__logo" src={img} alt="logo" />
                    <div className="ml-3">
                      <h3 className="report-title">Vijay Gas Agencies</h3>
                      <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
                    </div>
                  </div>
                  <br />
                  <div>
                    <label htmlFor="month">Month:</label>
                    <DatePicker
                      selected={month}
                      onChange={handleMonthChange}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </div>
                  <br />
                  <div>
                    <label htmlFor="productType">Product Category:</label>
                    <select
                      id="productType"
                      value={productTypeid}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Product Category</option>
                      {productData.map((product) => (
                        <option key={product.id} value={product.id}>
                          {product.categoryname}
                        </option>
                      ))}
                    </select>
                  </div>
                  <br />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={fetchReportData}
                    disabled={loading || !productTypeid || !month || !year}
                  >
                    Generate Report
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={generatePDF}
                    disabled={!reportData}
                  >
                    Download PDF
                  </button>
                </form>

                {loading && <div className="loading">Loading...</div>}
                {reportData && reportData.length > 0 && (
  <div className="table-responsive">
    <table className="table table-bordered mt-3">
      <thead>
        <tr>
          <th>Date</th>
          <th>Invoice ID</th>
          <th>Truck Info</th>
          {products.map((p, index) => (
            <th key={index}>{p.productname}</th>
          ))}
          <th>Taxable Value</th>
          <th>Taxes</th>
          <th>Delivery Charges</th>
        </tr>
      </thead>
      <tbody>
        {reportData.map((row, index) => (
          <tr key={index}>
            <td>{row.date}</td>
            <td>{row.invoiceid}</td>
            <td>{row.truckinfo}</td>
            {products.map((p) => {
                const prod = row.products.find((pr) => pr.productname === p.productname);
                return <td key={p.productname}>{prod ? prod.quantity : 0}</td>;
              })}
              <td>{row.taxableValue.toFixed(2)}</td>
              <td>{row.taxes.toFixed(2)}</td>
              <td>{row.deliveryCharges.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={3}>Total</th>
            {products.map((p) => (
              <th key={p.productname}>{columnTotals[p.productname] || 0}</th>
            ))}
            <th>
              {reportData.reduce((sum, row) => sum + row.taxableValue, 0).toFixed(2)}
            </th>
            <th>
              {reportData.reduce((sum, row) => sum + row.taxes, 0).toFixed(2)}
            </th>
            <th>
              {reportData.reduce((sum, row) => sum + row.deliveryCharges, 0).toFixed(2)}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default LoadinReport;
