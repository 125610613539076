import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

function AdminpasswordeditModalPopup({ isOpen, onRequestClose }) {
  const [uproduct1, setUproduct1] = useState({
    adminPassword: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [currentPassword, setCurrentPassword] = useState(""); 
  const [validationError, setValidationError] = useState(""); // Handle error messages
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setUproduct1({
      adminPassword: "",
      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    });
    setCurrentPassword("");
    setValidationError("");
    onRequestClose();
  };
  const token = JSON.parse(localStorage.getItem("data")).token;

  const validateCurrentPassword = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/api/accounts/validate-admin-password",
        { adminPassword: currentPassword }   , 
            { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data === "Password is correct") {
        setValidationError(""); 
      } else {
        setValidationError("Incorrect password"); 
      }
    } catch (error) {
      console.error("Password validation failed", error);
      setValidationError("Incorrect password");
    } finally {
        setLoading(false); // Hide loader after validation
      }
    };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "/api/accounts/update-admin-password",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success("Password updated successfully");
      setUproduct1({
        adminPassword: "",
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      });
      setCurrentPassword("");
      onRequestClose();

    } catch (error) {
      console.error("Password update failed", error);
      toast.error("Failed to update password");
    } finally {
      setLoading(false);
    }
  };
  const rhandle = (event, field) => {
    const value = event.target.value;
    setUproduct1((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {loading && <div className="loading"></div>} 

          <div className="form-group mb-3">
            <label>Enter Current Password</label>
            <input
              type="password"
              className="form-control"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              onBlur={validateCurrentPassword}
              placeholder="Current Password"
              required
            />
            {validationError && (
              <small style={{ color: "red", display: "block" }}>
                {validationError}
              </small>
            )}
          </div>
          <form onSubmit={handleSave}>

          <div className="form-group mb-3">
            <label>Enter New Password</label>
            <input
              type="password"
              className="form-control"
              id="adminPassword"

              value={uproduct1.adminPassword}
             
              placeholder="Admin Password"
              onChange={(e) => rhandle(e, "adminPassword")}

              disabled={validationError !== ""}
              required
            />
          </div>

          <div className="d-flex justify-content-between">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={validationError !== "" || loading}
            >
              Save
            </button>
            <Button variant="secondary" onClick={onRequestClose}>
              Back
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AdminpasswordeditModalPopup;
