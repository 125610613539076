import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";

import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import AdministratorSettings from "./AdministratorSettings";
import AdminpasswordeditModalPopup from "./Updateadminpassword";

 
 
function Settings() {
  const [isAdminSettingsOpen, setIsAdminSettingsOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [adminData, setAdminData] = useState(null);
  const userId = JSON.parse(localStorage.getItem("data"))?.userid;
  
  const handleOpenAdminSettings = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      const url = `api/accounts/${userId}`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdminData(response.data);

      if (response.data.adminPassword) {
        setIsEditModalOpen(true);
      } else {
        setIsAdminSettingsOpen(true);
      }
    } catch (error) {
      console.error("Error fetching admin data:", error);
    }
  };
  const handleCloseAdminSettings = () => {
    setIsAdminSettingsOpen(false);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  return (
    <div>
    <div>
      <AdminDashboard />
    </div>
    <br />
    <br />
    <br />
    <br />

    <div className="col-12 mx-xl-auto ps-xl-10">
      <div className="card card-raised">
        <div className="card-body">
          <div className="card-title form-group d-flex align-items-center justify-content-between">
            Settings
          </div>
           <br></br>
            <div>
            <Link
              to="/users/api-settings">
              API Settings
             </Link>
            </div>
            <br></br>
            <div>
            <Link
              to="/users/ProfileSettings">
              Profile
             </Link>
          </div>
          <br></br>
            <div>
            <Link
              to="/users/organization-settings">
              organization Settings
             </Link>
          </div>
          <br></br>
          
          <div>
          <Link
              to="/users/SQCSettings">
              SQC Settings         
            </Link>
          </div>
          <br></br>
          <div>
          <Link
              to="/users/tariff-freight">
              Tariff Freight
             </Link>
          </div>
         
          <div className="mt-4">
          <Link
              to="/users/performance">
              Performance Settings
             </Link>
          </div>
         
          <div className="mt-4">
              <span
                className="link-like"
                style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                onClick={handleOpenAdminSettings}
              >
                Administrator Settings
              </span>
            </div>
          <br></br>
          {/* <div className="mt-4">
          <Link
              to="/users/CurrencyConfiguration">
              Currency Configuration
             </Link>
          </div> */}
           
           
          </div>
        </div>
      </div>
      <AdministratorSettings
        isOpen={isAdminSettingsOpen}
        onRequestClose={handleCloseAdminSettings}
      />
       <AdminpasswordeditModalPopup
        isOpen={isEditModalOpen}
        onRequestClose={handleCloseEditModal}
        adminData={adminData} 
      />
    </div>
  );
}

export default Settings;