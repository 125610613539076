import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Button, Pagination, Form } from "react-bootstrap"; // Import Pagination from react-bootstrap
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router";
import Search from "./search";
import { FaMedal } from "react-icons/fa";
import DisableLinkedProducts from "./disableLinkedProducts";

function CampaignRewrdPoints() {
  const { campaignId } = useParams();

  const [loader, setLoader] = useState(false);
  const [searchOption, setSearchOption] = useState("MONTH");
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [typeFilter, setTypeFilter] = useState("");
  const [campaignRewards, setCampaignRewards] = useState([]);
  const [productsQty, setProductsQty] = useState([]);
  const [campaignName, setCampaignName] = useState(null);
  const [linkedProducts, setLinkedProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState(null);

  const date = new Date();
  const date1 = new Date();
  date.setDate(date.getDate());
  date1.setDate(date1.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  let currentDate1 = `${year}-${month}`;
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

 
    const fetchData = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoader(true);

      try {
        const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
        const campRes = await axios.get(`/api/campaign/${campaignId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCampaignName(campRes.data.campaignName);

        // Fetching current date data initially
        const url = `/api/rewardpoints/orgcode=${orgCode}/campaignId=${campaignId}/date=${currentDate}`;
        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const rewardsList = Array.isArray(response.data) ? response.data : [response.data];

  // Set campaign rewards
  setCampaignRewards(rewardsList);

  // Process each reward to fetch product quantities
  const productsQtyData = await Promise.all(
    rewardsList.map(async (reward) => {
      const qtyResponse = await axios.get(
        `/api/campaignProductsMappingDetails/rewardsPointId=${reward.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return { rewardId: reward.id, products: qtyResponse.data };
    })
  );

  setProductsQty(productsQtyData);

        const linkedProductsRes = await axios.get(`/api/campaignProductsMapping/campaignId=${campaignId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setLinkedProducts(linkedProductsRes.data);

        setLoader(false);
      } catch (error) {
        handleError(error);
      }
    };

    useEffect(() => {
    fetchData();
  }, [campaignId]);

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred.");
    }
    setLoader(false);
  };

  const handleFilterChange = (event) => {
    const selectedOption = event.target.value;
    setSearchOption(selectedOption);
    setSelectedMonth(null);
    setSelectedYear(null);
  };

  const searchByMonth = async (month, year) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      const searchResponse = await axios.get(
        `/api/rewardpoints/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/campaignId=${campaignId}/${month}/${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCampaignRewards([...searchResponse.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const searchByYear = async (year) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      const searchResponse = await axios.get(
        `/api/rewardpoints/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/campaignId=${campaignId}/${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCampaignRewards([...searchResponse.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const campaignRewardsSearch = async (text) => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios
      .get(
        `/api/rewardpoints/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/campaignId=${campaignId}/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setCampaignRewards(response.data);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // const handleDelete = async (id) => {
  //     const token = JSON.parse(localStorage.getItem("data")).token;
  //     try {
  //       await axios.delete(`/api/accounts/delete/${id}`, {
  //         headers: { Authorization: `Bearer ${token}` },
  //       });
    
  //       toast.success("Account Deleted successfully");
  //       getData();
  //     } catch (error) {
  //       console.error("Error deleting Account:", error);
  //       toast.error("Error deleting Account");
  //     }
  //   };

  
  const handleDisable = (mapping) => {
    setSelectedMapping(mapping);
    setShowModal(true);
  };

  const handleClose = () => {
    setSelectedMapping(null);
    setShowModal(false);
    fetchData();
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="row">

<div className="col-md-8">
      <div className="col-12 mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="card-title form-group mb-4">
                Rewards Points 
              </div>
              <Search searchName={campaignRewardsSearch} />
              {/* <div>
                <label>FILTER :</label>
                <select value={searchOption} onChange={handleFilterChange}>
                  <option value="MONTH">Month</option>
                  <option value="YEAR">Year</option>
                </select>
                &ensp;
                {searchOption === "MONTH" && (
                  <React.Fragment>
                    <Form.Label>Month:</Form.Label>
                    <DatePicker
                      selected={
                        selectedMonth !== null && selectedYear !== null
                          ? new Date(selectedYear, selectedMonth)
                          : null
                      }
                      onChange={(date) => {
                        const month = date.getMonth() + 1;
                        const year = date.getFullYear();
                        setSelectedYear(year);
                        setSelectedMonth(date.getMonth());
                        searchByMonth(month, year);
                      }}
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      placeholderText="Select Month"
                    />
                  </React.Fragment>
                )}
                {searchOption === "YEAR" && (
                  <React.Fragment>
                    <Form.Label>Year:</Form.Label>
                    <DatePicker
                      selected={selectedYear ? new Date(selectedYear, 0) : null}
                      onChange={(date) => {
                        const year = date.getFullYear();
                        setSelectedYear(year);
                        searchByYear(year);
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                      placeholderText="Select Year"
                    />
                  </React.Fragment>
                )}
              </div> */}
            </div>
            <h5>{campaignName}</h5>
            <br />
            <div className="row">
              {campaignRewards.length === 0 ? (
                <div className="col text-center">No Data Available</div>
              ) : (
                campaignRewards.map((cam, index) => (
                  <div key={index} className="col-6 mb-4">
                    <Card>
                      <Card.Body>
                        <div className="text-center">
                          <Card.Title className="mb-1" >{cam.name}</Card.Title>
                          <div className="d-flex justify-content-between align-items-center">
                <div>
                <Card.Text>
                            <FaMedal /> {cam.points}
                          </Card.Text>
                </div>
                {/* <div>
                  <Card.Text>Total Quantity: {cam.totalQty || 0}</Card.Text>
                  </div> */}
                  
                  {/* Display productsQty details below totalQty */}
                  <div>
          {productsQty.map((qtyData, qtyIndex) => {
            if (qtyData.rewardId === cam.id) {
              return (
                <div key={qtyIndex}>
    
                  {qtyData.products.length > 0 && (
                    qtyData.products.map((product, productIndex) => (
                      <div key={productIndex}>
                        <Card.Text>
                           {product.camProducts?.product?.productname} - {product.quantity} Qty
                        </Card.Text>
                        
                      </div>
                    ))
                  ) }
                  <div>
                  <Card.Text>Total Quantity: {cam.totalQty || 0}</Card.Text>
                  </div>
                </div>
              );
            }
            return null;
          })}
          </div>
          </div>
               
             
                         
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                ))
              )}
              
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="col-md-4"> 
  <div>
    <div className="card card-raised">
      <div className="card-body">
      <div className="card-title mb-2">
                Products:
              </div>
<div className="table-responsive">
  <table className="table table-bordered">
    <thead>
      <tr>
        <th>#</th>
        <th>Product Name</th>
        <th>Product Code</th>
        <th>From Date</th>
        <th>To Date</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {linkedProducts.length === 0 ? (
        <tr>
          <td colSpan="10" className="text-center">
            No Linked Products
          </td>
        </tr>
      ) : (
        linkedProducts.map((mapping, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{mapping.product?.productname}</td>
            <td>{mapping.product?.productcode}</td>
            <td>{mapping.fromDate}</td>
            <td>{mapping.toDate}</td>

            <td>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => handleDisable(mapping)}
                disabled={mapping.toDate !== null && mapping.toDate !== ""} // Disable button if toDate is not null
              >
                Disable
              </button>
            </td>

          </tr>
        ))
      )}
    </tbody>
  </table>
</div>

        </div>
        </div>
        </div>
        </div>
        

    </div>
    {showModal && (
  <DisableLinkedProducts
    isOpen={showModal}
    mapping={selectedMapping}
    onRequestClose={handleClose}
  />
)}
    </div>

    
  );
}

export default CampaignRewrdPoints;
