import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";

const AddVendorProduct = ({ isOpen, onRequestClose ,vendorId, categoryId}) => {
  console.log(vendorId);
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const initialProductState = {
    vendorid:vendorId,
    productname: "",
    productcode: "",
    date: currentDate,
    productstatus: "",
    productCategoryId:categoryId,
    consumerTypeId:"",
    hsnCode: "",
    netWeight: "",
    gst: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    pprice: {
      price: 0,
    },
    addPrice: {
      additionalPrice: 0,
    },
    newConPrice: {
      newConPrice: 0,
    },
    productPoints: [
      { points: "", pointsType: "sales" },
      { points: "", pointsType: "additional" },
      { points: "", pointsType: "new connection" }
    ]
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [categoryname, setCategoryname] = useState([]);
  const [categoryError, setCategoryError] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const [customerType, setCustomerTypes] = useState([]);
  const [categoryTypeId, setCategoryTypeId] = useState(null);
  const [selectedCategoryType, setSelectedCategoryType] = useState("");  const url = "/api/product/create";
  const [areFieldsEnabled, setAreFieldsEnabled] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchProductTypes();
  }, []);

  const fetchProductTypes = async () => {
    setLoader(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const response = await axios.get(`/api/productcategories/orgcode=${orgcode}/disable=false`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCategoryname(response.data);
      setLoader(false);

      const consumerRes = await axios.get("/api/consumertype", {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCustomerTypes(consumerRes.data);
      setLoader(false);

    } catch (error) {
      setLoader(false);

      handleError(error);
    }
  };
  useEffect(() => {
    if (isOpen && categoryId && categoryname.length > 0) {
        // Ensure categoryId and category.id types match
        const selectedCategory = categoryname.find(category => category.id === Number(categoryId)); 
        if (selectedCategory) {
            setNewProduct(prevState => ({
                ...prevState,
                productCategoryId: selectedCategory.id,
            }));

            const categoryType = selectedCategory.categoryType ? selectedCategory.categoryType.categorytype : "";
            setSelectedCategoryType(categoryType);
            const categoryTypeId = selectedCategory.productCategoryTypeId ? selectedCategory.productCategoryTypeId : "";
            setCategoryTypeId(categoryTypeId);
      
} else {
    console.error("No matching category found for ID:", categoryId);
}
}
}, [isOpen, categoryId, categoryname]);


  const handleCategoryChange = (e) => {
    const selectedId = parseInt(e.target.value, 10); // Parse to integer
    const selectedCategory = categoryname.find(category => category.id === selectedId);

    if (selectedCategory) {
        setNewProduct(prevState => ({
            ...prevState,
            productCategoryId: selectedId
        }));

        // Check if categoryType is present and access categorytype
        const categoryType = selectedCategory.categoryType ? selectedCategory.categoryType.categorytype : "";
        setSelectedCategoryType(categoryType); 
        const categoryTypeId = selectedCategory.productCategoryTypeId ? selectedCategory.productCategoryTypeId : "";
        setCategoryTypeId(categoryTypeId);
    } else {
        setSelectedCategoryType(""); 
        setCategoryTypeId(null);
    }
};
const validateFields = () => {
  const errors = {};

  if (!newProduct.productCategoryId) {
    errors.productCategoryId = "Category Name is required.";
  }
  if (!newProduct.productname.trim()) {
    errors.productname = "Product Name is required.";
  }
  if (!newProduct.productcode.trim()) {
    errors.productcode = "Product Code is required.";
  }
  // if (!newProduct.hsnCode.trim()) {
  //   errors.hsnCode = "HSN Code is required.";
  // }
 
  if (!newProduct.gst) {
    errors.gst = "GST is required.";
  }
  if (!newProduct.pprice?.price || newProduct.pprice.price <= 0) {
    errors.pprice = "Sales Price is required .";
  }
  // if (!newProduct.productPoints?.[0]?.points) {
  //   errors.salesPoints = "Sales Points are required.";
  // }

  if (categoryTypeId !== 2) {
    if (!newProduct.netWeight) {
      errors.netWeight = "Net Weight is required.";
    }
    // if (!newProduct.consumerTypeId) {
    //   errors.consumerTypeId = "Customer Type is required.";
    // }
    if (!newProduct.addPrice?.additionalPrice || newProduct.addPrice.additionalPrice <= 0) {
      errors.additionalPrice = "Additional Price is required .";
    }
    // if (!newProduct.productPoints?.[1]?.points) {
    //   errors.additionalPoints = "Additional Points are required.";
    // }
    if (!newProduct.newConPrice?.newConPrice || newProduct.newConPrice.newConPrice <= 0) {
      errors.newConPrice = "New Connection Price is required .";
    }
    // if (!newProduct.productPoints?.[2]?.points) {
    //   errors.newConPoints = "New Connection Points are required.";
    // }
  }

  // Ensure image is provided if it's required
  // if (!newProduct.image) {
  //   errors.image = "Product image is required.";
  // }

  return errors;
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFields();
  setValidationErrors(errors);

  if (Object.keys(errors).length > 0) {
    return; // Exit early if validation fails
  }

    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Product created successfully");
      onRequestClose();
      setNewProduct(initialProductState);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
      toast.error("An error occurred while creating the product");
    }
    setLoading(false);
  };

  const rhandle = (e) => {
    const { id, value } = e.target;
  
    // Using setNewProduct to handle nested fields
    setNewProduct(prevState => {
      const newdata = { ...prevState }; // Create a shallow copy of prevState
  
      // Handle price updates
      if (id.startsWith("pprice")) {
        newdata.pprice = {
          ...newdata.pprice,
          price: Number(value), // Ensure price is a number
        };
      } else if (id.startsWith("addPrice")) {
        newdata.addPrice = {
          ...newdata.addPrice,
          additionalPrice: Number(value), // Ensure additionalPrice is a number
        };
      } else if (id.startsWith("newConPrice")) {
        newdata.newConPrice = {
          ...newdata.newConPrice,
          newConPrice: Number(value), // Ensure newConPrice is a number
        };
      } 
      // Handle points updates
      else if (id === "productPoints.sales") {
        newdata.productPoints[0] = {
          ...newdata.productPoints[0], // Preserve other properties if necessary
          points: Number(value), // Ensure points are a number
        };
      } else if (id === "productPoints.additional") {
        newdata.productPoints[1] = {
          ...newdata.productPoints[1],
          points: Number(value),
        };
      } else if (id === "productPoints.new connection") {
        newdata.productPoints[2] = {
          ...newdata.productPoints[2],
          points: Number(value),
        };
      } else {
        newdata[id] = value; // Handle other inputs generically
      }
  
      return newdata; // Return the new state
    });
  };

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       const [metadata, content] = reader.result.split(",");
       resolve({ metadata, content });};
       reader.onerror = function (error) {
       reject(error);};}); }

       const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const { metadata, content } = await getBase64FromFile(file);
     
      setNewProduct(prevState => ({
        ...prevState,
        imageMeta: metadata,
        image: content
      }));
          } catch (error) {
            console.error("Error converting file to base64", error);
          }
        }
      };


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {loader && <div className="loading"></div>} 

        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form onSubmit={handleSubmit}>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productCategoryId">Category Name</label>
                    <select
                className={`form-control ${validationErrors.productCategoryId ? "is-invalid" : ""}`}
                id="productCategoryId"
                      type="text"
                      placeholder="Category Name"
                      value={newProduct.productCategoryId}
                      onChange={handleCategoryChange}
                      readOnly
                      >
                      <option value="">Select Category Name</option>
                      {categoryname.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.categoryname} 
                        </option>
                      ))}
                    </select>
                    {validationErrors.productCategoryId && (
                <div className="invalid-feedback">{validationErrors.productCategoryId}</div>
              )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="Categorytype">Category Type</label>
                    <input
                      className="form-control"
                      id="Categorytype"
                      type="text"
                      placeholder="Category Type"
                      value={selectedCategoryType} // Use state to show the selected category type
                      readOnly // Make it read-only if needed
                    />
                    
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productname">Product Name</label>
                    <input
                      className={`form-control ${validationErrors.productname ? "is-invalid" : ""}`}

                      id="productname"
                      type="text"
                      placeholder="Product Name"
                      value={newProduct.productname}
                      onChange={(e) => rhandle(e)}
                    />
                     {validationErrors.productname && (
                <div className="invalid-feedback">{validationErrors.productname}</div>
              )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productcode">Product Code</label>
                    <input
                className={`form-control ${validationErrors.productcode ? "is-invalid" : ""}`}
                id="productcode"
                      type="text"
                      placeholder="Product Code"
                      value={newProduct.productcode}
                      onChange={(e) => rhandle(e)}
                    />
                     {validationErrors.productcode && (
                <div className="invalid-feedback">{validationErrors.productcode}</div>
              )}
                  </div>
                </div>
                {/* {categoryTypeId !== 2 && (

                <div className="row mb-2">
                  <div className="form-floating-sm">
                  <label htmlFor="consumerTypeId">Customer Type</label>
                    <select
                className={`form-control ${validationErrors.consumerTypeId ? "is-invalid" : ""}`}
                id="consumerTypeId"
                      value={newProduct.consumerTypeId}
                      // disabled={selectedCategoryType === 'Sales'}
                      onChange={(e) => rhandle(e)}
                      
                      
                    >
                      <option value="">Select Customer Type</option>
                      {customerType.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.consumerType}
                        </option>
                      ))}
                    </select>
                    {validationErrors.consumerTypeId && (
                <div className="invalid-feedback">{validationErrors.consumerTypeId}</div>
              )}
                  </div>
                </div>
                )}
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="hsnCode">HSN Code</label>
                    <input
                className={`form-control ${validationErrors.hsnCode ? "is-invalid" : ""}`}
                id="hsnCode"
                      type="text"
                      placeholder="HSN Code"
                      value={newProduct.hsnCode}
                      onChange={(e) => rhandle(e)}
                    />
                      {validationErrors.hsnCode && (
                <div className="invalid-feedback">{validationErrors.hsnCode}</div>
              )} */}
                  {/* </div>
                </div> */}
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="gst">GST</label>
                    <input
                className={`form-control ${validationErrors.gst ? "is-invalid" : ""}`}
                id="gst"
                      type="text"
                      placeholder="0"
                      value={newProduct.gst}
                      onChange={(e) => rhandle(e)}
                    />
                     {validationErrors.gst && (
                <div className="invalid-feedback">{validationErrors.gst}</div>
              )}
                  </div>
                </div>
                {categoryTypeId !== 2 && (

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="netWeight">Net Weight</label>
                    <input
                className={`form-control ${validationErrors.netWeight ? "is-invalid" : ""}`}
                id="netWeight"
                      type="text"
                      placeholder="0"
                      value={newProduct.netWeight}
                      onChange={(e) => rhandle(e)}
                    />
                      {validationErrors.netWeight && (
                <div className="invalid-feedback">{validationErrors.netWeight}</div>
              )}
                  </div>
                
                </div>
                )}

                <div className="row">
                  
                  <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="price">Sales Price</label>
                    <input
    className={`form-control ${validationErrors.pprice ? 'is-invalid' : ''}`}
    id="pprice.price"
                      type="number"
                      placeholder="0"
                      value={newProduct.pprice.price}
                      onChange={(e) => rhandle(e)}
                      />
                        {validationErrors.pprice && <div className="invalid-feedback">{validationErrors.pprice}</div>}

                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productPoints.sales">Sales Points</label>
                    <input
        className={`form-control ${validationErrors.salesPoints ? "is-invalid" : ""}`}
        id="productPoints.sales"
                      type="text"
                      placeholder="0"
                      value={newProduct.productPoints[0].points}
                      onChange={(e) => rhandle(e)}

                     
                    />
                   {validationErrors.salesPoints && (
        <div className="invalid-feedback">{validationErrors.salesPoints}</div>
      )}  
                  </div>
                </div>
              
                </div>
              {/* )} */}
                  {categoryTypeId !== 2 && (

                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="additionalprice">Additional Price</label>
                    <input
        className={`form-control ${validationErrors.additionalPrice ? "is-invalid" : ""}`}
        id="addPrice.additionalPrice"
                      type="number"
                      placeholder="0"
                      value={newProduct.addPrice.additionalPrice}
                      onChange={(e) => rhandle(e)}
                      />
                       {validationErrors.additionalPrice && (
        <div className="invalid-feedback">{validationErrors.additionalPrice}</div>
      )}  
                  </div>
                  </div>
                  <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productPoints.additional">Additional Points</label>
                    <input
        className={`form-control ${validationErrors.additionalPoints ? "is-invalid" : ""}`}
        id="productPoints.additional"
                      type="text"
                      placeholder="0"
                      value={newProduct.productPoints[1].points}
                      onChange={(e) => rhandle(e)}

                    />
                     {validationErrors.additionalPoints && (
        <div className="invalid-feedback">{validationErrors.additionalPoints}</div>
      )}
                  </div>
                  </div>


                 
                </div>
              )}
{categoryTypeId !== 2 && (

                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="newconnection">New Connection Price</label>
                    <input
        className={`form-control ${validationErrors.newConPrice ? "is-invalid" : ""}`}
        id="newConPrice.newConPrice"
                      type="number"
                      placeholder="0"
                      value={newProduct.newConPrice.newConPrice}
                      onChange={(e) => rhandle(e)}
                         
                    />
                     {validationErrors.newConPrice && (
        <div className="invalid-feedback">{validationErrors.newConPrice}</div>
      )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productPoints.new connection">New Connection Points</label>
                    <input
        className={`form-control ${validationErrors.newConPoints ? "is-invalid" : ""}`}
        id="productPoints.new connection"
                      type="text"
                      placeholder="0"
                      value={newProduct.productPoints[2].points}
                      onChange={(e) => rhandle(e)}
                
                    />
                     {validationErrors.newConPoints && (
        <div className="invalid-feedback">{validationErrors.newConPoints}</div>
      )}
                  </div>
                </div>
               
                </div>
                )}
                

                <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="image">Upload Image</label>

    <input
      className="form-control"
      id="image"
      type="file" 
      onChange={handleImageChange}
      accept="image/jpeg, image/png, application/pdf" 
      />
  </div>
</div>
               
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddVendorProduct;
