import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";

const RemovequantityModalPopup = ({ isOpen, onRequestClose,productname, quantity,productcode, searchDate}) => {

  const [uproduct, setUproduct] = useState({
    productcode:productcode,
    productname: productname,
    quantity:quantity ,
    removequantity:"",
    description:"",
    date:searchDate,

    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [loading, setLoading] = useState(false);
    const [showPasswordPopup, setShowPasswordPopup] = useState(false);
    
    const [currentPassword, setCurrentPassword] = useState("");
    const [validationError, setValidationError] = useState("");
  const url = "/api/removequantity/create";
  const navigate = useNavigate();
   const validateCurrentPassword = async () => {
        setLoading(true);
        const localData = JSON.parse(localStorage.getItem("data"));
        const token = localData?.token;
        const orgCode = localData?.orgcode;
        try {
          const response = await axios.post(
            "/api/accounts/validate-admin-password",
            { adminPassword: currentPassword },
            { headers: { Authorization: `Bearer ${token}` } }
          );
    
          if (response.data === "Password is correct") {
            setValidationError("");
            setShowPasswordPopup(false); // Close password popup
            proceedWithUpdate();
    
          } else {
            setValidationError("Incorrect password");
          }
        } catch (error) {
          console.error("Password validation failed", error);
          setValidationError("Incorrect password");
        } finally {
          setLoading(false);
        }
      };
    const rsubmit = (e) => {
      e.preventDefault();

      setShowPasswordPopup(true);
  
      }
  function proceedWithUpdate() {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        uproduct,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success(" Quantity Removed successfully");
    onRequestClose()   ;
       
      })

      .catch((error) => {
        
          setLoading(false);
      handleError(error);
      });
    } 
  const rhandle = (e) => {
    const { id, value } = e.target; 
  
    setUproduct((prevUproduct) => ({
      ...prevUproduct,
      [id]: value, 
    }));
  };    

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleClosePopup = () => {
    setShowPasswordPopup(false); 
    navigate("/users/Stockadjustment"); 
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Remove Quantity </Modal.Title>
    </Modal.Header>
    <Modal.Body>

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="productname">Product Name</label>
                        <input
                          className="form-control"
                          id="productname"
                          type="text"
                          placeholder="productname"
                          value={uproduct.productname}
                          onChange={(e) => rhandle(e)}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="quantity">Quantity</label>
                        <input
                          className="form-control"
                          id="quantity"
                          type="text"
                          placeholder="quantity"
                          value={uproduct.quantity}
                          onChange={(e) => rhandle(e)}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="removequantity">Remove Quantity</label>
                        <input
                          className="form-control"
                          id="removequantity"
                          type="text"
                          placeholder="removequantity"
                          value={uproduct.removequantity}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="description">Description</label>
                        <input
                          className="form-control"
                          id="description"
                          type="text"
                          placeholder="description"
                          value={uproduct.description}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                  
                    
                   
                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;

          <Button variant="secondary" onClick={onRequestClose}>
            Close
         </Button>
                  </form>
                  {showPasswordPopup && (
        <div className="modal-overlay">
          <div className="password-popup">
            <h6>Enter Admin Password</h6>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Enter password"
            />
            {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
            <button onClick={validateCurrentPassword} disabled={loading}>
              {loading ? 'Validating...' : 'submit'}
            </button>
            <button onClick={handleClosePopup}>Close</button> {/* Add Close button */}
          </div>
        </div>
      )}
                </div>
              </div>
             
            </div>


          </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default RemovequantityModalPopup;
