import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { useParams } from "react-router-dom";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import PriceHistoryModal from "./PriceHistoryModal";
import $ from 'jquery';
import ProductdetailsModal from "./updateproducts";
import ProducteditModalPopup from "./productedit";
import { FiInfo } from "react-icons/fi";
import AdditionalPriceHistoryModal from "./additionalPriceHistoryModal";
import NewConPriceHistoryModal from "./newConPriceHistoryModal";
import ProductPriceUpdate from "./productPriceUpdate";
import Modalpopup from "./Modalpopup";
import ProductPointsHistory from "./productPointsHistoryModal";
import { FaMedal, FaRupeeSign } from "react-icons/fa";
function Productdetails() {
  const { categoryId } = useParams();

  useEffect(() => {
    // You can use categoryId here to fetch or display data based on it
    console.log("Category ID:", categoryId);
    // Fetch product details or perform other actions
  }, [categoryId]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [priceHistory, setPriceHistory] = useState([]); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [isProductPriceUpdateModal, setIsProductPriceUpdateModal] = useState(false);

  const [isPointsHistoryModalOpen, setIsPointsHistoryModalOpen] = useState(false);
  const [isPriceHistoryModalOpen, setIsPriceHistoryModalOpen] = useState(false);
  const [isAdditionalPriceHistoryModalOpen, setIsAdditionalPriceHistoryModalOpen] = useState(false);
  const [isNewConPriceHistoryModalOpen, setIsNewConPriceHistoryModalOpen] = useState(false);
  const [additionalPriceHistory, setAdditionalPriceHistory] = useState([]); 
  const [newConPriceHistory, setNewConPriceHistory] = useState([]); 
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [superProducts, setSuperProducts] = useState([]);
  const [productTypeFilter, setProductTypeFilter] = useState("All"); // New state for product type filter

  const navigate = useNavigate();
 

  

  
    const getData = async () => {
      setLoader(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;

        const productResponse = await axios.get(`/api/product/orgcode=${orgCode}/productCategoryId=${categoryId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProduct([...productResponse.data]);

        const superProductsResponse = await axios.get(`/api/super-products`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSuperProducts(superProductsResponse.data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        handleError(error);
      }
    };

    useEffect(() => {
      if (categoryId) { 
        getData();
      }
    }, [categoryId]);

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };
  useEffect(() => {
    getData();
    
  }, [isCreateModalOpen]);

  const filteredProducts = product.filter(p => 
    productTypeFilter === "All" || p.productType === productTypeFilter
  );
 
  const onClickhandler = async (e, id) => {
    setLoading(id);
    const checked = e.target.checked;
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      await axios.get(`/api/product/productUpdate/id=${id}/value=${checked}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(null);
      toast.success("Product status updated");
      getData();

    } catch (error) {
      handleError(error);
    }
  };

  const handleProductEdit = async (productId) => {
    setSelectedProductId(productId);
      setIsProductEditModalOpen(true);
  };
 
  const closeProductEditModal = () => {
    setIsProductEditModalOpen(false);
    getData();
 
  };

  const handleProductPriceUpdate = async (productId) => {
    setSelectedProductId(productId);
   setIsProductPriceUpdateModal(true);
  };
 
  const closeProductPriceUpdateModal = () => {
    setIsProductPriceUpdateModal(false);
    getData();
 
  };
 
  const openPriceHistoryModal = async (productId) => {
    setSelectedProductId(productId);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/ProductPrice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsPriceHistoryModalOpen(true);
  };
 
  const closePriceHistoryModal = () => {
    setSelectedProductId(null);
    setIsPriceHistoryModalOpen(false);
  };

  const openAdditionalPriceHistoryModal = async (productId) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/additionalprice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdditionalPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsAdditionalPriceHistoryModalOpen(true);
  };
 
  const closeAdditionalPriceHistoryModal = () => {
    setIsAdditionalPriceHistoryModalOpen(false);
  };

  const openNewConPriceHistoryModal = async (productId) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/newconnectionprice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNewConPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsNewConPriceHistoryModalOpen(true);
  };
 
  const closeNewConPriceHistoryModal = () => {
    setIsNewConPriceHistoryModalOpen(false);
  };

const [salesPoints, setSalesPoints] = useState([]);
const [additionalPoints, setAdditionalPoints] = useState([]);
const [newConPoints, setNewConPoints] = useState([]);
const [pointsType, setPointsType] = useState(null); // Track which points type is being displayed

const openPointsHistoryModal = async (productId, pointsType) => {
  setSelectedProductId(productId);
  setPointsType(pointsType); // Set the points type
  try {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const response = await axios.get(`/api/product-points/admin-productid=${productId}/pointstype=${pointsType}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    
    // Update state based on pointsType
    if (pointsType === 'sales') {
      setSalesPoints(response.data);
    } else if (pointsType === 'additional') {
      setAdditionalPoints(response.data);
    } else if (pointsType === 'new connection') {
      setNewConPoints(response.data);
    }
  } catch (error) {
    handleError(error);
  }
  setIsPointsHistoryModalOpen(true);
};

const closePointsHistoryModal = () => {
  setIsPointsHistoryModalOpen(false);
};

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
    setProduct([]);

  };

  // Function to handle closing create modal
  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    setProduct([]);
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      {loader && <div className="loading"></div>} 
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
          <div className="card-title form-group d-flex align-items-center justify-content-between">
    {/* Display the category name in the title */}
   Product Details       
              {/* <button className="btn btn-primary" onClick={handleCreateModalOpen}>
                ADD
              </button> */}
              {/* <button className="btn btn-primary"onClick={handleAddProduct}>
               ADD
            </button> */}
            </div>

            <div>    
    <h6>Category Type : {product.length > 0 ? product[0]?.productCategory?.categoryType?.categorytype : ""}</h6>  
    <h6>Category Name : {product.length > 0 ? product[0]?.productCategory?.categoryname : ""}</h6>  
    </div> 
  
            <br />
            <div className="table-responsive">

         

            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  {/* <th>Category Type</th>
                  <th>Category Name</th> */}
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>HSN Code</th>
                  <th>GST</th>
                  {product[0]?.productCategory?.productCategoryTypeId!== 2 && (
        <th>Net Weight</th>
      )}            
            <th>Sales</th> 
            {product[0]?.productCategory?.productCategoryTypeId!== 2 && (
        <th>Additional</th>
      )}          
 {product[0]?.productCategory?.productCategoryTypeId!== 2 && (
        <th>New Connection</th>
      )}      
                         
               <th>Edit</th>
                  <th>Status</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>
              {product.length === 0 ? (
                    <tr>
                      <td colSpan="15" className="text-center">
                        No Data Available
                      </td>
                    </tr>
          ) : (

            product.map((product) => {      
              const isTracked = superProducts.some(
                (superProduct) => superProduct.productName === product.productname
              );
              const trackingClass = isTracked ? "pill-green" : "pill-yellow";
              const salesPoints = product.productPoints.filter(point => point.pointsType === "sales").map(point => point.points).join(", ");
              const additionalPoints = product.productPoints.filter(point => point.pointsType === "additional").map(point => point.points).join(", ");
              const newConPoints = product.productPoints.filter(point => point.pointsType === "new connection").map(point => point.points).join(", ");
              const isSalesCategory = product.productCategory?.productCategoryTypeId === 2;
              return (
                <tr key={product.productid}>
                   {/* <td>{product.productCategory?.categoryType?.categorytype}</td>
                  <td>{product.productCategory?.categoryname}</td> */}
                  {/* <td>{product.productCategory.categoryType.categorytype}</td> */}

                  <td>
                    <div style={{ textAlign: "center" }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: "70px", height: "70px", marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {product.image && product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "50px", height: "50px", borderRadius: "50%" }}></div>
                          )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                          <span style={{ marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                            {product.productname}
                          </span>
                          <span
                            style={{
                              display: "inline-block",
                              padding: "0.2em 0.4em",
                              borderRadius: "0.4em",
                              fontSize: "0.8em",
                              color: "white",
                              backgroundColor: isTracked ? "green" : "black",
                              minWidth: '60px',
                              textAlign: 'center',
                            }}
                          >
                            {isTracked ? "Tracked" : "Untracked"}
                          </span>
                          {/* <span
          style={{
            display: "inline-block",
            marginTop: '5px',
            padding: "0.8em 0.4em",
            borderRadius: "0.4em",
            fontSize: "0.8em",
            color: "white",
            backgroundColor: "green", 
            minWidth: '60px',
            textAlign: 'center',
          }}
        >
          {product?.vendordata?.name}
        </span> */}
                        </div>
                      </div>
                    </div>
                  </td>
                    <td>{product.productcode}</td>
                      <td>{product.hsnCode}</td>
                      <td>{product?.gst} %</td>
                      {!isSalesCategory && (
              <td>{product.netWeight} Kg</td>
            )}      
                             <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {product.pprice?.price && (
                                <>
                                 <FaRupeeSign/> {product.pprice.price}
                                  <span>
                                    <FiInfo onClick={() => openPriceHistoryModal(product.productid)} style={{ cursor: 'pointer' }} title="View Price History"/>
                                  </span>
                                </>
                              )}
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }} className="mt-3">
                          {salesPoints && (
                            <>
                           <FaMedal/>{salesPoints}
                            <span>
                              <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'sales')} style={{cursor: 'pointer' }} title="View Points History" />
                            </span>
                            </>
                          )}
                          </div>
                        </td>
                        {!isSalesCategory && (  
                          <>    

<td>
<div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
{product.addPrice?.additionalPrice && (
      <>
<FaRupeeSign/>{product.addPrice.additionalPrice}
  <span>
    <FiInfo onClick={() => openAdditionalPriceHistoryModal(product.productid)} style={{cursor: 'pointer' }} title="View Price History"/>
  </span>
  </>
)}
</div>
<div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }} className="mt-3">
{additionalPoints && (
  <>
 <FaMedal/>{additionalPoints}
  <span>
    <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'additional')} style={{cursor: 'pointer' }} title="View Points History"/>
  </span>
  </>
)}
</div>
</td>
<td>
<div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
{product.newConPrice?.newConPrice && (
  <>
   <FaRupeeSign/> {product.newConPrice.newConPrice}
  <span>
    <FiInfo onClick={() => openNewConPriceHistoryModal(product.productid)} style={{cursor: 'pointer' }} title="View Price History"/>
  </span>
  </>
)}
</div>

<div style={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }} className="mt-3">
{newConPoints && (
  <>
  <FaMedal/>{newConPoints}
  <span>
    <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'new connection')} style={{cursor: 'pointer' }}  title="View Points History"/>
  </span>
  </>
)}
</div>
</td>
</>
                        )}
                      <td> 
                     <button
                      onClick={() => handleProductEdit(product.productid)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td>
                      <td>
                        <div className="form-check form-switch">
                          {loading == product.productid && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={product.productstatus}
                            onClick={(e) => onClickhandler(e, product.productid)}
                            value={product.productid}
                          />
                        </div>
                      </td>
                      <td>
      <button className="btn btn-primary btn-sm" onClick={() => handleProductPriceUpdate(product.productid)}>
        Update Price/Points
      </button>

              </td>
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
          
            </div>


            <div>
            {isPriceHistoryModalOpen && (
              <PriceHistoryModal
                onClose={closePriceHistoryModal}
                initialPriceHistory={priceHistory}
                productId={selectedProductId}
              />
            )}
              {isAdditionalPriceHistoryModalOpen && (
              <AdditionalPriceHistoryModal
                onClose={closeAdditionalPriceHistoryModal}
                additionalPriceHistory={additionalPriceHistory}
              />
            )}
              {isNewConPriceHistoryModalOpen && (
              <NewConPriceHistoryModal
                onClose={closeNewConPriceHistoryModal}
                newConPriceHistory={newConPriceHistory}
              />
            )}
            {isProductEditModalOpen && (
              <ProducteditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeProductEditModal}
                productId={selectedProductId}
              />
            )}

           {isProductPriceUpdateModal && (
              <ProductPriceUpdate
                isOpen={isProductPriceUpdateModal}
                onRequestClose={closeProductPriceUpdateModal}
                productId={selectedProductId}
              />
            )}
            {isCreateModalOpen && (
           <Modalpopup isOpen={isCreateModalOpen}  categoryId={categoryId} onRequestClose={handleCreateModalClose} />
            )}

           {isPointsHistoryModalOpen && (
      <ProductPointsHistory
        onClose={closePointsHistoryModal}
        salesPoints={pointsType === 'sales' ? salesPoints : []}
        additionalPoints={pointsType === 'additional' ? additionalPoints : []}
        newConPoints={pointsType === 'new connection' ? newConPoints : []}
      />
    )}

      </div>


          </div>
        </div>{" "}
      </div>
    </div>   
  );
}

export default Productdetails;
