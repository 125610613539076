import React, { useEffect } from "react";
import { IconButton, Tooltip, Button } from "@mui/material";


const PrintInvoice = ({ invoiceDetails, consumerdata, productData, serviceData, orgprofile, calculateSubtotal,
    calculateSubtotalAfterDiscount, finaltax, resultValue }) => {




    return (
        <div>
            <h4 style={{ marginLeft: '50px' }}>Invoice</h4>

            <div className="container" style={{ padding: '20px' }}>
                <div className="container" style={{ padding: '20px' }}>
                    <div className="row align-items-center">
                        {/* Organization Details Section */}
                        <div className="col-md-8">
                            <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{orgprofile?.name}</p>
                            <p style={{ marginBottom: '5px' }}>{`${orgprofile?.addressLine1}, ${orgprofile?.addressLine2}`}</p>
                            <p style={{ marginBottom: '5px' }}>{orgprofile?.pincode}</p>
                            <p style={{ marginBottom: '5px' }}>{orgprofile?.contact}</p>
                            <p style={{ marginBottom: '0' }}>GSTIN : {orgprofile?.gstin}</p>
                        </div>

                        {/* Logo Section */}
                        <div className="col-md-4 text-end">
                            <img
                                className="img-fluid mb-1"
                                id="image"
                                src={`${orgprofile?.imageMeta},${orgprofile?.logo}`}
                                alt="Organization Logo"
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "5px",
                                    objectFit: "contain",
                                    border: "1px solid #ccc",
                                    padding: "5px",
                                    backgroundColor: "#f9f9f9",
                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>

            {/* Consumer Details Section */}
            <div style={{ padding: '20px' }}> {/* Reduced padding */}
                <div className="row">
                    <h5>To</h5>
                    {consumerdata ? (
                        <div className="col-md-6">
                            <p style={{ marginBottom: '5px' }}>{consumerdata.consumername}</p>
                            <p style={{ marginBottom: '5px' }}>{`${consumerdata.address1}, ${consumerdata.address2}`}</p>
                            <p style={{ marginBottom: '5px' }}>{consumerdata.address3}</p>
                            <p style={{ marginBottom: '5px' }}>{consumerdata.phone}</p>
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}


                    {/* Quotation Details Section */}
                    <div style={{ marginBottom: "20px", textAlign: "right" }}>
                        <p style={{ marginBottom: '0' }}>#No : {invoiceDetails?.invoiceid}</p>
                        <div>  <span style={{ fontWeight: 'bold' }}>Invoice Date </span>:  {invoiceDetails?.quotesdate || ''} </div>
                        <div>  <span style={{ fontWeight: 'bold' }}>  Due Date </span>: {invoiceDetails?.duedate || ''}</div>
                        <div>  <span style={{ fontWeight: 'bold' }}>Ship By (expected) </span>: {invoiceDetails?.shibydate || ''}</div>
                    </div>

                </div>
            </div>


            <div style={{ padding: '20px' }}>

                <table  >
                    <thead>
                        <tr style={{ fontSize: "17px", color: "black" }}>

                            <th style={{ width: "10%" }}>Product</th>
                            <th style={{ width: "10%" }}>Product Code</th>
                            <th style={{ width: "10%" }}>Description</th>
                            <th style={{ width: "2%" }}>Quantity</th>
                            <th style={{ width: "4%" }}>Unit</th>
                            <th style={{ width: "5%" }}>Unit Price</th>
                            <th style={{ width: "5%" }}>Before tax</th>
                            <th style={{ width: "10%" }}>Tax</th>
                            <th style={{ width: "8%" }}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productData.map((product, index) => (
                            <tr key={index}>
                                <td>{product.product}</td>
                                <td>{product.productcode}</td>
                                <td>{product.description}</td>
                                <td>{product.quantity}</td>
                                <td>{product.uom}</td>
                                <td>{(product.price).toFixed(2)}</td>
                                <td>{(product.beforetax).toFixed(2)}</td>
                                <td>
                                    <div>
                                        Tax: {product.tax}% , ₹{(product.tax * product.beforetax) / 100}
                                    </div>
                                    <div>
                                        CGST: {product.tax / 2}% , ₹{(product.tax * product.beforetax) / 200}
                                    </div>
                                    <div>
                                        SGST: {product.tax / 2}% , ₹{(product.tax * product.beforetax) / 200}
                                    </div>
                                </td>
                                <td>{(product.amount).toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>

            <div style={{ padding: '20px' }}>

                <table  >
                    <thead>
                        <tr style={{ fontSize: "17px", color: "black" }}>

                            <th>Service Name</th>
                            <th>Service Code</th>
                            <th>Points</th>
                            <th>Service Cost</th>
                            <th>GST</th>
                            <th>Service Cost</th>

                        </tr>

                    </thead>
                    <tbody>
                        {serviceData.map((service, index) => (
                            <tr key={index}>
                                <td>{service.servicename}</td>
                                <td>{service.servicecode}</td>
                                <td>{service.points}</td>
                                <td>{service.servicecost}</td>
                                <td>
                                    <div>
                                        tax:{service.gst}%  , ₹ {service.gst * service.servicecost / 100}
                                    </div>

                                    <div>
                                        CGST:{service.gst / 2}% ,₹ {(service.gst * service.servicecost / 100) / 2}
                                    </div>
                                    <div>
                                        SGST:{service.gst / 2} % ,₹ {(service.gst * service.servicecost / 100) / 2}
                                    </div>
                                </td>
                                <td>{service.totalamount}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>



            <div>
                <textarea
                    className={`form-control`}
                    value={invoiceDetails.memo || ''}
                    readOnly
                />

            </div>

            {/* <div style={{ padding: '20px' }}>
                        <h5>Attached Files</h5>
                        {files.length > 0 ? (
                            <ul>
                                {files.map((file, index) => (
                                    <li key={index}>
                                        <a
                                            // href="#/users/ViewQuotation"
                                            onClick={() => handleFileClick(file.filedata)} // Handle file click
                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        >
                                            View File {index + 1}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No attached files</p>
                        )}
                    </div> */}






            <div className="col-12 col-md-6 offset-md-7">



                <div className="row">
                    <div className="col-md-7 d-flex justify-content-end align-items-center" >
                        <label>Subtotal :  ₹ {calculateSubtotal()}</label>

                    </div>

                    <div className="col-md-7 d-flex justify-content-end align-items-center">
                        <label> Discount:₹ {invoiceDetails.discount}</label>
                    </div>

                    <div className="col-md-7 d-flex justify-content-end align-items-center">
                        <label> Before Tax:  ₹  {calculateSubtotalAfterDiscount()}</label>
                    </div>


                    <div className="col-md-7 d-flex justify-content-end align-items-center" >
                        <label> Tax</label>:<label>  ₹ {finaltax.totalTax} </label>

                    </div>
                    <div className="col-12 mt-2">
                        <div style={{ backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
                            <h6>Tax Breakdown:</h6>
                            <div>CGST: ₹  {finaltax.dividedTax.toFixed(2)}</div>
                            <div>SGST: ₹  {finaltax.dividedTax.toFixed(2)}</div>
                        </div>
                    </div>



                    <div className="col-md-7 d-flex justify-content-end align-items-center">TCS: ₹ 0.00</div>


                    <div className="col-md-7 d-flex justify-content-end align-items-center">Rounding Off:
                        ₹ {resultValue.roundOff}
                    </div>

                    <div className="col-md-7 d-flex justify-content-end align-items-center fw-bold">Total: {resultValue.roundedTotal.toFixed(2)}</div>

                </div>
            </div>

        </div>
    );
}

export default PrintInvoice;
