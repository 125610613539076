import React, { useState, useEffect } from "react";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import CustomFieldDisplay from "./CustomFieldDisplay";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IconButton, Tooltip, Dialog, DialogContent, TextField, Modal, InputAdornment, Button, MenuItem, Select, Grid } from "@mui/material";
import Addcustomfields from "./Addcustomfields";
import { Clear, ClearAll, Search } from '@mui/icons-material';
import AdminDashboard from "./admindashboard";
import { AiOutlineDelete } from 'react-icons/ai';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { toast } from "react-toastify";
import Pagination from '@mui/material/Pagination';
import { BiEditAlt } from "react-icons/bi";






function AddQuotation() {
    const [customerData, setCustomerData] = useState(null);
    const [filteredCustomers, setFilteredCustomers] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [barcodeModalOpen, setBarcodeModalOpen] = useState(false);
    const [barcode, setBarcode] = useState('');
    const [selectedContactData, setSelectedContactData] = useState(null);
    const [openContactModal, setOpenContactModal] = useState(false);
    const [customFields, setCustomFields] = useState([]);
    const [openNewContactModal, setOpenNewContactModal] = useState(false);
    const [productList, setProductList] = useState([]);
    const [productData, setProductData] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().split("T")[0]);
    const [quoteDate, setQuoteDate] = useState(currentDate);
    const [dueDate, setDueDate] = useState("");
    const [shipByDate, setShipByDate] = useState("");
    const [showTaxDetails, setShowTaxDetails] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [openCustomFieldModal, setOpenCustomFieldModal] = useState(false);
    const userid = JSON.parse(localStorage.getItem("data"))?.userid;
    const [newContactData, setNewContactData] = useState({
        product: "",
        consumername: "",
        consumernumber: "",
        phone: "",
        longitude: "",
        latitude: "",
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        address1: "",
        address2: "",
        address3: ""
    });
    const [cunsumerdetailespage, setCunsumerdetailespage] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [memoValue, setMemoValue] = useState("");
    const [files, setFiles] = useState([]);
    const [orgprofile, setOrgprofile] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchType, setSearchType] = useState("consumername");


    const orgcodes = JSON.parse(localStorage.getItem("data")).orgcode;








    const fetchCustomerData = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = JSON.parse(localStorage.getItem("data")).token;
            const response = await axios.get(`/api/consumerdetails/orgcodes=${orgcodes}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCustomerData(response.data);

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchOrganization = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = JSON.parse(localStorage.getItem("data")).token;
            const response = await axios.get(`/api/organizationprofiles/Orgcode=${orgcodes}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setOrgprofile(response.data);
            console.log(response.data);

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = (customFields) => {
        console.log("Custom fields saved:", customFields);
        setOpenCustomFieldModal(false);
    };

    const handleSaveCustomFields = (fields) => {
        setCustomFields((prevFields) => [...(prevFields || []), fields]);
        setOpenCustomFieldModal(false);
        console.log("Custom fields saved:", fields);
    };

    const handleFieldValueChange = (index, fieldKey, value) => {
        const updatedFields = [...customFields];
        if (updatedFields[index]) {
            updatedFields[index][fieldKey] = value;
            setCustomFields(updatedFields);
        }
    };




    const handleBackClick = () => {
        navigate(-1);
    };

    const handleClose = () => {
        navigate(-1);
    };

    const fetchAllProducts = async () => {
        setLoading(true);
        try {
            const token = JSON.parse(localStorage.getItem("data")).token;
            const response = await axios.get(`/api/product/orgcode=${orgcodes}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setProductList(response.data);
            console.log(response.data);
            setLoading(false);

        } catch (err) {
            setLoading(false);
            console.error("Failed to fetch products:", err.message);
        }
    };

    const addNewProductRow = () => {
        const newProductRow = {
            name: "",
            code: "",
            description: "",
            quantity: 1,
            uom: "",
            price: 0,
            discount: 0,
            Beforetax: 0,
            tax: 0,
            amount: 0,
            editable: true
        };
        const updatedProductData = [...productData, newProductRow];
        setProductData(updatedProductData);
        localStorage.setItem('productData', JSON.stringify(updatedProductData));
    };


    const handleProductSelect = (index, selectedProductCode) => {
        const selectedProduct = productList.find(product => product.productcode === selectedProductCode);

        if (selectedProduct) {
            const updatedProductData = productData.map((product, idx) => {
                if (idx === index) {
                    const quantity = 1;
                    const price = parseFloat(selectedProduct.pprice.price) || 0;
                    const discount = parseFloat(selectedProduct.discount) || 0;
                    const gstRate = parseFloat(selectedProduct.gst) || 0;

                    const cgstRate = gstRate / 2;
                    const sgstRate = gstRate / 2;

                    const subtotal = price * quantity;

                    const taxableAmount = subtotal - discount;

                    const cgstAmount = (taxableAmount * cgstRate) / 100;
                    const sgstAmount = (taxableAmount * sgstRate) / 100;

                    const totalAmount = taxableAmount + cgstAmount + sgstAmount;

                    return {
                        ...product,
                        name: selectedProduct.productname,
                        code: selectedProduct.productcode,
                        description: selectedProduct.description,
                        quantity: quantity,
                        uom: selectedProduct.uom,
                        price: price,
                        discount: discount,
                        Beforetax: taxableAmount.toFixed(2),
                        tax: {
                            totalTax: gstRate.toFixed(2),
                            cgstRate: cgstRate.toFixed(2),
                            sgstRate: sgstRate.toFixed(2),
                            cgstAmount: cgstAmount.toFixed(2),
                            sgstAmount: sgstAmount.toFixed(2),
                        },
                        amount: totalAmount.toFixed(2),
                        editable: false,
                    };
                }
                return product;
            });

            setProductData(updatedProductData);
            localStorage.setItem('productData', JSON.stringify(updatedProductData));
        }
    };

    const handleInputChangeProduct = (index, field, value) => {
        const updatedProductData = productData.map((product, idx) => {
            if (idx === index) {
                const updatedProduct = { ...product, [field]: value };

                const price = parseFloat(updatedProduct.price) || 0;
                const quantity = parseFloat(updatedProduct.quantity) || 1;
                const discount = parseFloat(product.discount) || 0;
                const cgstRate = parseFloat(product.tax.cgstRate) || 0;
                const sgstRate = parseFloat(product.tax.sgstRate) || 0;


                const subtotal = price * quantity;
                const gstRate = cgstRate + sgstRate;
                const taxableAmount = subtotal - discount;


                const cgstAmount = (taxableAmount * cgstRate) / 100;
                const sgstAmount = (taxableAmount * sgstRate) / 100;

                const totalAmount = taxableAmount + cgstAmount + sgstAmount;

                updatedProduct.Beforetax = taxableAmount.toFixed(2);
                updatedProduct.tax = {
                    totalTax: gstRate.toFixed(2),
                    cgstRate: cgstRate.toFixed(2),
                    sgstRate: sgstRate.toFixed(2),
                    cgstAmount: cgstAmount.toFixed(2),
                    sgstAmount: sgstAmount.toFixed(2),
                };
                updatedProduct.amount = totalAmount.toFixed(2);

                return updatedProduct;
            }
            return product;
        });


        setProductData(updatedProductData);
        localStorage.setItem('productData', JSON.stringify(updatedProductData));
    };



    const clearAllItems = () => {
        setProductData([]);
        localStorage.removeItem('productData');
    };

    const selectedProductCodes = productData
        .filter(product => !product.editable)
        .map(product => product.code);

    const handleRemoveProduct = (index) => {
        const updatedProductData = productData.filter((_, idx) => idx !== index);
        setProductData(updatedProductData);
        localStorage.setItem('productData', JSON.stringify(updatedProductData));
    };


    const handleOpenCustomFieldModal = () => setOpenCustomFieldModal(true);
    const handleCloseCustomFieldModal = () => setOpenCustomFieldModal(false);

    const getData = async (page) => {
        setLoading(true);
        try {
            const token = JSON.parse(localStorage.getItem("data")).token;
            const response = await axios.get(`/api/consumerdetails/paginated`, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    page: currentPage,
                    size: itemsPerPage
                }
            });

            setCunsumerdetailespage(response.data.content);
            setTotalItems(response.data.totalElements);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    };



    const handleOpenContactModal = () => {
        setOpenContactModal(true);
        fetchCustomerData();
    };


    const handleCloseContactModal = () => setOpenContactModal(false);


    const handleOpenNewContactModal = () => setOpenNewContactModal(true);
    const handleCloseNewContactModal = () => setOpenNewContactModal(false);

    const handleSaveContact = async () => {
        setLoading(true);
        try {
            const token = JSON.parse(localStorage.getItem("data")).token;
            const response = await axios.post(`/api/consumerdetails/create`, newContactData, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setSelectedContactData(newContactData);
            handleCloseNewContactModal();
            handleCloseContactModal();
            setLoading(false);
        }

        catch (error) {
            setLoading(false);
            console.error("Error saving contact:", error);
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewContactData((prevData) => ({ ...prevData, [name]: value }));
    };


    const handleSelectContact = (customer) => {
        setSelectedContactData(customer);
        handleCloseContactModal();
    };


    const calculateSubtotal = () => {
        return productData.reduce((sum, product) => {
            const quantity = parseFloat(product.quantity) || 0;
            const price = parseFloat(product.price) || 0;
            return sum + (quantity * price);
        }, 0).toFixed(2);
    };

    const calculateSubtotalAfterDiscount = () => {
        const subtotal = parseFloat(calculateSubtotal());
        const discountAmount = parseFloat(discount);
        const subtotalAfterDiscount = subtotal - discountAmount;
        return subtotalAfterDiscount.toFixed(2);
    };

    const toggleTaxDetails = () => {
        setShowTaxDetails(!showTaxDetails);
    };
    const calculateTotalTaxAmount = () => {
        return productData.reduce((sum, product) => {
            const cgst = parseFloat(product.tax.cgstAmount) || 0;
            const sgst = parseFloat(product.tax.sgstAmount) || 0;
            const totalamount = cgst + sgst;

            return sum + totalamount;
        }, 0).toFixed(2);
    };

    const calculateRoundingOff = () => {
        const totalBeforeRounding = parseFloat(calculateSubtotalAfterDiscount()) + parseFloat(calculateTotalTaxAmount());
        const decimalPart = totalBeforeRounding - Math.floor(totalBeforeRounding);

        let roundOffAmount;
        if (decimalPart > 0.5) {
            roundOffAmount = Math.ceil(totalBeforeRounding) - totalBeforeRounding;
        } else {
            roundOffAmount = Math.floor(totalBeforeRounding) - totalBeforeRounding;
        }

        return {
            roundedTotal: (totalBeforeRounding + roundOffAmount).toFixed(2),
            roundOff: roundOffAmount.toFixed(2)
        };
    };
    const resultValue = calculateRoundingOff();

    // const handlePrint = () => {
    //     window.print();
    // };

    const handleSaveQuotetion = async () => {


        if (!dueDate) {
            toast.warning("Due Date is mandatory!");

            return;
        }
        if (!shipByDate) {
            toast.warning("shipByDate is mandatory!");
            return;
        }
        if (!selectedContactData) {
            toast.warning(" Select Consumer  details!");
            return;
        }

        if (productData > 1) {
            toast.warning(" Select products atleast 1 is mendatory !");
            return;
        }


        setLoading(true);
        try {
            const token = JSON.parse(localStorage.getItem("data")).token;


            const requestBody = {
                consumername: selectedContactData.consumername,
                orgid: orgcodes,
                shibydate: shipByDate,
                quotesdate: quoteDate,
                duedate: dueDate,
                memo: memoValue,
                totalAmount: calculateRoundingOff().roundedTotal,
                discount: discount,
                quotes: productData.map((products) => ({
                    product: products.name,
                    productcode: products.code,
                    description: products.description,
                    quantity: products.quantity,
                    uom: products.uom,
                    price: products.price,
                    beforetax: products.Beforetax,
                    tax: products.tax.totalTax,
                    amount: products.amount
                })),
                files: files.map(file => ({
                    filedata: file.filedata
                })),
            };
            const response = await axios.post(`/api/quotes/create`, requestBody, {
                headers: { Authorization: `Bearer ${token}` },
            });

            console.log(response.data);
            clearAllItems();
            navigate(-1);
            toast.success("Saved data succesfully");
            setLoading(false);


        } catch (error) {
            setLoading(false);
            console.error("Error saving contact:", error);
        }
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
        getData(page);
    };


    const handleFileChange = async (event) => {

        const selectedFiles = Array.from(event.target.files);

        if (files.length + selectedFiles.length > 5) {
            alert("You can only upload a maximum of 5 files.");
            return;
        }

        const updatedFiles = await Promise.all(
            selectedFiles.map(file => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64String = reader.result.split(',')[1];
                        resolve({
                            filedata: base64String,
                            name: file.name,
                            size: file.size
                        });
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            })
        );

        setFiles(prevFiles => [...prevFiles, ...updatedFiles]);
    };


    const toggleBarcodeModal = () => {
        setBarcodeModalOpen(!barcodeModalOpen);
        setError('');
        setBarcode('');
    };

    const handleCheckProduct = async () => {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("data")).token;
        const productCode = barcode;
        try {

            const response = await axios.get(`/api/product/price/productcode=${productCode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });


            if (response.data && response.data.length > 0) {
                const foundProduct = response.data[0];

                const price = foundProduct.productprice.price || 0;
                const quantity = 1;
                const gstRate = parseFloat(foundProduct.gst) || 0;
                const cgstRate = gstRate / 2;
                const sgstRate = gstRate / 2;

                const subtotal = price * quantity;

                const taxableAmount = subtotal - discount;

                const cgstAmount = (taxableAmount * cgstRate) / 100;
                const sgstAmount = (taxableAmount * sgstRate) / 100;

                const totalAmount = taxableAmount + cgstAmount + sgstAmount;

                const newProductRow = {
                    name: foundProduct.productname,
                    code: foundProduct.productcode,
                    description: foundProduct.description,
                    quantity: quantity,
                    uom: foundProduct.uom,
                    price: price,
                    Beforetax: taxableAmount.toFixed(2),
                    tax: {
                        totalTax: gstRate.toFixed(2),
                        cgstRate: cgstRate.toFixed(2),
                        sgstRate: sgstRate.toFixed(2),
                        cgstAmount: cgstAmount.toFixed(2),
                        sgstAmount: sgstAmount.toFixed(2),
                    },
                    amount: totalAmount.toFixed(2),
                    editable: true,
                };

                setProductData((prevProductData) => [...prevProductData, newProductRow]);
                toggleBarcodeModal();

                toast.success("Product found and added to the table");
            } else {
                toast.warn("Product not found");
            }
            setLoading(false);
        }

        catch (error) {
            setLoading(false);
            console.error("Error occurred during product fetch:", error);
            toast.error("Error fetching product");
        }
    };


    useEffect(() => {
        fetchAllProducts();
        getData();
        fetchOrganization();

    }, []);

    const searchQuotations = async () => {
        setLoading(true);
        try {
            const token = JSON.parse(localStorage.getItem("data")).token;
            const params = { orgcode: orgcodes };

            if (searchType === "consumername") params.consumername = searchTerm;
            if (searchType === "consumernumber") params.consumernumber = searchTerm;
            if (searchType === "phone") params.phone = searchTerm;


            const response = await axios.get(`/api/consumerdetails/search`, {
                headers: { Authorization: `Bearer ${token}` },
                params: params,
            });

            const data = Array.isArray(response.data) ? response.data : response.data.data || [];
            setCustomerData(data);
        } catch (error) {
            console.error("Error occurred during product fetch:", error);
        } finally {
            setLoading(false);
        }
    };


    const filteredConsumer = cunsumerdetailespage.filter((item) => {
        if (searchType === "consumername") {
            return item.consumername?.toLowerCase().startsWith(searchTerm.toLowerCase());
        }
        if (searchType === "consumernumber") {
            return item.consumernumber?.toString().toLowerCase().includes(searchTerm.toLowerCase());
        }
        if (searchType === "phone") {
            return item.phone?.toString().toLowerCase().includes(searchTerm.toLowerCase());
        }
        return true; // No filtering if the searchType doesn't match
    });


    return (
        <div> <AdminDashboard />
            <br />
            <br />
            <br />
            <br />
            {loading && <div className="loading"></div>}

            <div className="col-12 mx-xl-auto ps-xl-10">

                <div style={{
                    width: '100%',
                    height: '50px',
                    backgroundColor: 'lightgray',
                    marginBottom: '20px',
                    display: 'flex',
                    alignItems: 'center',

                }}>
                    <div>
                        <h4 style={{ marginRight: 0 }}>New Quotes</h4>
                    </div>



                </div>



                <Tooltip title="Back" arrow>
                    <IconButton
                        onClick={handleBackClick}
                        sx={{
                            padding: '5px',
                            backgroundColor: 'darkgray',
                            color: 'blue',
                            borderRadius: '10px',
                            width: "60px",
                            height: "30px",
                            left: '3%',
                            '&:hover': { backgroundColor: 'darkgray' }
                        }}
                    >
                        <AiOutlineArrowLeft style={{ fontSize: '25px' }} />
                    </IconButton>
                </Tooltip>


                <br />

                <div className="container">
                    <div className="row">



                        {/* Right side - Organization profile data */}
                        <div className="col-7" style={{ marginLeft: 'auto', padding: '30px' }}>
                            <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{orgprofile?.name}</p>
                            <p style={{ marginBottom: '5px' }}>{`${orgprofile?.addressLine1}, ${orgprofile?.addressLine2}`}</p>
                            <p style={{ marginBottom: '5px' }}>{orgprofile?.pincode}</p>
                            <p style={{ marginBottom: '5px' }}>{orgprofile?.contact}</p>
                            <p style={{ marginBottom: '0' }}>GSTIN : {orgprofile?.gstin}</p>

                        </div>
                        <div className="col-5 d-flex flex-column justify-content-center align-items-center">
                            <img
                                className="img-fluid mb-1"
                                id="image"
                                src={`${orgprofile?.imageMeta},${orgprofile?.logo}`}
                                alt="Organization Logo"
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    background: "#ccc",
                                    display: "block",
                                }}
                            />

                        </div>


                        {selectedContactData ? (
                            <div >
                                <h5> To  <IconButton style={{ color: "blue" }} onClick={handleOpenContactModal}>
                                    <BiEditAlt />
                                </IconButton>  </h5>

                                <p style={{ marginBottom: '5px' }}> {selectedContactData.consumername}</p>
                                <p style={{ marginBottom: '5px' }}> {`${selectedContactData.address1}, ${selectedContactData.address2}`}</p>
                                <p style={{ marginBottom: '5px' }}>{selectedContactData.address3}</p>
                                <p style={{ marginBottom: '5px' }}>{selectedContactData.phone}</p>
                            </div>
                        ) : (
                            <div className="col-12" style={{ padding: '20px' }}>

                                <button className="btn btn-primary mb-3" onClick={handleOpenContactModal}>
                                    + Add a Contact
                                </button>
                            </div>
                        )}


                        <Dialog open={openContactModal} onClose={handleCloseContactModal} maxWidth="md" fullWidth>
                            <DialogContent>


                                <div className="mt-3">
                                    <Button onClick={handleOpenNewContactModal} variant="contained" color="primary">
                                        Add New Customer
                                    </Button>
                                </div>

                                <Grid item sx={{ marginRight: '8px' }}>
                                    {/* <FormControl sx={{ minWidth: 100, marginRight: '4px' }}> */}
                                    <Select
                                        value={searchType}
                                        onChange={(e) => setSearchType(e.target.value)}
                                        displayEmpty
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                </InputAdornment>
                                            ),
                                            sx: { height: '30px', fontSize: '15px' },
                                        }}
                                        sx={{ width: '150px', height: '30px' }}
                                    >
                                        <MenuItem value=""> Select Search </MenuItem>
                                        <MenuItem value="consumername">Consumer Name</MenuItem>
                                        <MenuItem value="consumernumber">consumernumber</MenuItem>
                                        <MenuItem value="phone">phone</MenuItem>

                                    </Select>


                                    <TextField
                                        variant="outlined"
                                        placeholder={`Search by ${searchType}`}
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                </InputAdornment>
                                            ),
                                            sx: { height: '30px', fontSize: '15px' },
                                        }}
                                        sx={{ width: '150px', height: '30px' }}
                                    />

                                    <Button
                                        variant="contained"
                                        onClick={searchQuotations}
                                        sx={{ height: '30px', width: '60px', backgroundColor: 'blue', color: 'white' }}
                                    >
                                        search
                                    </Button>
                                </Grid>

                                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Username</th>
                                                <th>Username No</th>
                                                <th>Phone</th>
                                                <th>Address 1</th>
                                                <th>Address 2</th>
                                                <th>Address 3</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(filteredConsumer) && filteredConsumer.length > 0 ? (
                                                filteredConsumer.map(customer => (

                                                    <tr key={customer.id} onClick={() => handleSelectContact(customer)} style={{ cursor: "pointer" }}>
                                                        <td>{customer.id}</td>
                                                        <td>{customer.consumername}</td>
                                                        <td>{customer.consumernumber}</td>
                                                        <td>{customer.phone}</td>
                                                        <td>{customer.address1}</td>
                                                        <td>{customer.address2}</td>
                                                        <td>{customer.address3}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7">No data available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {/* Pagination Component */}
                                    <Pagination
                                        count={Math.ceil(totalItems / itemsPerPage)} // Total number of pages
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        color="primary"
                                        shape="rounded"
                                    />


                                </div>

                            </DialogContent>
                        </Dialog>

                        <Dialog open={openNewContactModal} onClose={handleCloseNewContactModal} maxWidth="md" fullWidth>
                            <DialogContent>
                                <h5>Add New Contact</h5>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label>Product</label>
                                        <select
                                            className="form-control"
                                            name="product"
                                            value={newContactData.product}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Product</option>
                                            {productList.map((product) => (
                                                <option key={product.id} value={product.id}>
                                                    {product.productname}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>Consumer Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Consumer Name"
                                            name="consumername" // This should match the state key
                                            value={newContactData.consumername}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label>Consumer Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Consumer Number"
                                            name="consumernumber"
                                            value={newContactData.consumernumber}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>Phone number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Phone"
                                            name="phone"
                                            value={newContactData.phone}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label>Longitude</label>

                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Longitude"
                                            name="longitude"
                                            value={newContactData.longitude}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>Latitude</label>


                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Latitude"
                                            name="latitude"
                                            value={newContactData.latitude}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label>Area</label>

                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Area"
                                            value={newContactData.area}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>Org Code</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Org Code"
                                            name="orgcode"
                                            value={newContactData.orgcode}
                                            onChange={handleInputChange}
                                            readOnly // Make it read-only if the orgcode should not be editable by the user
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 mb-3">
                                        <label>address1</label>

                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Address 1"
                                            name="address1"
                                            value={newContactData.address1}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label>address2</label>

                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Address 2"
                                            name="address2"
                                            value={newContactData.address2}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label>address3</label>

                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Address 3"
                                            name="address3"
                                            value={newContactData.address3}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <Button onClick={handleSaveContact} variant="contained" color="primary">
                                        Save
                                    </Button>
                                    <Button
                                        onClick={handleCloseNewContactModal}
                                        variant="outlined"
                                        color="secondary"
                                        style={{ marginRight: "10px" }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </DialogContent>
                        </Dialog>



                        {/* Quote Information */}
                        <div className="col-md-2 mb-2">
                            <label>Quote Date</label>
                            <input
                                type="date"
                                className={`form-control ${error ? "is-invalid" : ""}`} // Add 'is-invalid' class for invalid state
                                value={quoteDate}
                                onChange={(e) => {
                                    setQuoteDate(e.target.value)

                                    setError("");
                                }
                                } // Save the selected date
                            />
                        </div>
                        <div className="col-md-2 mb-2">
                            <label>Due Date</label>
                            <input
                                type="date"
                                className={`form-control ${error ? "is-invalid" : ""}`} // Add 'is-invalid' class for invalid state
                                value={dueDate}
                                onChange={(e) => {
                                    setDueDate(e.target.value);
                                    setError(""); // Clear error on change
                                }}
                            />
                        </div>
                        <div className="col-md-2 mb-2">
                            <label>Ship By (expected)</label>
                            <input
                                type="date"
                                className={`form-control ${error ? "is-invalid" : ""}`} // Add 'is-invalid' class for invalid state
                                value={shipByDate}
                                onChange={(e) => {
                                    setShipByDate(e.target.value)
                                    setError("");
                                }}
                            />
                        </div>

                        <CustomFieldDisplay customFields={customFields} handleFieldValueChange={handleFieldValueChange} />


                        {/* Custom Fields Section */}
                        {/* {customFields.length > 0 && customFields.map((field, index) => (
                        <div key={index} className="col-md-4 mb-3">
                            <label>{field.label}</label>
                            <input
                                type={field.type || "text"} // Use text as default type
                                className="form-control"
                                placeholder={field.placeholder || ""}
                            />
                        </div>
                    ))} */}

                        <Dialog open={openCustomFieldModal} onClose={handleCloseCustomFieldModal} maxWidth="md" fullWidth>
                            <DialogContent>
                                <Addcustomfields onSave={handleSaveCustomFields} adminId={userid} autoAddField={true} onClose={handleCloseCustomFieldModal} />
                            </DialogContent>
                        </Dialog>

                        {/* Add Field Button */}
                        <div className="col-12">
                            <button className="btn btn-link mt-3" onClick={() => setOpenCustomFieldModal(true)}>
                                + Add  Custom Fields
                            </button>
                        </div>

                        {/* Add Custom Fields Modal */}
                        <Dialog
                            open={openCustomFieldModal}
                            onClose={handleCloseCustomFieldModal}
                            maxWidth="md"
                            fullWidth
                        >
                            <DialogContent>
                                <Addcustomfields
                                    autoAddField={true}
                                    onSave={handleSaveCustomFields}
                                    onClose={handleCloseCustomFieldModal}
                                />
                            </DialogContent>
                        </Dialog>

                        {/* Product Table */}
                        <div className="col-12 mb-3">
                            <div className="card" style={{ width: "110%" }}>
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr style={{ fontSize: "12px", backgroundColor: "lightgray", color: "black", textAlign: "center" }}>
                                                <th style={{ width: "10%" }}>Product</th>
                                                <th style={{ width: "10%" }}>Product Code</th>
                                                <th style={{ width: "10%" }}>Description</th>
                                                <th style={{ width: "10%" }}>Quantity</th>
                                                <th style={{ width: "10%" }}>UOM</th>
                                                <th style={{ width: "10%" }}> Unit Price</th>
                                                {/* <th style={{ width: "10%" }}>Discount</th> */}
                                                <th style={{ width: "10%" }}>Before tax </th>
                                                <th style={{ width: "15%" }}>Tax</th>
                                                <th style={{ width: "10%" }}>Amount</th>
                                                <th style={{ width: "10%" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productData.map((product, index) => (
                                                <tr key={index}>
                                                    {/* Product Dropdown */}
                                                    <td>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) => handleProductSelect(index, e.target.value)}
                                                            disabled={!product.editable} // Disable dropdown if not editable
                                                        >
                                                            <option value="">Select Product</option>
                                                            {productList
                                                                .filter(productOption => !selectedProductCodes.includes(productOption.productcode) || productOption.productcode === product.code) // Filter out selected products
                                                                .map((productOption) => (
                                                                    <option key={productOption.productcode} value={productOption.productcode}>
                                                                        {productOption.productname}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </td>
                                                    <td>{product.code}</td>
                                                    <td>
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            value={product.description}
                                                            onChange={(e) => handleInputChangeProduct(index, "description", e.target.value)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={product.quantity}
                                                            onChange={(e) => handleInputChangeProduct(index, "quantity", e.target.value)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={product.uom}
                                                            onChange={(e) => handleInputChangeProduct(index, "uom", e.target.value)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={product.price}
                                                            onChange={(e) => handleInputChangeProduct(index, "price", e.target.value)}
                                                            readOnly
                                                        />
                                                    </td>
                                                    {/* <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={product.discount}
                                                        onChange={(e) => handleInputChangeProduct(index, "discount", e.target.value)}
                                                    />
                                                </td> */}

                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={product.Beforetax}
                                                            onChange={(e) => handleInputChangeProduct(index, "Beforetax", e.target.value)}
                                                            readOnly
                                                        />
                                                    </td>
                                                    <td>

                                                        <div>
                                                            Total Tax : {product.tax.totalTax} %
                                                        </div>

                                                        <div>
                                                            CGST: {product.tax.cgstRate}% ({product.tax.cgstAmount})
                                                        </div>
                                                        <div>
                                                            SGST: {product.tax.sgstRate}% ({product.tax.sgstAmount})
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={product.amount}
                                                            onChange={(e) => handleInputChangeProduct(index, "amount", e.target.value)}
                                                            readOnly
                                                        />
                                                    </td>
                                                    <td>
                                                        <IconButton onClick={() => handleRemoveProduct(index)} size="small" color="secondary">
                                                            <AiOutlineDelete />
                                                        </IconButton>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Add Item Button */}
                        <div className="col-12 mb-3">
                            <button className="btn btn-link" onClick={addNewProductRow}>+ Add Item</button>
                            <button className="btn btn-link" onClick={toggleBarcodeModal}>
                                Scan Barcode
                            </button>

                            <Modal open={barcodeModalOpen} onClose={toggleBarcodeModal}>
                                <div style={{ padding: '20px', backgroundColor: 'white', width: '300px', margin: '100px auto', borderRadius: '8px' }}>
                                    <h4>Enter Product Code</h4>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={barcode}
                                        onChange={(e) => setBarcode(e.target.value)}
                                        placeholder="Enter product code"
                                    />
                                    <button className="btn btn-primary mt-3" onClick={handleCheckProduct}>
                                        Check Product
                                    </button>
                                    {error && <p className="text-danger mt-2">{error}</p>}
                                    <button className="btn btn-secondary mt-2" onClick={toggleBarcodeModal}>
                                        Close
                                    </button>
                                </div>
                            </Modal>
                            <button className="btn btn-link" onClick={clearAllItems}>

                                Clear All Items</button>
                        </div>

                        <div className="col-md-6 mb-2">
                            <textarea
                                className="form-control"
                                rows="3"
                                placeholder="Memo (Optional)"
                                value={memoValue}
                                onChange={(e) => {
                                    setMemoValue(e.target.value);

                                }}
                            />
                        </div>

                        {/* <div className="col-12 mb-3">
                            <input
                                type="file"
                                accept="image/*,application/pdf"
                                multiple
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="file-input"
                            />
                            <label htmlFor="file-input" className="btn btn-link">
                                + Attach files
                            </label>
                            <label>(Max 5MB)</label>
                        </div>
                        <div>
                           
                            {files.length > 0 && (
                                <ul>
                                    {files.map((file, index) => (
                                        <li key={index}>
                                            {file.name} ({(file.size / 1024).toFixed(2)} KB) 
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div> */}


                        <div className="col-12 col-md-6 offset-md-7">
                            <div className="row">
                                <div className="col-8 text-end" >
                                    <label>Subtotal :  ₹ {calculateSubtotal()}</label>

                                </div>

                                <div className="col-md-8 d-flex justify-content-end align-items-center" >
                                    <label htmlFor="discount" style={{ marginRight: '10px' }}>Total Discount:</label>
                                    <input
                                        id="discount"
                                        value={discount}
                                        type="text"
                                        onChange={(e) => setDiscount(e.target.value)}
                                        style={{
                                            width: '60px',
                                            backgroundColor: 'transparent',
                                            border: '1px solid #ccc',
                                            padding: '3px',
                                            borderRadius: '8px',
                                            textAlign: 'right'
                                        }}
                                    />
                                </div>

                                <div className="col-8 text-end">
                                    <label> Before Tax:  ₹  {calculateSubtotalAfterDiscount()}</label>
                                </div>
                                <div className="col-6 text-end">
                                    <label> Tax</label>

                                    <Tooltip title="Show tax breakdown">
                                        <IconButton onClick={toggleTaxDetails} style={{ marginLeft: '10px' }}>
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>:
                                </div>
                                <div className="col-6 d-flex align-items-center">
                                    <label>₹ {calculateTotalTaxAmount()}</label></div>

                                {/* Show SGST and CGST breakdown when info icon is clicked */}
                                {showTaxDetails && (
                                    <div className="col-12 mt-2">
                                        <div style={{ backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
                                            <h6>Tax Breakdown:</h6>
                                            <div>CGST: ₹ {productData.reduce((sum, product) => sum + parseFloat(product.tax.cgstAmount || 0), 0).toFixed(2)}</div>
                                            <div>SGST: ₹ {productData.reduce((sum, product) => sum + parseFloat(product.tax.sgstAmount || 0), 0).toFixed(2)}</div>

                                        </div>
                                    </div>
                                )}

                                <div className="col-6 text-end">TCS:</div>
                                <div className="col-6">₹ 0.00</div>

                                <div className="col-8 text-end">Rounding Off:
                                    ₹ {resultValue.roundOff}
                                </div>

                                <div className="col-8 text-end fw-bold">Total: {resultValue.roundedTotal}</div>

                            </div>
                        </div>


                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
                            {/* <Button
                                variant="contained"
                                color="primary"
                                onClick={handlePrint}
                                style={{ marginLeft: '10px', height: '35px' }}
                            >
                                Print
                            </Button> */}


                            <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleSaveQuotetion}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Save
                                </Button>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>

                                <button
                                    className="btn btn-secondary"
                                    onClick={handleClose}
                                    style={{ marginLeft: '10px', height: '35px' }}                                  >
                                    Close
                                </button>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AddQuotation;
