import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import "./Spinner.css";
import PurchaseEdit from "./PurchaseEdit";
import {
  Card, CardContent, Grid, Typography, TextField, Button,
  Table, TableHead, TableBody, TableRow, TableCell,
  IconButton, Tooltip, InputAdornment, Pagination, MenuItem, Select
} from '@mui/material';
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineEye, AiOutlineShareAlt, AiOutlinePlus, AiOutlineDelete } from 'react-icons/ai';
import { MdFilterList } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function CreditNoteList() {
  const [credit, setCredit] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedPurchase, setSelectedCredit] = useState(null);
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchType, setSearchType] = useState("consumername");

  const itemsPerPage = 5;

  const getCurrentFiscalYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const fiscalStartMonth = 3;


    const fiscalYearStart = new Date(today.getFullYear(), fiscalStartMonth, 1);
    const fiscalYearEnd = new Date(today.getFullYear() + 1, fiscalStartMonth - 1, 31);


    return today >= fiscalYearStart ? { startYear: currentYear, endYear: currentYear + 1 }
      : { startYear: currentYear - 1, endYear: currentYear };
  };

  const { startYear, endYear } = getCurrentFiscalYear();


  const defaultStartDate = `${startYear}-04-01`;
  const defaultEndDate = `${endYear}-03-31`;

  const [dateRange, setDateRange] = useState({ startDate: defaultStartDate, endDate: defaultEndDate });

  const getData = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/creditbill`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedData = response.data
        ? response.data.sort((a, b) => b.id - a.id)
        : [];
      setCredit(response.data || []);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [isCreateModalOpen, isEditModalOpen]);

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
  };

  const handleEditModalOpen = (credit) => {
    setSelectedCredit(credit);
    setIsEditModalOpen(true);
  };

  const handleEditPurchase = (credit) => {

    navigate(`/users/CreditNoteEdit/${credit}`);

  };

  const handleViewPurchase = (credit) => {

    navigate(`/users/CreditView/${credit}`);
  };

  const filteredquotation = credit.filter((credit) => {

    if (searchType === "consumername") {
      return credit.consumername?.toLowerCase().startsWith(searchTerm.toLowerCase());
    }
  });
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredquotation.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };



  const searchQuotations = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      const response = await axios.get(`/api/creditbill/orgcode=${orgcode}/consumername=${encodeURIComponent(searchTerm)}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const data = Array.isArray(response.data) ? response.data : response.data.data || [];
      setCredit(data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false);
    }
  };

  const handleDeleteData = async (billId) => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      const response = await axios.delete(`/api/creditbill/delete/creditbillid=${billId}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setCredit(credit.filter((creditdata) => creditdata.id !== billId));
        toast.success("CreditBill  deleted successfully!");
        setLoader(false);
        getData();

        return true;

      }
      else {
        console.error('Failed to delete the row:', response.status);
        toast.error("Failed to delete the credit bill.");
        setLoader(false);
        return false;
      }

    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }


  // useEffect(() => {
  //   if (searchTerm.trim()) {
  //     getDataByConsumerName(searchTerm);
  //   } else {
  //     setCredit([]); // Clear data if the search term is empty
  //   }
  // }, [searchTerm]);


  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">

          <Card>
            <CardContent>
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="h5" component="div">Performa Bill </Typography>
                <Grid container item xs={12} justifyContent="flex-end" alignItems="center"> {/* Align everything to the right */}
                  <Grid item sx={{ marginRight: '8px' }}>
                    <TextField
                      variant="outlined"
                      placeholder="consumername"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                        sx: { height: '30px', fontSize: '15px' },
                      }}
                      sx={{ width: '150px', height: '30px' }}
                    />


                    <Button
                      variant="contained"
                      onClick={searchQuotations}
                      sx={{
                        padding: '5px',
                        backgroundColor: 'blue',
                        color: 'white',
                        borderRadius: '4px',
                        width: '80px',
                        height: '30px',
                        '&:hover': { backgroundColor: 'darkblue' }
                      }}                    >
                      Search
                    </Button>
                  </Grid>

                  <Grid item sx={{ marginRight: '8px' }}>
                    <TextField
                      variant="outlined"
                      type="date"
                      value={dateRange.startDate}
                      onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <BsCalendar style={{ fontSize: '15px' }} /> */}
                          </InputAdornment>
                        ),
                        sx: { height: '30px', fontSize: '15px' },
                      }}
                      sx={{ width: '150px', height: '30px' }}
                    />
                  </Grid>

                  <Grid item sx={{ marginRight: '8px' }}>
                    <TextField
                      variant="outlined"
                      type="date"
                      value={dateRange.endDate}
                      onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })}
                      InputProps={{
                        sx: { height: '30px', fontSize: '15px' },
                      }}
                      sx={{ width: '150px', height: '30px' }}
                    />
                  </Grid>

                  {/* <Grid item container alignItems="center" spacing={1} sx={{ marginLeft: '8px' }}> Move icons closer to fields */}
                  <Tooltip title="Filter">
                    <IconButton sx={{ padding: '5px' }}>
                      <MdFilterList style={{ fontSize: '20px' }} /> {/* Smaller filter icon */}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View">
                    <IconButton sx={{ padding: '5px' }}>
                      <AiOutlineEye style={{ fontSize: '20px' }} /> {/* Smaller eye icon */}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Share">
                    <IconButton sx={{ padding: '5px' }}>
                      <AiOutlineShareAlt style={{ fontSize: '20px' }} /> {/* Smaller share icon */}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={<span style={{ color: 'white' }}>New Performa</span>} arrow>
                    <NavLink to="/users/Creditnote" style={{ textDecoration: 'none' }}>
                      <IconButton
                        sx={{
                          padding: '5px',
                          backgroundColor: 'blue',
                          color: 'white',
                          borderRadius: '4px',
                          width: '120px',
                          height: '40px',
                          '&:hover': { backgroundColor: 'darkblue' }
                        }}
                      >
                        <AiOutlinePlus style={{ fontSize: '15px', marginRight: '5px' }} />
                        <span style={{ fontSize: '12px' }}>New Performa</span>
                      </IconButton>
                    </NavLink>
                  </Tooltip>

                </Grid>

              </Grid>
              {/* </Grid> */}
              <br />

              {/* Product Table */}
              <Table>
                <TableHead>
                  <TableRow>

                    <TableCell>Consumer Name</TableCell>
                    <TableCell>Total  Amount</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredquotation.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">No Data Available</TableCell>
                    </TableRow>
                  ) : (
                    filteredquotation.map((credit) => (
                      <TableRow key={credit.id}>
                        <TableCell>{credit.consumername}</TableCell>
                        <TableCell>{credit.totalamount}</TableCell>
                        <TableCell>{credit.date}</TableCell>

                        <TableCell>
                          <IconButton onClick={() => handleEditPurchase(credit.id)}>
                            <BiEditAlt />
                          </IconButton>
                          <Tooltip title="View">
                            <IconButton sx={{ padding: '5px' }} onClick={() => handleViewPurchase(credit.id)}>
                              <AiOutlineEye style={{ fontSize: '25px' }} />
                            </IconButton>
                          </Tooltip>
                          <IconButton onClick={() => handleDeleteData(credit.id)} size="small" color="secondary">
                            <AiOutlineDelete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </CardContent>
            {/* Pagination */}
            <Pagination
              count={Math.ceil(filteredquotation.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                '& .MuiPaginationItem-root': {
                  color: 'blue',
                },
                '& .Mui-selected': {
                  backgroundColor: 'blue',
                  color: 'white',
                },
              }}
            />


          </Card>
        </div>
      </div>
    </div >
  );
}




export default CreditNoteList;
