import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../images/report.png";

function TransactionIncomingReport() {
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [vehicleFilter, setVehicleFilter] = useState("");
  const [refillProducts, setRefillProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(""); 
  const [isDataAvailable, setIsDataAvailable] = useState(false); 

  const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

  // Fetch vehicles from API
  const fetchVehicles = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;

    try {
      const response = await axios.get(`/api/vehicle/orgid=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const vehiclesData = response.data.map((vehicle) => ({
        id: vehicle.id,
        truckinfo: vehicle.truckinfo,
        licenceNo: vehicle.licenceNo,
      }));

      setVehicles(vehiclesData);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch vehicles");
    }
  };

  // Fetch products from API
  const fetchRefillProducts = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    
    try {
      const response = await axios.get(`/api/product/productstatus=true/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setRefillProducts(response.data); 
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch products");
    }
  };

  // Fetch data from API
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    setLoading(true);
    setIsDataAvailable(false); 

    try {
      const response = await axios.get(
        `/api/incomingtransactions/orgcode=${orgcode}/vehicle=${vehicleFilter}/productcode=${selectedProduct}/${month.getMonth() + 1}/${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Full API Response:", response); // Log entire response
      console.log("Data:", response.data); // Log data

      if (response.data && response.data.data.length > 0) {
        setReportData(response.data); // Set response data
        setIsDataAvailable(true); // Set data available to true
      } else {
        toast.error("No data available");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  // Calculate totals for each column
  const calculateTotals = () => {
    let totalQuantity = 0;
    let totalOnlinePay = 0;
    let totalNewOrg = 0;
    let totalExempted = 0;
    let totalAdditional = 0;
    let totalReturnedProduct = 0;
    let totalTransfer = 0;

    reportData.data.forEach((item) => {
      totalQuantity += item.quantity || 0;
      totalOnlinePay += item.onlinepay || 0;
      totalNewOrg += item.neworg || 0;
      totalExempted += item.exempted || 0;
      totalAdditional += item.additional || 0;
      totalReturnedProduct += item.returnedproduct || 0;
      totalTransfer += item.transfer || 0;
    });

    return {
      totalQuantity,
      totalOnlinePay,
      totalNewOrg,
      totalExempted,
      totalAdditional,
      totalReturnedProduct,
      totalTransfer,
    };
  };

  const generatePDF = () => {
    if (!isDataAvailable) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();
      
      // Add logo to the left side
      const imgData = img;
      const logoWidth = 50;
      const logoHeight = 20;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);
      
      // Add address to the right side
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const textX = doc.internal.pageSize.width - 10;
      const textY = logoY + 10;
      doc.text("Vijay Gas Agencies", textX, textY, { align: "right" });

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      const addressY = textY + 10;
      doc.text(addressText, textX, addressY, { align: "right" });

      // Add report title below the logo
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      const titleX = logoX;
      const titleY = logoY + logoHeight + 15;
      doc.text(" Transaction Incoming Report", titleX, titleY);

      // Add month details below the report title
      doc.setFontSize(12);
      const monthText = `Month: ${
        month ? `${month.getMonth() + 1}/${month.getFullYear()}` : "N/A"
      }`;
      const detailsY = titleY + 10; // Space below the report title
      doc.text(monthText, titleX, detailsY);

      // Prepare table data using the fetched reportData
      const tableData = reportData.data.map((item) => [
        item.date,
        item.quantity,
        item.onlinepay || 0,
        item.neworg || 0,
        item.exempted || 0,
        item.additional || 0,
        item.returnedproduct || 0,
        item.transfer || 0,
      ]);

      // Calculate totals
      const totals = calculateTotals();
      const totalsRow = [
        "Total",
        totals.totalQuantity,
        totals.totalOnlinePay,
        totals.totalNewOrg,
        totals.totalExempted,
        totals.totalAdditional,
        totals.totalReturnedProduct,
        totals.totalTransfer,
      ];

      // Add table to the PDF using autoTable
      doc.autoTable({
        startY: titleY + 20, // Start table after title
        head: [
          ["Date", "Quantity", "Online Pay", "New Org", "Exempted", "Additional", "Returned Product", "Transfer"],
        ],
        body: [...tableData, totalsRow], // Add totals row at the end
        theme: "striped",
        styles: { overflow: "linebreak" },
      });

      // Save the generated PDF
      doc.save("TransactionIncomingReport.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };

  const handleMonthChange = (date) => {
    setMonth(date);
    if (date) {
      setYear(date.getFullYear());
    }
  };

  const handleVehicleChange = (event) => {
    setVehicleFilter(event.target.value);
  };

  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  useEffect(() => {
    fetchVehicles(); 
    fetchRefillProducts(); 
  }, []);

  useEffect(() => {
    if (month && year && vehicleFilter) {
      getData();
    }
  }, [month, year, vehicleFilter, selectedProduct]);

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br /><br/>
      <div className="d-flex justify-content-center align-items-center">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
              </div>
            </div>
            <br />
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                  <div className="d-flex justify-content-between align-items-center" style={{ fontFamily: "Arial, sans-serif" }}>
                    <div>
                      <label htmlFor="month">Month:</label>
                      <DatePicker
                        selected={month}
                        onChange={handleMonthChange}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        className="form-control"
                      />
                    </div>

                    <div className="filter-dropdown" style={{ marginRight: "20px" }}>
                      <label>Vehicle:</label>
                      <select value={vehicleFilter} onChange={handleVehicleChange} className="form-control">
                        <option value="">Select Vehicle</option>
                        {vehicles.map((vehicle) => (
                          <option key={vehicle.id} value={vehicle.truckinfo}>
                            {vehicle.truckinfo || vehicle.licenceNo}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="d-flex align-items-center">
                      <label className="me-2">FILTER BY PRODUCT:</label>
                      <select
                        value={selectedProduct}
                        onChange={handleProductChange}
                        className="form-select"
                        style={{ width: "200px" }}
                      >
                        <option value="">All</option>
                        {refillProducts.map((p) => (
                          <option key={p.id} value={p.productcode}>
                            {p.productname}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <br />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={getData}
                    disabled={loading}
                  >
                    Generate Report
                  </button>
                  <button
                            className="btn btn-success"
                            onClick={generatePDF}
                          >
                            Download PDF
                          </button>
                  <br /><br />
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <div className="table-container">
                      {isDataAvailable && (
                        <div>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Quantity</th>
                                <th>Online Pay</th>
                                <th>New Org</th>
                                <th>Exempted</th>
                                <th>Additional</th>
                                <th>Returned Product</th>
                                <th>Transfer</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportData.data.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.date}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.onlinepay || 0}</td>
                                  <td>{item.neworg || 0}</td>
                                  <td>{item.exempted || 0}</td>
                                  <td>{item.additional || 0}</td>
                                  <td>{item.returnedproduct || 0}</td>
                                  <td>{item.transfer || 0}</td>
                                </tr>
                              ))}
                              <tr>
                                <td><strong>Total</strong></td>
                                <td><strong>{calculateTotals().totalQuantity}</strong></td>
                                <td><strong>{calculateTotals().totalOnlinePay}</strong></td>
                                <td><strong>{calculateTotals().totalNewOrg}</strong></td>
                                <td><strong>{calculateTotals().totalExempted}</strong></td>
                                <td><strong>{calculateTotals().totalAdditional}</strong></td>
                                <td><strong>{calculateTotals().totalReturnedProduct}</strong></td>
                                <td><strong>{calculateTotals().totalTransfer}</strong></td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                         
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default TransactionIncomingReport;
