import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import SQCeditModalPopup from "./Sqcedit";
import { MdDeleteForever } from "react-icons/md";
import Search from "./search";
import {FaSearch } from "react-icons/fa";



function SQCSettings() {
  const { loadinDate } = useParams();
  const date = new Date();
  date.setDate(date.getDate() );
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [freezeStates, setFreezeStates] = useState(() => {
    const storedFreezeStates = localStorage.getItem("freezeStates");
    return storedFreezeStates ? JSON.parse(storedFreezeStates) : {};
  });
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchbydate, setSearchbydate] = useState(true);
  const [searchtruck, setSearchtruck] = useState([]);
  const [searchTruckNumber, setSearchTruckNumber] = useState(""); // Step 1
  const [searchInvoicekNumber, setSearchInvoiceNumber] = useState("");
  const [searchSqcNumber, setSearchSqcNumber] = useState("");
  const [searchDate, setSearchDate]= useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  console.log("loadinDate", loadinDate);
  useEffect(() => {
    console.log("In useEffect - Current searchDate:", searchDate);

    if (loadinDate) {
      setSearchDate(loadinDate); // Update searchDate when loadinDate is present
      getData(loadinDate);
    }else{
      setSearchDate(currentDate);
      getData(currentDate);
    }
  }, [loadinDate]);

  function searchName(text) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);
    setSearchDate(text);
    setLoader(true);
    axios
    .get(`/api/sqcform/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setProduct(res.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        handleError(error);
      });
      
  }
  function searchByTruckNumber(truckNumber) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchtruck(false);
    setLoader(true);
    axios
      .get(`/api/sqcform/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/trucknumber=${truckNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setProduct(res.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        handleError(error);
      });
  }
 
 
  function searchByInvoiceNumber(invoiceNumber) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchInvoiceNumber(false);
    setLoader(true);
    axios
      .get(`/api/sqcform/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/invoicenumber=${invoiceNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setProduct(res.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        handleError(error);
      });
  }
 
  function searchBySqcNumber(id) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchSqcNumber(false);
    setLoader(true);
    axios
    .get(`api/sqcform/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const responseData = res.data;
        const dataArray = [
          { id: responseData.id, invoiceNumber: responseData.invoiceNumber, truckNumber: responseData.truckNumber, date: responseData.date }
        ];
        setProduct(dataArray);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        handleError(error);
      });
  }
  
  const onClickhandler = async (e, id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    const vehicles = await axios({
      url: "/api/sqcform",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`
      },
    }).then((res) => {
      toast.success("Deleted Successfully");
      getData();
      setLoading(null);
    });
  };

  const getData = async (searchDate) => {
    console.log("Before API call - searchDate:", searchDate); // Log the searchDate before making the call

    const token = JSON.parse(localStorage.getItem("data")).token;
    // const effectiveDate = searchDate || currentDate;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/sqcform/orgcode=${orgCode}/date=${searchDate}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };
  
  // useEffect(() => {
  //   getData();
  // }, [isModalOpen]);
 
 
  const handleEdit = (sqcId) => {
    setSelectedProductId(sqcId);
    navigate(`/users/Sqcedit/${sqcId}`);  };

  const closeEditModal = () => {
    setIsProductEditModalOpen(false);
    getData(searchDate); 
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }
 
  const handleFreeze = async (id, freeze) => {
    try {
      setLoader(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.post(
        "/api/sqcform/freeze",
        { id, freeze: !freeze },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      searchName(searchDate);
      setLoader(false);
      toast.success(`SQC ${response.data.freeze=== true ? "frozen" : "unfrozen"} successfully`);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };
  
  const filteredSqc = product.filter((item) =>
    item.invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.truckNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.id.toString().includes(searchTerm)
  );

  function onClickhandler1(e, id, sqcfreeze) {
    console.log("Toggle request for ID:", id, "Current Sqcfreeze:", sqcfreeze);    
    setLoading(id);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios.post(`/api/sqcform/update`, {
      id: id,
      sqcfreeze:!sqcfreeze
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
   
      .then((response) => {
        setLoading(null);
        toast.success(" status updated");
        handleFreeze(id, !sqcfreeze);
        console.log("Updated Sqcfreeze:", response.data.sqcfreeze);   
             getData(searchDate);
      })
      .catch((error)=>{
        handleError(error);
      });
  }
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {loader && <div className="loading"></div>}
      <div className="row md-9">
        <div className="col-9 md-2">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" ,marginLeft:"90px"}}>

        <input
  type="text"
  className="form-control"
  style={{
    width: '400px',  
    display: 'inline-block', 
    paddingLeft: '10px', 
  }}  placeholder="Search SQC Number   Truck Number   Invoice Number"
  value={searchTerm}
  onChange={(e) => setSearchTerm(e.target.value)}
/>
</div>

      <div style={{position: "relative",left:"900px" , display: "flex",bottom:"30px" }}>
        <Search searchName={searchName} />
      </div>
      </div>
      </div>
 
   

      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="d-flex">
              <div className="card-title">SQC  Settings Details{""}
             
                   </div>
              {""}
         
            </div>
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  <th>SQC Number</th>
                  <th>Invoice Number</th>
                  <th>Truck Number</th>
                  <th>Date</th>
                  <th>Approval Status</th>

                  <th>Action</th>
                  <th>Status</th>

                </tr>
              </thead>
              <tbody>
     {searchbydate && filteredSqc.length === 0 ? (
    <tr>
      <td colSpan="5" className="text-center">
        No Data Available
      </td>
    </tr>
  ) : (
    searchbydate &&
    filteredSqc.map((searchItem) => { 
      return (  
      <tr key={searchItem.id}>
      <td>{searchItem.id}</td>
        <td>{searchItem.invoiceNumber}</td>
        <td>{searchItem.truckNumber}</td>
        <td>{searchItem.date}</td>
        <td>{searchItem.approvalStatus}</td>

        <td>
          {/* <button
            onClick={() => handleEdit(searchItem.id)}
            style={{ border: "none", background: "white", size: "150px" }}
            disabled={searchItem.freeze===true}
          >
            <BiEditAlt />
          </button>
          <button
            style={{
              border: "none",
              background: "white",
              color: "grey",
              position: "relative",
              left: "20px",
              fontSize: "30px",
            }}
            onClick={(e) => {
              onClickhandler(
                e,
                searchItem.id,
                searchItem.invoiceNumber,
                searchItem.truckNumber
              );
            }}
            disabled={searchItem.freeze===true}
          >
            <MdDeleteForever />
          </button>{" "}
          {loading == searchItem.id && (
            <div
              className="loader"
              style={{ position: "relative", left: "40px", top: "8px" }}
            ></div>
          )}{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           */}
          <button
  className="btn btn-primary"
  onClick={() => handleFreeze(searchItem.id, searchItem.freeze)}
  style={{ backgroundColor: searchItem.freeze===true ? "red" : "green" }}
>
  { searchItem.freeze===true ? "Unfreeze" : "Freeze"}
</button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {/* <button   className="btn btn-primary"
                          onClick={(e) => handlePrint(e, searchItem.id)}
                         
                        >
                          Print
                        </button> */}
                        

         
        </td>
        <td>
                        <div className="form-check form-switch">
                          {loading == searchItem.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            checked={searchItem.sqcfreeze}
                            onChange={(e) => onClickhandler1(e, searchItem.id, searchItem.sqcfreeze)}
                            value={searchItem.id}
                          />
                        </div>
                      </td>
      </tr>
   );
  }))}
   {!searchbydate && filteredSqc.length === 0 ? (
            <tr>
              <td colSpan="4" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
   !searchbydate  &&  filteredSqc.map((searchItem) => {
     return (
      <tr key={searchItem.id}>
        <td>{searchItem.id}</td>
        <td>{searchItem.invoiceNumber}</td>
        <td>{searchItem.truckNumber}</td>
        <td>{searchItem.date}</td>
        <td>{searchItem.approvalStatus}</td>

        <td>
          {/* <button
            onClick={() => handleEdit(searchItem.id)}
            style={{ border: "none", background: "white", size: "150px" }}
            disabled={searchItem.freeze===true}
          >
            <BiEditAlt />
          </button>
          <button
            style={{
              border: "none",
              background: "white",
              color: "grey",
              position: "relative",
              left: "20px",
              fontSize: "30px",
            }}
            onClick={(e) => {
              onClickhandler(
                e,
                searchItem.id,
                searchItem.invoiceNumber,
                searchItem.truckNumber
              );
            }}
            disabled={searchItem.freeze===true}
          >
            <MdDeleteForever />
          </button>{" "}
          {loading == searchItem.id && (
            <div
              className="loader"
              style={{ position: "relative", left: "40px", top: "8px" }}
            ></div>
          )}{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
          <button
  className="btn btn-primary"
  onClick={() => handleFreeze(searchItem.id, searchItem.freeze)}
  style={{ backgroundColor: searchItem.freeze===true ? "red" : "green" }}
>
  { searchItem.freeze===true ? "Unfreeze" : "Freeze"}
</button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          {/* <button   className="btn btn-primary"
                          onClick={(e) => handlePrint(e, searchItem.id)}
                         
                        >
                           Print
                        </button> */}
                        
        </td>
        <td>
                        <div className="form-check form-switch">
                          {loading == searchItem.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            checked={searchItem.sqcfreeze}
                            onChange={(e) => onClickhandler1(e, searchItem.id, searchItem.sqcfreeze)}
                            value={searchItem.id}
                          />
                        </div>
                      </td>
      </tr>
);
}))}
</tbody>

            </table>
            <div>
            
      </div>


          </div>
        </div>{" "}
      </div>
      {isProductEditModalOpen && (
              <SQCeditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeEditModal}
                sqcId={selectedProductId}
              />
            )}
    </div>  
    
  );
}

export default SQCSettings;
