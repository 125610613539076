import { Link, Navigate } from "react-router-dom";
import { MdDirectionsTransit, MdFireTruck, MdLocalShipping, MdOutlineProductionQuantityLimits, MdStickyNote2 } from "react-icons/md";
import { FcInTransit } from "react-icons/fc";
import { AiOutlineStock } from "react-icons/ai";
import { AiOutlineUserAdd } from "react-icons/ai";
import { GrOrganization } from "react-icons/gr";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { IoIosSettings } from "react-icons/io";
import { RiNumbersFill } from "react-icons/ri";
import { MdOutlineApproval } from "react-icons/md";
import { TbHandClick } from "react-icons/tb";
import { FaUser } from "react-icons/fa";
import axios from "../api/baseurl";
import { useNavigate } from 'react-router-dom';

import React, { useEffect, useState } from "react";

function Sidebar() {

  const location = useLocation();
  const navigate = useNavigate();

  const [productCategories, setProductCategories] = useState([]);
  const [organizationProfiles, setOrganizationProfiles] = useState([]);
  const [selectedOrgCode, setSelectedOrgCode] = useState("");
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const userid = JSON.parse(localStorage.getItem("data"))?.userid;
console.log(userid);
  useEffect(() => {
    // Fetch organization profiles

    const fetchOrganizationProfiles = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;

        const response = await axios.get(`/api/organizationprofiles/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrganizationProfiles(response.data);
      } catch (error) {
        console.error("Error fetching organization profiles:", error);
      }
    };
    fetchOrganizationProfiles();
  }, [userid]);

  useEffect(() => {
    const fetchProductCategories = async () => {
  
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

        const response = await axios.get(`/api/productcategories/orgcode=${orgcode}/disable=false`, 
          {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProductCategories(response.data);
        console.log("Product categories fetched successfully:", response.data);
      } catch (error) {

        console.error("Error fetching product categories:", error);
      }
    };
  
    fetchProductCategories();
  }, []);
 
  const handleOrgCodeChange = (event) => {
    setSelectedOrgCode(event.target.value);
  };
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("data")) || {};
    if (storedData.orgcode) {
      setSelectedOrgCode(storedData.orgcode);
    }
  }, []);
  const isTransactionSectionOpen =
    location.pathname.startsWith("/users/stock") ||
    location.pathname.startsWith("/users/ConsumableStock") ||

    location.pathname.startsWith("/users/stocktransfer") ||
    location.pathname.startsWith("/users/transaction") ||
    location.pathname.startsWith("/users/loaddetails") ||
    location.pathname.startsWith("/users/sqcnumber") ||
    location.pathname.startsWith("/users/addsqcnumber") ||

    location.pathname.startsWith("/users/admintransactionapproval");

  const isOrganizationSectionOpen =
    location.pathname.startsWith("/users/Deliveryagentdetails") ||
    location.pathname.startsWith("/users/attendance") ||
    location.pathname.startsWith("/users/monthlytargetdetails") ||
    location.pathname.startsWith("/users/financialdetails") ||
    location.pathname.startsWith("/users/vehicledetail") ||
    location.pathname.startsWith("/users/vehiclemapping");

    const isProductsSectionOpen = /\/users\/productdetails\/\d+/.test(location.pathname);

   


  const isCounterSectionOpen =
    location.pathname.startsWith("/users/Counter") ||
    
    location.pathname.startsWith("/users/overheaddetails")||

    location.pathname.startsWith("/users/Quotation")||
    location.pathname.startsWith("/users/CreditnoteList")||
    location.pathname.startsWith("/users/Quotation")||
    location.pathname.startsWith("/users/InvoiceList");



  const isVendorSectionOpen =
    location.pathname.startsWith("/users/Productcategory")||
    /\/users\/productdetails\/\d+/.test(location.pathname)||

  location.pathname.startsWith("/users/ProductService")||
  location.pathname.startsWith("/users/Stockadjustment") ||
  location.pathname.startsWith("/users/Warehouse") ||
  location.pathname.startsWith("/users/PurchaseList") ||
  location.pathname.startsWith("/users/Bills") ||


  location.pathname.startsWith("/users/Goodsreceived")||

    location.pathname.startsWith("/users/Vendorcomponent") ||
    location.pathname.startsWith("/users/Warehouse") ||
    location.pathname.startsWith("/users/PurchaseList") ||
    location.pathname.startsWith("/users/Bills") ||

    location.pathname.startsWith("/users/Goodsreceived");
  const isServiceSectionOpen =
    location.pathname.startsWith(" /users/ProductService");

  // const isProductSectionOpen = location.pathname.startsWith("/users/productdetails");

  const isExemptedDetailsSectionOpen =
    location.pathname.startsWith("/users/exemptedcategory") ||
    location.pathname.startsWith("/users/exemptedcustomer") ||
    location.pathname.startsWith("/users/Customerpayment") ||
    location.pathname.startsWith("/users/Exempteddelivery");

  const isAreamasterSectionOpen =
    location.pathname.startsWith("/users/Consumerlist") ||
    location.pathname.startsWith("/users/ConsumerLoyaltyPoints") ||
    location.pathname.startsWith("/users/Areamaster") ||

    location.pathname.startsWith("/users/Csvupload") ||

    location.pathname.startsWith("/users/Leaderboard");

    const isVendorSectionOpen1=
    location.pathname.startsWith("/users/Vendorcomponent") ||
   

    location.pathname.startsWith("/users/Stocklistperson");
  const isRewardmasterSectionOpen1 =
    location.pathname.startsWith("/users/Viewrewards");

    
  const isRewardmasterSectionOpen =
    location.pathname.startsWith("/users/Staffrewards");



  const isReportsSectionOpen =
    location.pathname.startsWith("/users/fuelreport") ||
    location.pathname.startsWith("/users/dagentvehiclereport") ||
    location.pathname.startsWith("/users/servicereport") ||
    location.pathname.startsWith("/users/exemptedreport") ||
    location.pathname.startsWith("/users/transactionreport");

  const isTransportationSectionOpen =
  location.pathname.startsWith("/users/truckinfo")||
  location.pathname.startsWith("/users/truck-transporter")||
    location.pathname.startsWith("/users/truck-drivers");

  const isApprovalSectionOpen =
    location.pathname.startsWith("/users/adminapproval");


  const isUserDetailsSectionOpen =
    location.pathname.startsWith("/users/accountdetails");
  const isOrganizationDetailsSectionOpen =
    location.pathname.startsWith("/users/organizationdetails");
  const ischartDetailsSectionOpen =
    location.pathname.startsWith("/users/Teritory");
  //  location.pathname.startsWith("/users/Logo") ;
  const isproductmasterDetailsSectionOpen =
    location.pathname.startsWith("/users/Productmaster");


  const isleaderDetailsSectionOpen =
    location.pathname.startsWith("/users/Superrewardpoints");
  const iswalletDetailsSectionOpen =
    location.pathname.startsWith("/users/Wallet");
  const isleaderSectionOpen =
    location.pathname.startsWith("/users/Rewardpointsbutton");

  const isCampaignSectionOpen =
    location.pathname.startsWith("/users/campaigns");

  const isSettingsSectionOpen =
    location.pathname.startsWith("/users/submodule") ||
    location.pathname.startsWith("/users/subregion") ||
    location.pathname.startsWith("/users/tax") ||
    location.pathname.startsWith("/users/denomination")||
   
    location.pathname.startsWith("/ users/sudo");


  const shouldCounternSectionBeOpen = !isCounterSectionOpen;
  const shouldTransactionSectionBeOpen = !isTransactionSectionOpen;
  const shouldOrganizationSectionBeOpen = !isOrganizationSectionOpen;
  // const shouldProductSectionBeOpen = !isProductSectionOpen;
  const shouldExemptedDetailsSectionBeOpen = !isExemptedDetailsSectionOpen;
  const shouldReportsSectionBeOpen = !isReportsSectionOpen;
  const shouldApprovalSectionBeOpen = !isApprovalSectionOpen;
  const shouldUserDetailsSectionBeOpen = !isUserDetailsSectionOpen;
  const shouldwalletDetailsSectionBeOpen = !iswalletDetailsSectionOpen;
  const shouldleaderSectionBeOpen = !isleaderSectionOpen;
  const shouldCampaignSectionBeOpen = !isCampaignSectionOpen;

  const shouldchartDetailsSectionBeOpen = !ischartDetailsSectionOpen;
  const shouldleaderDetailsSectionBeOpen = !isleaderDetailsSectionOpen;

  const shouldproductmasterDetailsSectionBeOpen = !isproductmasterDetailsSectionOpen;

  const shouldOrganizationDetailsSectionBeOpen = !isOrganizationDetailsSectionOpen;
  const shouldSettingsSectionBeOpen = !isSettingsSectionOpen;
  const shouldAreamasterSectionBeOpen = !isAreamasterSectionOpen;
  const shouldRewardmasterSectionBeOpen = !isRewardmasterSectionOpen;
  const shouldRewardmasterSectionBeOpen1 = !isRewardmasterSectionOpen1;
  const shouldTransportationSectionBeOpen = !isTransportationSectionOpen;
  const shouldProductsSectionBeOpen = !isProductsSectionOpen;

  const shouldVendorSectionBeOpen = !isVendorSectionOpen;
  const shouldVendorSectionBeOpen1 = !isVendorSectionOpen1;

  const shouldServiceDetailsSectionBeOpen = !isServiceSectionOpen;

  const profileimage = JSON.parse(localStorage.getItem("profile"));
  return (
    <div id="layoutDrawer">
      <style>
        {`

          .active-link {
            background-color: #f0f0f0; /* Add your desired active highlight style */
          }

        `}
      </style>
      <div id="layoutDrawer_nav">
        <nav
          className="drawer accordion drawer-light bg-white"
          id="drawerAccordion"
        >

          <div className="drawer-menu" style={{ position: "sticky" }}>
            <div className="nav">
              <div className="drawer-menu-heading d-sm-none">Account</div>
              <a className="nav-link d-sm-none" href="#!">
                <div className="nav-link-icon">
                  <i className="material-icons">notifications</i>
                </div>
                Notifications
              </a>
              <a className="nav-link d-sm-none" href="#!">
                <div className="nav-link-icon">
                  <i className="material-icons">mail</i>
                </div>
                Messages
              </a>
<br/>

            

              {(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN") |
                (`${JSON.parse(localStorage.getItem("data")).role}` ===
                  "ROLE_MANAGER") ? (
                <div>
                  <div className="drawer-menu-divider d-sm-none"></div>
                  <div className="drawer-menu-heading">
                    <Link to="/users/admindashboardwithanalytics">
                      <div className="d-flex align-items-center">
                        <span>Dashboard</span>
                        <div className="ms-auto">
                        </div>
                      </div>

                    </Link>

                  </div>
                </div>
              ) : (
                ""
              )}

              {(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_SUPER") ? (
                <div>
                  <div className="drawer-menu-divider d-sm-none"></div>
                  <div className="drawer-menu-heading">
                    <Link to="/users/Superdashboard">
                      <div className="d-flex align-items-center">
                        <span>Dashboard</span>
                        <div className="ms-auto">
                        </div>
                      </div>

                    </Link>

                  </div>
                </div>
              ) : (
                ""
              )}

              {(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_MANAGER") |
                (`${JSON.parse(localStorage.getItem("data")).role}` ===
                  "ROLE_SUPER") ? (
                <div>
                  <NavLink
                    className={`nav-link collapsed ${isApprovalSectionOpen ? "show" : ""}`}
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-expanded={shouldApprovalSectionBeOpen ? "true" : "false"}
                    to="/users/adminapproval"
                  >
                    <div className="nav-link-icon">
                      <i className="material-icons">
                        <MdOutlineApproval />
                      </i>
                    </div>
                    Approval
                  </NavLink>
                </div>
              ) : (
                ""
              )}


              {/* {(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN") |
              (`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_MANAGER") ? (
                <div>
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/stock"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                        <AiOutlineStock />
                      </i>
                    </div>
                    Stock
                  </NavLink>
                </div>
              ) : (
                ""
              )} */}

              {(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN") |
                (`${JSON.parse(localStorage.getItem("data")).role}` ===
                  "ROLE_MANAGER") ? (

                <div>
                  <a
                    className={`nav-link collapsed ${isCounterSectionOpen ? "show" : ""}`}
                    href="javascript:void(0);"
                    data-bs-toggle="collapse"
                    data-bs-target="#countercollapseDashboards"
                    aria-expanded={shouldCounternSectionBeOpen ? "true" : "false"}
                    aria-controls="countercollapseDashboards"
                  >

                    <div className="nav-link-icon" >
                      <i className="material-icons">
                        business
                      </i>
                    </div>

                    Counter
                    <div className="drawer-collapse-arrow">
                      <i className="material-icons">expand_more</i>
                    </div>
                  </a>

                  <div
                    className={`collapse ${isCounterSectionOpen ? "show" : ""}`}
                    id="countercollapseDashboards"
                    data-bs-parent="#drawerAccordion"
                  >
                    <nav className="drawer-menu-nested nav">

                      <NavLink className="nav-link" to="/users/Counter" activeClassName="active-link">
                      Kiosk
                      </NavLink>
                      
                      < NavLink className="nav-link" to="/users/overheaddetails" activeClassName="active-link">
                        Overhead Details
  
                      </NavLink>
                      < NavLink className="nav-link" to="/users/Quotation" activeClassName="active-link">
                        Quotation
                      </NavLink>
                      < NavLink className="nav-link" to="/users/CreditnoteList" activeClassName="active-link">
                        Performa Bill
                        </NavLink>

                      < NavLink className="nav-link" to="/users/InvoiceList" activeClassName="active-link">
                        InvoiceList
                      </NavLink>

                      < NavLink className="nav-link" to="/users/Customerorder" activeClassName="active-link">
                        Customer Order
                      </NavLink>


                    </nav>
                  </div>

                </div>
              ) : (
                ""
              )}


              {(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN") |
                (`${JSON.parse(localStorage.getItem("data")).role}` ===
                  "ROLE_MANAGER") ? (

                <div>
                  <a
                    className={`nav-link collapsed ${isTransactionSectionOpen ? "show" : ""}`}
                    href="javascript:void(0);"
                    data-bs-toggle="collapse"
                    data-bs-target="#transactioncollapseDashboards"
                    aria-expanded={shouldTransactionSectionBeOpen ? "true" : "false"}
                    aria-controls="transactioncollapseDashboards"
                  >
                    <div className="nav-link-icon">
                      <i className="material-icons">
                        <FcInTransit />
                      </i>
                    </div>
                    Stock Master
                    <div className="drawer-collapse-arrow">
                      <i className="material-icons">expand_more</i>
                    </div>
                  </a>
                  <div
                    className={`collapse ${isTransactionSectionOpen ? "show" : ""}`}
                    id="transactioncollapseDashboards"
                    aria-labelledby="headingOne"
                    data-bs-parent="#drawerAccordion"
                  >

                    <nav className="drawer-menu-nested nav">
                      <NavLink className="nav-link" to="/users/stock" activeClassName="active-link">
                        Stock
                      </NavLink>

                      {/* <NavLink className="nav-link" to="/users/ConsumableStock" activeClassName="active-link">
                    Consumable Stock
                      </NavLink> */}
                      <NavLink className="nav-link" to="/users/stocktransfer" activeClassName="active-link">
                        Stock Transfer
                      </NavLink>

                      <NavLink className="nav-link" to="/users/loaddetails" activeClassName="active-link">
                        Loads Details
                      </NavLink>
                      <NavLink className="nav-link" to="/users/sqcnumber" activeClassName="active-link">
                        SQC Details
                      </NavLink>
                      <NavLink className="nav-link" to="/users/transaction" activeClassName="active-link">
                        Transaction Details
                      </NavLink>





                      <NavLink
                        className="nav-link"
                        to="/users/admintransactionapproval " activeClassName="active-link"
                      >
                        Admin Approval
                      </NavLink>
                    </nav>
                  </div>

                </div>
              ) : (
                // <div>
                //   <a
                //     className="nav-link collapsed"
                //     href="javascript:void(0);"
                //     data-bs-toggle="collapse"
                //     data-bs-target="#transactioncollapseDashboards"
                //     aria-expanded="false"
                //     aria-controls="transactioncollapseDashboards"
                //   >
                //     <div className="nav-link-icon">
                //       <i className="material-icons">
                //         <FcInTransit />
                //       </i>
                //     </div>
                //     Transactions
                //     <div className="drawer-collapse-arrow">
                //       <i className="material-icons">expand_more</i>
                //     </div>
                //   </a>
                //   <div
                //     className="collapse"
                //     id="transactioncollapseDashboards"
                //     aria-labelledby="headingOne"
                //     data-bs-parent="#drawerAccordion"
                //   >
                //     <nav className="drawer-menu-nested nav">
                //       <NavLink className="nav-link" to="/users/transaction">
                //         Transaction Details
                //       </NavLink>
                //     </nav>
                //   </div>
                // </div>
                ""
              )}

              {/* {(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN") |
              (`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_MANAGER") ? (
                <div>
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/sqcnumber"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                      <RiNumbersFill />                    
                      </i>
                    </div>
                    SQC
                  </NavLink>
                </div>
              ) : (
                ""
              )} */}






              {`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN" && (
                  <div>

                    <a
                      className={`nav-link collapsed ${isOrganizationSectionOpen ? "show" : ""}`}
                      href="javascript:void(0);"
                      data-bs-toggle="collapse"
                      data-bs-target="#organizationCollapseDashboards"
                      aria-expanded={shouldOrganizationSectionBeOpen ? "true" : "false"}
                      aria-controls="organizationCollapseDashboards"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">
                          < GrOrganization />
                        </i>
                      </div>
                      Organization Management
                      <div className="drawer-collapse-arrow">
                        <i className="material-icons">expand_more</i>
                      </div>
                    </a>
                    <div
                      className={`collapse ${isOrganizationSectionOpen ? "show" : ""}`}
                      id="organizationCollapseDashboards"
                      aria-labelledby="headingOrganization"
                      data-bs-parent="#drawerAccordion"
                    >
                      <nav className="drawer-menu-nested nav">
                        {/* <Link className="nav-link" to="/users/registermd">
                        Add Members
                      </Link> */}
                      

                        <NavLink className="nav-link" to="/users/Deliveryagentdetails" activeClassName="active-link">
                          Staff Details
                        </NavLink>

                        <NavLink className="nav-link" to="/users/attendance" activeClassName="active-link">
                          Attendance
                        </NavLink>
                        <NavLink className="nav-link" to="/users/monthlytargetdetails" activeClassName="active-link">
                          Monthly Target
                        </NavLink>
                        <NavLink className="nav-link" to="/users/financialdetails" activeClassName="active-link">
                          Financial Details
                        </NavLink>
                        <NavLink className="nav-link" to="/users/vehicledetail" activeClassName="active-link">
                          Fleet Details
                        </NavLink>
                        {/* <NavLink className="nav-link" to="/users/Superproduct" activeClassName="active-link">
                      Super Product Details                     
                      </NavLink> */}
                        {/* <NavLink className="nav-link" to="/users/productdetails" activeClassName="active-link">
                      Product Details                      
                      </NavLink> */}
                        {/* <NavLink className="nav-link" to="/users/ProductService" activeClassName="active-link">
                      Service                     
                      </NavLink> */}

                        <NavLink className="nav-link" to="/users/vehiclemapping" activeClassName="active-link">
                          Vehicle Mapping
                        </NavLink>



                      </nav>
                    </div>
                  </div>
                )}

              {/* {`${JSON.parse(localStorage.getItem("data")).role}` === "ROLE_ADMIN" ||
                `${JSON.parse(localStorage.getItem("data")).role}` === "ROLE_MANAGER" ? (
                <div>

                  <a
                    className={`nav-link collapsed ${isProductsSectionOpen ? "show" : ""}`}
                    href="javascript:void(0);"
                    data-bs-toggle="collapse"
                    data-bs-target="#productsCollapseDashboards"
                    aria-expanded={shouldProductsSectionBeOpen ? "true" : "false"}
                    aria-controls="productsCollapseDashboards"
                  >
                    <div className="nav-link-icon">
                      <i className="material-icons">store</i>
                    </div>
                    Products
                    <div className="drawer-collapse-arrow">
                      <i className="material-icons">expand_more</i>
                    </div>
                  </a>
                  <div
                    className={`collapse ${isProductsSectionOpen ? "show" : ""}`}
                    id="productsCollapseDashboards"
                    aria-labelledby="headingProducts"
                    data-bs-parent="#drawerAccordion"
                  >
                    <nav className="drawer-menu-nested nav">

                      <NavLink className="nav-link" to="/users/refill-sales" activeClassName="active-link">
                        Refill Sales
                      </NavLink>

                      <NavLink className="nav-link" to="/users/beyond-lpg" activeClassName="active-link">
                        Beyond LPG
                      </NavLink>
                      <NavLink className="nav-link" to="/users/consumables" activeClassName="active-link">
                        Consumables
                      </NavLink>
                    </nav>
                  </div>
                </div>
              ) : null}  */}




               {`${JSON.parse(localStorage.getItem("data")).role}` === "ROLE_ADMIN" ||
                `${JSON.parse(localStorage.getItem("data")).role}` === "ROLE_MANAGER" ? (
                <div>

                  <a
                    className={`nav-link collapsed ${isVendorSectionOpen ? "show" : ""}`}
                    href="javascript:void(0);"
                    data-bs-toggle="collapse"
                    data-bs-target="#VendorCollapseDashboards"
                    aria-expanded={shouldVendorSectionBeOpen ? "true" : "false"}
                    aria-controls="VendorCollapseDashboards"
                  >
                    <div className="nav-link-icon">
                      <i className="material-icons">inventory_2</i>
                    </div>
                    Inventory
                    <div className="drawer-collapse-arrow">
                      <i className="material-icons">expand_more</i>
                    </div>
                  </a>
                  <div
                    className={`collapse ${isVendorSectionOpen ? "show" : ""}`}
                    id="VendorCollapseDashboards"
                    aria-labelledby="headingInventory"
                    data-bs-parent="#inventoryAccordion"
                  >
                    <nav className="drawer-menu-nested nav">

                    <NavLink className="nav-link" to="/users/Productcategory" activeClassName="active-link">
                        Product Category
                      </NavLink>
        <a
          className={`nav-link collapsed ${isProductsSectionOpen ? "show" : ""}`}
          // href="javascript:void(0);"
          
          data-bs-toggle="collapse"
          data-bs-target="#productsCollapseDashboards"
          aria-expanded={shouldProductsSectionBeOpen ? "true" : "false"}
          aria-controls="productsCollapseDashboards"
        >
         
          Products
          <div className="drawer-collapse-arrow">
            <i className="material-icons">expand_more</i>
          </div>
        </a>
        {loader && <div className="loading"></div>}

        <div className={`collapse ${isProductsSectionOpen ? "show" : ""}`}

         id="productsCollapseDashboards"
         aria-labelledby="headingProducts"
         data-bs-parent="#productsAccordion">
         
         <nav className="drawer-menu-nested nav">
          {productCategories.map((category) => {
            if (!category.disable) {
              const link = `/users/productdetails/${category.id}`;

              return (
                <NavLink
                  key={category.id}
                  className="nav-link"
                  to={link}
                  activeClassName="active-link"
                >
                  {category.categoryname}
                </NavLink>
              );
            }
            return null;
          })}
        </nav>
              </div>
                       <NavLink className="nav-link" to="/users/ProductService" activeClassName="active-link">
                        Services
                      </NavLink>
                      <NavLink className="nav-link" to="/users/Stockadjustment" activeClassName="active-link">
                        Stock Adjustment
                      </NavLink>

                      <NavLink className="nav-link" to="/users/Warehouse" activeClassName="active-link">
                        Ware House
                      </NavLink>
                      <NavLink className="nav-link" to="/users/PurchaseList" activeClassName="active-link">
                        Purchases
                      </NavLink>

                      <NavLink className="nav-link" to="/users/Bills" activeClassName="active-link">
                        Bills
                      </NavLink>
                      <NavLink className="nav-link" to="/users/Goodsreceived" activeClassName="active-link">
                        Goods Received
                      </NavLink>
                    </nav>
                  </div>
                </div>
              ) : null} 

{/* {(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN") |
              (`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_MANAGER") ? (
                <div>
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/Vendorcomponent"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                      store
                          </i>
                    </div>
                    Vendor Management
                  </NavLink>
                </div>
              ) : (
                ""
              )} */}

{`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN" && (
                  <div>
                    <a
                      className={`nav-link collapsed ${isVendorSectionOpen1 ? "show" : ""}`}
                      href="javascript:void(0);"
                      data-bs-toggle="collapse"
                      data-bs-target="#pagesCollapseAccountsareavendor"
                      aria-expanded={shouldVendorSectionBeOpen1 ? "true" : "false"}
                      aria-controls="pagesCollapseAccountsareavendor"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">store
                        </i>
                      </div>
                      Vendor Management
                      <div className="drawer-collapse-arrow">
                        <i className="material-icons">expand_more</i>
                      </div>
                    </a>
                    <div
                      className={`collapse ${isVendorSectionOpen1 ? "show" : ""}`}
                      id="pagesCollapseAccountsareavendor"
                      aria-labelledby="headingOne"
                      data-bs-parent="#drawerAccordion"
                    >
                      <nav className="drawer-menu-nested nav">

                        <NavLink className="nav-link" to="/users/Vendorcomponent" activeClassName="active-link">
                          Vendors
                        </NavLink>
                        <NavLink className="nav-link" to="/users/Stocklistperson" activeClassName="active-link">
                          Stocklist
                        </NavLink>
                    

                      </nav>
                    </div>
                  </div>
                )}
            {/* {`${JSON.parse(localStorage.getItem("data")).role}` === "ROLE_ADMIN" ||
            `${JSON.parse(localStorage.getItem("data")).role}` === "ROLE_MANAGER" ? (
              {`${JSON.parse(localStorage.getItem("data")).role}` === "ROLE_ADMIN" ||
                `${JSON.parse(localStorage.getItem("data")).role}` === "ROLE_MANAGER" ? (
                <div>
                  {" "}
                  <NavLink
                    className={`nav-link collapsed ${isServiceSectionOpen ? "show" : ""}`}

                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-expanded={shouldServiceDetailsSectionBeOpen ? "true" : "false"}
                    to="/users/ProductService"
                  >
                    <div className="nav-link-icon">
                      <i className="material-icons">build
                      </i>
                    </div>
                    Services
                  </NavLink>
                  </div>
                ):null} */}
                {/* </div>
              ) : null} */}

              {/* {(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN") |
              (`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_MANAGER") ? (
                <div>
                  <div>
                    <a
                    className={`nav-link collapsed ${isProductSectionOpen ? "show" : ""}`}
                    href="javascript:void(0);"
                      data-bs-toggle="collapse"
                      data-bs-target="#pagesCollapseAccount"
                      aria-expanded={shouldProductSectionBeOpen ? "true" : "false"}
                      aria-controls="pagesCollapseAccount"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">
                          <MdOutlineProductionQuantityLimits />
                        </i>
                      </div>
                      Product Details
                      <div className="drawer-collapse-arrow">
                        <i className="material-icons">expand_more</i>
                      </div>
                    </a>
                    <div
                    className={`collapse ${isProductSectionOpen ? "show" : ""}`}
                    id="pagesCollapseAccount"
                      aria-labelledby="headingOne"
                      data-bs-parent="#drawerAccordion"
                    >
                      <nav className="drawer-menu-nested nav">
                        <NavLink className="nav-link" to="/users/productdetails" activeClassName="active-link">
                          Product Details
                        </NavLink>
                      </nav>
                    </div>
                    </div>
                  </div>
                  ) : (
                ""
              )} */}


              {`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN" && (
                  <div>
                    <a
                      className={`nav-link collapsed ${isAreamasterSectionOpen ? "show" : ""}`}
                      href="javascript:void(0);"
                      data-bs-toggle="collapse"
                      data-bs-target="#pagesCollapseAccountsarea"
                      aria-expanded={shouldAreamasterSectionBeOpen ? "true" : "false"}
                      aria-controls="pagesCollapseAccountsarea"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">location_city
                        </i>
                      </div>
                      Consumer Master
                      <div className="drawer-collapse-arrow">
                        <i className="material-icons">expand_more</i>
                      </div>
                    </a>
                    <div
                      className={`collapse ${isAreamasterSectionOpen ? "show" : ""}`}
                      id="pagesCollapseAccountsarea"
                      aria-labelledby="headingOne"
                      data-bs-parent="#drawerAccordion"
                    >
                      <nav className="drawer-menu-nested nav">

                        <NavLink className="nav-link" to="/users/Consumerlist" activeClassName="active-link">
                          Consumer List
                        </NavLink>
                        <NavLink className="nav-link" to="/users/ConsumerLoyaltyPoints" activeClassName="active-link">
                          Consumer Loyalty Points
                        </NavLink>
                        <NavLink className="nav-link" to="/users/Areamaster" activeClassName="active-link">
                          Area Master
                        </NavLink>
                        {/* <NavLink className="nav-link" to="/users/Vehicleareamap" activeClassName="active-link">
                          Vehicle Area Map
                        </NavLink> */}
                        <NavLink className="nav-link" to="/users/Csvupload" activeClassName="active-link">
                          Delivery Records
                        </NavLink>
                        {/* <NavLink className="nav-link" to="/users/Leaderboard" activeClassName="active-link">
                      Leader board
                        </NavLink> */}


                      </nav>
                    </div>
                  </div>
                )}
              {`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN" && (
                  <div>
                    <a
                      className={`nav-link collapsed ${isExemptedDetailsSectionOpen ? "show" : ""}`}
                      href="javascript:void(0);"
                      data-bs-toggle="collapse"
                      data-bs-target="#pagesCollapseAccounts"
                      aria-expanded={shouldExemptedDetailsSectionBeOpen ? "true" : "false"}
                      aria-controls="pagesCollapseAccounts"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">widgets</i>
                      </div>
                      Exempted Master
                      <div className="drawer-collapse-arrow">
                        <i className="material-icons">expand_more</i>
                      </div>
                    </a>
                    <div
                      className={`collapse ${isExemptedDetailsSectionOpen ? "show" : ""}`}
                      id="pagesCollapseAccounts"
                      aria-labelledby="headingOne"
                      data-bs-parent="#drawerAccordion"
                    >
                      <nav className="drawer-menu-nested nav">
                        <NavLink className="nav-link" to="/users/exemptedcategory" activeClassName="active-link">
                          Exempted Category
                        </NavLink>
                        <NavLink className="nav-link" to="/users/exemptedcustomer" activeClassName="active-link">
                          Exempted Customer
                        </NavLink>
                        {/* <NavLink className="nav-link" to="/users/Customerpayment" activeClassName="active-link">
                          Customer Payment
                        </NavLink> */}
                        <NavLink

                          className="nav-link"
                          to="/users/Exempteddelivery" activeClassName="active-link"
                        >
                          Exempted Delivery
                        </NavLink>
                      </nav>
                    </div>
                  </div>
                )}


              {/* {`${JSON.parse(localStorage.getItem("data")).role}` ===
                  "ROLE_ADMIN" && (
                  <div>
                    <a
                    className={`nav-link collapsed ${ isRewardmasterSectionOpen1 ? "show" : ""}`}
                    href="javascript:void(0);"
                      data-bs-toggle="collapse"
                      data-bs-target="#pagesCollapseAccountsreward"
                      aria-expanded={shouldRewardmasterSectionBeOpen1 ? "true" : "false"}
                      aria-controls="pagesCollapseAccountsreward"
                    >
                      <div className="nav-link-icon">
                      <i className="material-icons">emoji_events
                      </i>
                      </div>
                      Reward Master
                      <div className="drawer-collapse-arrow">
                      <i className="material-icons">expand_more</i>
                                     </div>
                    </a>
                    <div
                    className={`collapse ${ isRewardmasterSectionOpen  ? "show" : ""}`}
                    id="pagesCollapseAccountsreward"
                      aria-labelledby="headingOne"
                      data-bs-parent="#drawerAccordion"
                    >
                      <nav className="drawer-menu-nested nav">
                        <NavLink className="nav-link" to="/users/Viewrewards" activeClassName="active-link">
                        View Rewards
                        </NavLink>
                       
                       
                      
                      </nav>
                    </div>
                  </div>
                ) } */}





              {/* {`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN" && (
                <div>
                  {" "}
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"

                    to="/users/CustomerDetails" activeClassName="active-link"
                  >
                    <div className="nav-link-icon">
                      <i className="material-icons">person</i>
                    </div>
                    Customer Details

                    
                  
                    <div className="nav-link-icon">
                      <i className="material-icons">
                        <AiOutlineUserAdd />
                      </i>
                    </div>
                    ADD Members

                  </NavLink>{" "}
                </div>
              )} */}
              {`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN" && (
                  <div>
                    {" "}
                    <NavLink
                      className={`nav-link collapsed ${iswalletDetailsSectionOpen ? "show" : ""}`}

                      href="javascript:void(0);"
                      data-bs-target="#collapseDashboards"
                      aria-expanded={shouldwalletDetailsSectionBeOpen ? "true" : "false"}
                      to="/users/Wallet"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">attach_money
                        </i>
                      </div>
                      Wallet
                    </NavLink>
                  </div>
                )}
              {`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN" && (
                  <div>
                    {" "}
                    <NavLink
                      className={`nav-link collapsed ${isleaderSectionOpen ? "show" : ""}`}

                      href="javascript:void(0);"
                      data-bs-target="#collapseDashboards"
                      aria-expanded={shouldleaderSectionBeOpen ? "true" : "false"}
                      to="/users/Rewardpointsbutton"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">leaderboard
                        </i>
                      </div>
                      Leaderboard
                    </NavLink>
                  </div>
                )}

              {`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN" && (
                  <div>
                    {" "}
                    <NavLink
                      className={`nav-link collapsed ${isCampaignSectionOpen ? "show" : ""}`}

                      href="javascript:void(0);"
                      data-bs-target="#collapseDashboards"
                      aria-expanded={shouldCampaignSectionBeOpen ? "true" : "false"}
                      to="/users/campaigns"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">campaign
                        </i>
                      </div>
                      Campaign
                    </NavLink>
                  </div>
                )}

              {`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_SUPER" && (
                  <div>
                    {" "}
                    <NavLink
                      className={`nav-link collapsed ${isUserDetailsSectionOpen ? "show" : ""}`}

                      href="javascript:void(0);"
                      data-bs-target="#collapseDashboards"
                      aria-expanded={shouldUserDetailsSectionBeOpen ? "true" : "false"}
                      to="/users/accountdetails"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">
                          <FaUser />
                        </i>
                      </div>
                      User Details
                    </NavLink>
                    <NavLink
                      className={`nav-link collapsed ${ischartDetailsSectionOpen ? "show" : ""}`}

                      href="javascript:void(0);"
                      data-bs-target="#collapseDashboards"
                      aria-expanded={shouldchartDetailsSectionBeOpen ? "true" : "false"}
                      to="/users/Teritory"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">
                          location_city
                        </i>
                      </div>
                      Organization Chart
                    </NavLink>
                    {/* {`${JSON.parse(localStorage.getItem("data")).role}` ===
                  "ROLE_SUPER" &&  (
                  <div>
                    <a
                    className={`nav-link collapsed ${ ischartDetailsSectionOpen  ? "show" : ""}`}
                    href="javascript:void(0);"
                      data-bs-toggle="collapse"
                      data-bs-target="#pagesCollapseAccountsreward"
                      aria-expanded={shouldchartDetailsSectionBeOpen ? "true" : "false"}
                      aria-controls="pagesCollapseAccountsreward"
                    >
                      <div className="nav-link-icon">
                      <i className="material-icons"> location_city
                      </i>
                      </div>
                      Organization Chart
                      <div className="drawer-collapse-arrow">
                      <i className="material-icons">expand_more</i>
                                     </div>
                    </a>
                    <div
                    className={`collapse ${ ischartDetailsSectionOpen  ? "show" : ""}`}
                    id="pagesCollapseAccountsreward"
                      aria-labelledby="headingOne"
                      data-bs-parent="#drawerAccordion"
                    >
                      <nav className="drawer-menu-nested nav">
                        <NavLink className="nav-link" to="/users/Teritory" activeClassName="active-link">
                        Territory Details
                        </NavLink>
                        <NavLink className="nav-link" to="/users/Logo" activeClassName="active-link">
                        Logo
                        </NavLink>
                       
                       
                      
                      </nav>
                    </div>
                  </div>
                ) } */}
                    <NavLink
                      className={`nav-link collapsed ${isproductmasterDetailsSectionOpen ? "show" : ""}`}

                      href="javascript:void(0);"
                      data-bs-target="#collapseDashboards"
                      aria-expanded={shouldproductmasterDetailsSectionBeOpen ? "true" : "false"}
                      to="/users/Productmaster"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">
                          <MdOutlineProductionQuantityLimits />
                        </i>
                      </div>
                      Product Master
                    </NavLink>
                    <NavLink
                      className={`nav-link collapsed ${isleaderDetailsSectionOpen ? "show" : ""}`}

                      href="javascript:void(0);"
                      data-bs-target="#collapseDashboards"
                      aria-expanded={shouldleaderDetailsSectionBeOpen ? "true" : "false"}
                      to="/users/Superrewardpoints"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">
                          location_city
                        </i>
                      </div>
                      Leaderboard
                    </NavLink>
                    <NavLink
                      className={`nav-link collapsed ${isOrganizationDetailsSectionOpen ? "show" : ""}`}
                      href="javascript:void(0);"
                      data-bs-target="#collapseDashboards"
                      aria-expanded={shouldOrganizationDetailsSectionBeOpen ? "true" : "false"}
                      to="/users/organizationdetails"
                    >
                      <div className="nav-link-icon">
                        <i className="material-icons">
                          <GrOrganization />
                        </i>
                      </div>
                      Organization Details
                    </NavLink>
                    {/* <NavLink
              className={`nav-link collapsed ${isOrganizationDetailsSectionOpen ? "show" : ""}`}
              href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-expanded={shouldOrganizationDetailsSectionBeOpen ? "true" : "false"}
                    to="/users/Supersubsricptiondetails"
                  >
                    <div className="nav-link-icon">
                      <i className="material-icons">
                      <TbHandClick />
                      </i>
                    </div>
                    Subscription Details
                  </NavLink> */}



                    <div>
                      <div>
                        <a
                          className={`nav-link collapsed ${isSettingsSectionOpen ? "show" : ""}`}
                          href="javascript:void(0);"
                          data-bs-toggle="collapse"
                          data-bs-target="#pagesCollapsAccount"
                          aria-expanded={shouldSettingsSectionBeOpen ? "true" : "false"}
                          aria-controls="pagesCollapsAccount"
                        >
                          <div className="nav-link-icon">
                            <i className="material-icons">build</i>
                          </div>
                          Settings
                          <div className="drawer-collapse-arrow">
                            <i className="material-icons">expand_more</i>
                          </div>
                        </a>
                        <div
                          className={`collapse ${isSettingsSectionOpen ? "show" : ""}`}
                          id="pagesCollapsAccount"
                          aria-labelledby="headingOne"
                          data-bs-parent="#drawerAccordion"
                        >
                          <nav className="drawer-menu-nested nav">
                            {/* <Link
                            className="nav-link collapsed"
                            href="javascript:void(0);"
                            data-bs-target="#collapseDashboards"
                            aria-controls="collapseDashboards"
                            to="/users/subscription"
                          >
                            <div className="nav-link-icon">
                              <i className="material-icons">widgets</i>
                            </div>
                            Subscription
                          </Link> */}
                            <NavLink
                              className="nav-link collapsed"
                              href="javascript:void(0);"
                              data-bs-target="#collapseDashboards"
                              aria-controls="collapseDashboards" activeClassName="active-link"
                              to="/users/submodule"
                            >
                              Subscription Model
                            </NavLink>
                            <NavLink
                              className="nav-link collapsed"
                              href="javascript:void(0);"
                              data-bs-target="#collapseDashboards"
                              aria-controls="collapseDashboards" activeClassName="active-link"
                              to="/users/subregion"
                            >
                              Subscription Region
                            </NavLink>

                            <NavLink
                              className="nav-link collapsed"
                              href="javascript:void(0);"
                              data-bs-target="#collapseDashboards"
                              aria-controls="collapseDashboards" activeClassName="active-link"
                              to="/users/denomination"
                            >
                              Denomination
                            </NavLink>
                            <NavLink
                              className="nav-link collapsed"
                              href="javascript:void(0);"
                              data-bs-target="#collapseDashboards"
                              aria-controls="collapseDashboards" activeClassName="active-link"
                              to="/users/tax"
                            >
                              Tax
                            </NavLink>
                            <NavLink
                              className="nav-link collapsed"
                              href="javascript:void(0);"
                              data-bs-target="#collapseDashboards"
                              aria-controls="collapseDashboards" activeClassName="active-link"
                              to="/users/sudo"
                            >
                              Assume (SUDO)
                            </NavLink>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {/* {`${JSON.parse(localStorage.getItem("data")).role}` ===
                  "ROLE_SUPER" &&  (
                  <div>
                    <a
                    className={`nav-link collapsed ${ isRewardmasterSectionOpen  ? "show" : ""}`}
                    href="javascript:void(0);"
                      data-bs-toggle="collapse"
                      data-bs-target="#pagesCollapseAccountsreward"
                      aria-expanded={shouldRewardmasterSectionBeOpen ? "true" : "false"}
                      aria-controls="pagesCollapseAccountsreward"
                    >
                      <div className="nav-link-icon">
                      <i className="material-icons">emoji_events
                      </i>
                      </div>
                      Reward Master
                      <div className="drawer-collapse-arrow">
                      <i className="material-icons">expand_more</i>
                                     </div>
                    </a>
                    <div
                    className={`collapse ${ isRewardmasterSectionOpen  ? "show" : ""}`}
                    id="pagesCollapseAccountsreward"
                      aria-labelledby="headingOne"
                      data-bs-parent="#drawerAccordion"
                    >
                      <nav className="drawer-menu-nested nav">
                        <NavLink className="nav-link" to="/users/Staffrewards" activeClassName="active-link">
                        Staff Rewards
                        </NavLink>
                       
                       
                      
                      </nav>
                    </div>
                  </div>
                ) } */}


              {`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_SUPER" && (
                  <div>
                    <a
                      className={`nav-link collapsed ${isReportsSectionOpen ? "show" : ""}`}
                      href="javascript:void(0);"
                      data-bs-toggle="collapse"
                      data-bs-target="#reportCollapseReport"
                      aria-expanded={shouldReportsSectionBeOpen ? "true" : "false"}
                      aria-controls="reportCollapseReport"
                    >

                      <div className="nav-link-icon">
                        <i className="material-icons">description</i>
                      </div>
                      Reports
                      <div className="drawer-collapse-arrow">
                        {/* <i className="material-icons">expand_more</i> */}
                      </div>
                    </a>
                    <div

                      className={`collapse ${isReportsSectionOpen ? "show" : ""}`}
                      id="reportCollapseReport"
                      aria-labelledby="headingOne"
                      data-bs-parent="#drawerAccordion"
                    >

                      {/* <nav className="drawer-menu-nested nav">
                    <NavLink className="nav-link" to="/users/fuelreport" activeClassName="active-link">
                      Fuellog report
                    </NavLink>
                    <NavLink className="nav-link" to="/users/dagentvehiclereport" activeClassName="active-link">
                      Agent report
                    </NavLink>
                    <NavLink className="nav-link" to="/users/servicereport" activeClassName="active-link">
                      Maintenance report
                    </NavLink>
                    <NavLink className="nav-link" to="/users/exemptedreport" activeClassName="active-link">
                      Exempted report
                    </NavLink>
                    <NavLink className="nav-link" to="/users/transactionreport" activeClassName="active-link">
                      Transaction report
                    </NavLink>
                  </nav> */}
                    </div>
                  </div>
                )}

              {`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN" && (
                  <NavLink
                    className={`nav-link collapsed ${isReportsSectionOpen ? "show" : ""}`}

                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-expanded={shouldReportsSectionBeOpen ? "true" : "false"}
                    to="/users/ReportsPage"
                  >
                    <div className="nav-link-icon">
                      <i className="material-icons">description</i>

                    </div>
                    Report
                  </NavLink>
                )}


              {(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_ADMIN") |
                (`${JSON.parse(localStorage.getItem("data")).role}` ===
                  "ROLE_MANAGER") ? (
                <div>
                  <a
                    className={`nav-link collapsed ${isTransportationSectionOpen ? "show" : ""}`}
                    href="javascript:void(0);"
                    data-bs-toggle="collapse"
                    data-bs-target="#reportCollapseTransportation"
                    aria-expanded={shouldTransportationSectionBeOpen ? "true" : "false"}
                    aria-controls="reportCollapseTransportation"
                  >

                    <div className="nav-link-icon" >
                      <i className="material-icons">
                        < MdLocalShipping />
                      </i>
                    </div>
                    Transportation
                    <div className="drawer-collapse-arrow">
                      <i className="material-icons">expand_more</i>
                    </div>
                  </a>
                  <div

                    className={`collapse ${isTransportationSectionOpen ? "show" : ""}`}
                    id="reportCollapseTransportation"
                    aria-labelledby="headingOne"
                    data-bs-parent="#drawerAccordion"
                  >

                    <nav className="drawer-menu-nested nav">
                      <NavLink className="nav-link" to="/users/truckinfo" activeClassName="active-link">
                        Trucks
                      </NavLink>
                    <NavLink className="nav-link" to="/users/truck-transporter" activeClassName="active-link">
                      Transporters
                    </NavLink>  
                    <NavLink className="nav-link" to="/users/truck-drivers" activeClassName="active-link">
                      Drivers
                    </NavLink>
                    </nav>
                  </div>
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/Settings"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                        < IoIosSettings />
                      </i>
                    </div>
                    Settings
                  </NavLink>
                </div>
              ) : (
                ""
              )}
              <div
                className="collapse"
                id="collapseDashboards"
                aria-labelledby="headingOne"
                data-bs-parent="#drawerAccordion"
              ></div>
            </div>
          </div>

          <div className="drawer-footer border-top">
              <div className="text-center">
                {/* <img
                  className="img-fluid rounded-circle mb-1"
                  src={`${profileimage.imageMeta},${profileimage.image}`}
                /> */}
              </div>
              <div className="dropdown mb-3" >
              <label htmlFor="orgDropdown" className="form-label me-2" style={{ fontSize: "0.75rem" }}>
      Select Organization
    </label>
              <div className="d-flex align-items-center">

              
  <div className="input-group">
    <select
      id="orgDropdown"
      className="form-select me-2"
      value={selectedOrgCode}
      onChange={(e) => setSelectedOrgCode(e.target.value)}
      style={{
        maxWidth: "180px",
        fontSize: "0.7rem",
        padding: "0.2rem 0.4rem",
        height: "25px",
      }}    >
      <option value="">
      {selectedOrgCode ? `Selected: ${selectedOrgCode}` : "Choose Organization"}
      </option>
      {organizationProfiles.map((org) => (
        <option key={org.orgcode} value={org.orgcode}>
          {org.orgcode}
        </option>
      ))}
    </select>
    <button
      type="button"
      className="btn btn-primary btn-sm"
            disabled={!selectedOrgCode}
            onClick={() => {
              if (selectedOrgCode) {
                // Retrieve the existing data from localStorage
                const existingData = JSON.parse(localStorage.getItem("data")) || {};
                
                // Update only the orgcode
                const updatedData = { ...existingData, orgcode: selectedOrgCode };
                
                // Save the updated data back to localStorage
                localStorage.setItem("data", JSON.stringify(updatedData));
                
                // Log the stored value in localStorage
                console.log("Stored data in localStorage:", JSON.parse(localStorage.getItem("data")));
                
                // Navigate to the Admin Dashboard with Analytics page
                navigate("/users/admindashboardwithanalytics");
              } else {
                console.log("No organization selected");
              }
            }}
            style={{
              fontSize: "0.7rem",
              padding: "0.2rem 0.4rem",
              height: "25px",
            }}
          >
            Switch
          </button>
  </div>
</div>

<div className="mt-2 d-flex align-items-center">
  <span className="small" style={{ fontSize: "0.8rem" }}>Logged in as:</span>
  <span className="ms-1 small fw-bold">
    {JSON.parse(localStorage.getItem("data")).username}
  </span>
</div>
              </div>
            
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
